import { DynamicFormCard, InformationBox, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { BorrowerSimulationDto, CreditProvider, FinancialPlanConfig, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { of, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { hideWhenFieldIsFalse } from '../utils/dynamic-form.utils';
import { FormConfiguration } from '../models/dynamic-form.model';

export default function (
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource,
	financialPlanConfig?: FinancialPlanConfig,
	liabilityProviders?: CreditProvider[]
): InputBase<any>[] {
	const collateralForm = new FormConfiguration();
	collateralForm.setName('extraCollateralForm');
	collateralForm.formControl.questions = [
		new InputField({
			key: 'collateralAmount',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.extraCollateral?.collateralAmount,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'hasExistingLoan',
			label: 'ç.question.hasExistingLoan.label',
			value: !!formData?.extraCollateral?.originalLoanAmount,
			updateOn: 'change',
			type: 'checkbox',
		}),
		new InputField({
			key: 'originalLoanAmount',
			label: 'ç.question.originalLoanAmount.label',
			value: formData?.extraCollateral?.originalLoanAmount || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoan'),
		}),
		new InputSelect({
			key: 'creditProvider.id',
			label: 'ç.question.creditProvider.label',
			value: formData?.extraCollateral?.creditProvider?.id,
			required: false,
			appendTo: null,
			bindLabel: 'name',
			options: liabilityProviders,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoan'),
		}),
		new InformationBox({
			content: 'ç.feature.mortgageSimulator.yourContribution.collateral.informationBox',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoan'),
		}),
	];

	const bridgeLoanForm = new FormConfiguration();
	bridgeLoanForm.setName('bridgeLoanForm');
	bridgeLoanForm.formControl.questions = [
		new InputField({
			key: 'amount',
			label: 'ç.question.bridgeLoanAmount.label',
			helpText: of('ç.question.bridgeLoanAmount.helpText'),
			value: formData?.bridgeLoan?.amount,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	return [
		new InputField({
			key: 'ownFunds',
			label: 'ç.question.ownFunds.label',
			value: formData?.ownFunds || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicFormCard({
			title: 'ç.misc.additionalCollateral',
			key: 'extraCollateral',
			value: formData?.extraCollateral,
			formConfiguration: collateralForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.misc.action.addCollateral',
			secondaryAction: true,
			forceExpand: formData?.extraCollateral?.collateralAmount > 0,
			required: false,
		}),

		new DynamicFormCard({
			title: 'ç.resource.loan-type.bridgeLoan',
			key: 'bridgeLoan',
			value: formData?.bridgeLoan,
			formConfiguration: bridgeLoanForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.misc.action.addBridgeLoan',
			secondaryAction: true,
			forceExpand: formData?.bridgeLoan?.amount > 0,
			required: false,
		}),
	];
}
