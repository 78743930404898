import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { Document, Resource, Proof, ActionState, initialActionState, setActionState, ActionTypes } from '@oper-client/shared/data-model';

import * as ProofActions from './proof.actions';

export const PROOF_ENTITY_KEY = 'proof';

export interface ProofState extends EntityState<Proof> {
	actions: ProofActionsState;
	allowedDocuments?: Resource[];
	downloadZippedProofDocuments: string;
	generatedDocument: Document;
}
export type ProofActionTypes =
	| 'loadProof'
	| 'loadAllowedDocuments'
	| 'createProofDocument'
	| 'deleteProofDocument'
	| 'uploadFile'
	| 'updateProofDocument'
	| 'downloadZippedProofDocuments'
	| 'generateDocument';
export type ProofActionsState = Record<ProofActionTypes, ActionState>;

export const proofAdapter: EntityAdapter<Proof> = createEntityAdapter<Proof>();

export const initialState: ProofState = proofAdapter.getInitialState({
	allowedDocuments: undefined,
	downloadZippedProofDocuments: undefined,
	generatedDocument: null,
	actions: {
		loadProof: initialActionState,
		loadAllowedDocuments: initialActionState,
		createProofDocument: initialActionState,
		updateProofDocument: initialActionState,
		deleteProofDocument: initialActionState,
		uploadFile: initialActionState,
		downloadZippedProofDocuments: initialActionState,
		generateDocument: initialActionState,
	},
});

function setActionStates(
	actionState: ProofActionsState,
	action: ProofActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null,
	ids: any = null
): ProofActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error, ids),
	};
}

const documentReducer = createReducer(
	initialState,

	on(ProofActions.loadProof, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProof', ActionTypes.loading),
	})),
	on(ProofActions.loadProofSuccess, (state, { proof }) =>
		proofAdapter.setAll(proof, {
			...state,
			actions: setActionStates(state.actions, 'loadProof', ActionTypes.success),
		})
	),
	on(ProofActions.loadProofFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProof', ActionTypes.failure, error),
	})),

	on(ProofActions.createProofDocument, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createProofDocument', ActionTypes.loading),
	})),
	on(ProofActions.uploadDocument, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createProofDocument', ActionTypes.loading),
	})),
	on(ProofActions.createProofDocumentSuccess, (state, { categoryId, document }) => {
		const matchedProof = state.entities[categoryId];
		if (!matchedProof) {
			return;
		}
		const update: Update<Proof> = {
			id: categoryId,
			changes: {
				documents: [...matchedProof.documents, document],
			},
		};
		return proofAdapter.updateOne(update, {
			...state,
			actions: setActionStates(state.actions, 'createProofDocument', ActionTypes.success),
		});
	}),
	on(ProofActions.createProofDocumentFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createProofDocument', ActionTypes.failure, error),
	})),

	on(ProofActions.updateProofDocument, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateProofDocument', ActionTypes.loading),
	})),
	on(ProofActions.updateProofDocumentSuccess, (state, { categoryId, document }) => {
		const matchedProof = state.entities[categoryId];
		if (!matchedProof) {
			return;
		}
		const matchedDoc = matchedProof.documents.find((doc) => doc.id === document.id);
		const otherDocuments = matchedProof.documents.filter((doc) => doc.id !== document.id);
		const update: Update<Proof> = {
			id: categoryId,
			changes: {
				documents: [...otherDocuments, { ...matchedDoc, ...document.changes }],
			},
		};
		return proofAdapter.updateOne(update, {
			...state,
			actions: setActionStates(state.actions, 'updateProofDocument', ActionTypes.success, null, { categoryId, documentId: document.id }),
		});
	}),
	on(ProofActions.updateProofDocumentFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateProofDocument', ActionTypes.failure, error),
	})),

	on(ProofActions.uploadFile, (state, { categoryId, documentId }) => ({
		...state,
		actions: setActionStates(state.actions, 'uploadFile', ActionTypes.loading, null, { categoryId, documentId }),
	})),
	on(ProofActions.uploadFileSuccess, (state, { categoryId, document }) => {
		const matchedProof = state.entities[categoryId];
		if (!matchedProof) {
			return;
		}
		const matchedDoc = matchedProof.documents.find((doc) => doc.id === document.id);
		const otherDocuments = matchedProof.documents.filter((doc) => doc.id !== document.id);
		const update: Update<Proof> = {
			id: categoryId,
			changes: {
				documents: [...otherDocuments, { ...matchedDoc, ...document.changes }],
			},
		};
		return proofAdapter.updateOne(update, {
			...state,
			actions: setActionStates(state.actions, 'uploadFile', ActionTypes.success, null, { categoryId, documentId: document.id }),
		});
	}),
	on(ProofActions.uploadFileFailure, (state, { categoryId, documentId, error }) => ({
		...state,
		actions: setActionStates(state.actions, 'uploadFile', ActionTypes.failure, error, { categoryId, documentId }),
	})),

	on(ProofActions.deleteProofDocument, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'deleteProofDocument', ActionTypes.loading),
	})),
	on(ProofActions.deleteProofDocumentSuccess, (state, { categoryId, documentId }) => {
		const matchedProof = state.entities[categoryId];
		if (!matchedProof) {
			return state;
		}
		const update: Update<Proof> = {
			id: categoryId,
			changes: {
				documents: matchedProof.documents.filter((doc) => doc.id !== documentId),
			},
		};
		return proofAdapter.updateOne(update, {
			...state,
			actions: setActionStates(state.actions, 'deleteProofDocument', ActionTypes.success),
		});
	}),
	on(ProofActions.deleteProofDocumentFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'deleteProofDocument', ActionTypes.failure, error),
	})),
	on(ProofActions.deleteProofDocumentFile, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateProofDocument', ActionTypes.loading),
	})),
	on(ProofActions.loadAllowedProofDocuments, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadAllowedDocuments', ActionTypes.loading),
	})),
	on(ProofActions.loadAllowedProofDocumentsSuccess, (state, { allowedDocuments }) => ({
		...state,
		allowedDocuments: allowedDocuments,
		actions: setActionStates(state.actions, 'loadAllowedDocuments', ActionTypes.success),
	})),
	on(ProofActions.loadAllowedProofDocumentsFailure, (state, { error }) => ({
		...state,
		allowedDocuments: [],
		actions: setActionStates(state.actions, 'loadAllowedDocuments', ActionTypes.failure, error),
	})),

	on(ProofActions.downloadZippedProofDocuments, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'downloadZippedProofDocuments', ActionTypes.loading),
	})),
	on(ProofActions.downloadZippedProofDocumentsSuccess, (state, { zipFiles }) => ({
		...state,
		downloadZippedProofDocuments: zipFiles,
		actions: setActionStates(state.actions, 'downloadZippedProofDocuments', ActionTypes.success),
	})),
	on(ProofActions.downloadZippedProofDocumentsFailure, (state, { error }) => ({
		...state,
		allowedDocuments: [],
		actions: setActionStates(state.actions, 'downloadZippedProofDocuments', ActionTypes.failure, error),
	})),
	on(ProofActions.resetState, (state) => ({
		...state,
		...initialState,
	})),

	on(ProofActions.generateDocument, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'generateDocument', ActionTypes.loading),
	})),
	on(ProofActions.generateDocumentSuccess, (state, { document }) => ({
		...state,
		generatedDocument: document,
		actions: setActionStates(state.actions, 'generateDocument', ActionTypes.success),
	})),
	on(ProofActions.generateDocumentFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'generateDocument', ActionTypes.failure, error),
	}))
);

export function reducer(state: ProofState | undefined, action: Action) {
	return documentReducer(state, action);
}
