export enum BorrowerModes {
	SINGLE = 'single',
	MULTI = 'multi',
	MARRIED = 'married',
}

export enum BorrowerSimulatorUniqueStepNames {
	SIMULATION_REPORT = 'simulationReport',
}

export enum LoanApplicationUniqueStepNames {
	SUBMIT_APPLICATION = 'submitApplication',
}

export enum StepNames {
	BORROWER_MODE = 'borrower_mode',
	CLIENT = 'client',
	CLOSURE = 'closure',
	DIP = 'dip',
	ERROR = 'error',
	FINANCIAL_ASSET = 'financial_asset',
	FINANCIAL_DATA = 'financial_data',
	OWN_FUNDS = 'own_funds',
	INCOME = 'income',
	LIABILITY = 'liability',
	MORTGAGE_REPORT = 'mortgage_report',
	OFFER = 'offer',
	OFFICE_SELECTOR = 'office_selector',
	PREAPPROVAL = 'preapproval',
	REALTY = 'realty',
	REALTY_ADVANCED = 'realty_advanced',
	TAX = 'tax',
	YOUR_MORTGAGE = 'your_mortgage',
	MORTGAGE_FLOW = 'mortgageFlow',
	MORTGAGE_INCOME = 'mortgageIncome',
	MORTGAGE_OWN_FUNDS = 'mortgageOwnFunds',
	MORTGAGE_REALTY = 'mortgageRealty',
	MORTGAGE_REALTY_BUILD = 'mortgageRealtyBuild',
	MORTGAGE_LIABILITY = 'mortgageLiability',
	MORTGAGE_YOUR_HOME = 'mortgageYourHome',
	MORTGAGE_YOUR_PROJECT = 'mortgageYourProject',
	MORTGAGE_NEW_BUILD = 'mortgageNewBuild',
	MORTGAGE_CO_LIVING = 'mortgageCoLivingGroup',
	MORTGAGE_REGION = 'mortgageRegion',
	MORTGAGE_HOUSING_TYPE = 'mortgageHousingType',
	MORTGAGE_EXISTING_REALTY_TO_RENOVATE = 'mortgageExistingRealtyToRenovate',
	MORTGAGE_RENOVATED_EXISTING_REALTY = 'mortgageRenovatedExistingRealty',
	SIGNUP_CREDENTIALS = 'signupCredentials',
	SIGNUP_DETAILS = 'signupDetails',
	SIGNUP_VERIFICATION = 'signupVerification',
	// This is a placeholder for a step that will be dynamically created
	DYNAMIC_STEP = 'dynamicStep',
}
