import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
	ActionState,
	ActionTypes,
	initialActionState,
	setActionState,
	ValidationResult,
} from '@oper-client/shared/data-model';
import * as Actions from './ai-validation.actions';
import { AiValidationConfig } from '@oper-client/shared/configuration';

export const AI_VALIDATION_FEATURE_KEY = 'aiValidation';

export interface State extends EntityState<ValidationResult> {
	config: AiValidationConfig;
	actions: AiValidationActionsState;
}

export type AiValidationActionTypes = 'loadFieldMapping' | 'triggerValidation' | 'clearValidationResults' | 'loadValidationResult' | 'enableValidationTrigger';
export type AiValidationActionsState = Record<AiValidationActionTypes, ActionState>;

export interface AiValidationPartialState {
	readonly [AI_VALIDATION_FEATURE_KEY]: State;
}

export const validationsAdapter: EntityAdapter<ValidationResult> = createEntityAdapter<ValidationResult>({});

export const initialState: State = validationsAdapter.getInitialState({
	config: null,
	actions: {
		loadFieldMapping: initialActionState,
		triggerValidation: initialActionState,
		clearValidationResults: initialActionState,
		loadValidationResult: initialActionState,
		enableValidationTrigger: initialActionState,
	},
});

function setActionStates(
	actionState: AiValidationActionsState,
	action: AiValidationActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): AiValidationActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const validationReducer = createReducer(
	initialState,
	on(Actions.loadFieldMappingSuccess, (state, { config }) => ({
		...state,
		config,
		actions: setActionStates(state.actions, 'loadFieldMapping', ActionTypes.success),
	})),
	on(Actions.loadFieldMappingFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFieldMapping', ActionTypes.failure, error),
	})),
	on(Actions.loadValidationResultsSuccess, (state, { validationResults }) =>
		validationsAdapter.upsertMany(validationResults, {
			...state,
			actions: setActionStates(state.actions, 'loadValidationResult', ActionTypes.success),
		})
	),
	on(Actions.loadValidationResultsFailure, (state) =>
		validationsAdapter.removeAll({
			...state,
			actions: setActionStates(state.actions, 'loadValidationResult', ActionTypes.failure),
		})
	),
	on(Actions.triggerValidationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'triggerValidation', ActionTypes.failure, error),
	})),
	on(Actions.clearValidationResults, (state) =>
		validationsAdapter.removeAll({
			...state,
			actions: setActionStates(state.actions, 'clearValidationResults', ActionTypes.success),
		})
	),
	on(Actions.enableValidationTrigger, (state) =>
		validationsAdapter.removeAll({
			...state,
			actions: setActionStates(state.actions, 'enableValidationTrigger', ActionTypes.success),
		})
	)
);

export function reducer(state: State | undefined, action: Action): State {
	return validationReducer(state, action);
}
