export enum LoanType {
	PROGRESSIVE_AMORTIZATION = 'progressiveAmortization',
	INFINITE_CREDIT = 'infiniteCredit',
	SUBSIDY = 'subsidy',
	BALLOON_CREDIT = 'balloonCredit',
	BALLOON_CREDIT_NO_PAYMENT = 'balloonCreditNoPayment',
	CRESCENDO = 'crescendo',
	ANNUITY = 'mensuality',
	BRIDGE_LOAN = 'bridgeLoan',
}

export const BULLET_LOAN_TYPES: LoanType[] = [LoanType.BALLOON_CREDIT, LoanType.BALLOON_CREDIT_NO_PAYMENT];
