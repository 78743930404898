import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import {
	DocumentResponse,
	ConfigurationPortalDocumentPayload,
	ExcelPricerResponse,
	ConfigurationPortalLibraryDocument,
	ConfigurationPortalPricingSheet,
	ConfigurationPortalBaseProduct,
} from '@oper-client/shared/data-model';
import { HttpHeaders } from '@angular/common/http';

export const CONFIGURATION_PORTAL_PRICING_SERVICE = new InjectionToken<PricingService>('CONFIGURATION_PORTAL_PRICING_SERVICE');

@Injectable()
export class PricingService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getLibraryDocumentTypes(): Observable<any> {
		return this.apiService.get('/configuration/resources/library-document-type/');
	}

	getLibraryDocumentType(definition: string): Observable<any> {
		return this.apiService.get('/configuration/resources/library-document-type/?search=' + definition);
	}

	getLanguages(): Observable<any> {
		return this.apiService.get('/configuration/resources/language/');
	}

	getPeriodicities(): Observable<any> {
		return this.apiService.get('/configuration/resources/periodicity/');
	}

	getCostAmountType(): Observable<any> {
		return this.apiService.get('/configuration/resources/product-cost-amount-type/');
	}

	getCostType(): Observable<any> {
		return this.apiService.get('/configuration/resources/product-cost-type/');
	}

	getCostPromotionType(): Observable<any> {
		return this.apiService.get('/configuration/resources/product-cost-promotion-type/');
	}

	getPricingSheets(): Observable<ConfigurationPortalPricingSheet[]> {
		return this.apiService.get('/configuration/pricingmodule/pricing-sheets/');
	}

	getPricingSheet(configurationId: string): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.get('/configuration/pricingmodule/pricing-sheets/' + configurationId);
	}

	getProducts(): Observable<ConfigurationPortalBaseProduct[]> {
		return this.apiService.get('/configuration/creditprovidermodule/base-products/');
	}

	getProduct(configurationId: string): Observable<ConfigurationPortalBaseProduct> {
		return this.apiService.get('/configuration/creditprovidermodule/base-products/' + configurationId);
	}

	createPricingSheet(pricingSheet: ConfigurationPortalPricingSheet): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.post('/configuration/pricingmodule/pricing-sheets/', pricingSheet);
	}

	updatePricingSheet(pricingSheet: ConfigurationPortalPricingSheet): Observable<ConfigurationPortalPricingSheet> {
		return this.apiService.put('/configuration/pricingmodule/pricing-sheets/' + pricingSheet.configurationId, pricingSheet);
	}

	uploadExcelPricer(excelPricer: ConfigurationPortalDocumentPayload): Observable<ExcelPricerResponse> {
		const formData = new FormData();
		formData.append('file_name', excelPricer.fileName || '');
		formData.append('file', excelPricer.file, excelPricer.fileName || '');

		const headers = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.post('/api/internaldocuments/upload/', formData, null, headers);
	}

	downloadExcelPricer(configurationId: string): Observable<DocumentResponse> {
		return this.apiService.get(`/api/internaldocuments/documents/${configurationId}`);
	}

	deleteExcelPricer(configurationId: string): Observable<DocumentResponse> {
		return this.apiService.delete(`/api/internaldocuments/documents/${configurationId}`);
	}

	downloadLibraryDocument(configurationId: string): Observable<ConfigurationPortalLibraryDocument> {
		return this.apiService.get(`/configuration/documentationlibrarymodule/library-documents/${configurationId}`);
	}

	deleteDocumentByConfigurationId(configurationId: string) {
		return this.apiService.delete(`/configuration/documentationlibrarymodule/library-documents/${configurationId}/`, null);
	}
}
