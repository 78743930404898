import { FormGroup } from '@angular/forms';
import { debounceTimes } from '@oper-client/shared/configuration';
import { LiabilityType, PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { of } from 'rxjs';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { DynamicInputTable, InputField, InputSelect, showOrHideField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const loanPurposesSet = new Set(formData?.loanPurposes?.map((purpose) => purpose.definition.toLowerCase()));
	const hasNewBuildPurpose = loanPurposesSet.has('newbuild');
	const hasRenovationPurpose = loanPurposesSet.has('renovation');
	const hasPurchasePurpose = loanPurposesSet.has('purchase');
	const hasRefinancePurpose = loanPurposesSet.has('refinance');
	const hasBuyOutPurpose = loanPurposesSet.has('buyout');
	const numberOfLoanPurposes = loanPurposesSet.size;
	const mortgageImmovable: LiabilityType = 'mortgageImmovable';
	const liabilityType = resources?.[ResourceType.LIABILITY_TYPE]?.find((item) => item.definition === mortgageImmovable);

	const questions = [
		new InputField({
			key: 'defaultRefinanceLiabilityType',
			type: 'hidden',
			value: liabilityType,
			required: false,
		}),
		new InputField({
			key: 'buyOutAmount',
			label: 'ç.question.buyOut.label',
			value: hasBuyOutPurpose ? formData?.buyOutAmount : 0,
			type: 'number',
			currency: true,
			required: false,
			readonly: readonlyMode,
			class: 'span12',
			order: 1,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'buyOutAmount', 'number', hasBuyOutPurpose, false),
		}),
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.realtyPrice.label',
			value: hasPurchasePurpose ? formData?.realtyPrice : 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			order: 1,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'realtyPrice', 'number', hasPurchasePurpose, false),
		}),
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceLand.label',
			value: hasNewBuildPurpose ? formData?.priceOfLand : 0,
			type: 'number',
			readonly: readonlyMode,
			required: false,
			currency: true,
			class: 'span12',
			order: 1,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'priceOfLand', 'number', hasNewBuildPurpose, false),
		}),
		new InputField({
			key: 'buildingCosts',
			label: 'ç.question.priceBuilding.label',
			value: hasNewBuildPurpose ? formData?.buildingCosts : 0,
			type: 'number',
			readonly: readonlyMode,
			required: false,
			currency: true,
			class: 'span12',
			order: 1,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'buildingCosts', 'number', hasNewBuildPurpose, false),
		}),
		new InputField({
			key: 'refinancingAmount',
			label: `ç.question.${numberOfLoanPurposes === 1 ? 'refinancingOutstandingBalance' : 'refinancingAmount'}.label`,
			helpText: of(
				`ç.feature.mortgageSimulator.${numberOfLoanPurposes === 1 ? 'refinancingOutstandingBalance' : 'refinancingAmount'}.helpText`
			),
			value: hasRefinancePurpose ? formData?.refinancingAmount : 0,
			type: 'number',
			currency: true,
			readonly: readonlyMode,
			required: false,
			class: 'span12',
			order: numberOfLoanPurposes === 1 ? 2 : 3,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'refinancingAmount', 'number', hasRefinancePurpose, false),
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.venalValueBeforeRenovation.label',
			value: hasRenovationPurpose && numberOfLoanPurposes === 1 ? formData?.venalValueBefore : 0,
			type: 'number',
			currency: true,
			readonly: readonlyMode,
			required: false,
			class: 'span12',
			order: 1,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(
					formGroup,
					formConfiguration,
					'venalValueBefore',
					'number',
					hasRenovationPurpose && numberOfLoanPurposes === 1,
					false
				),
		}),
		new DynamicInputTable({
			key: 'renovationCosts',
			value: hasRenovationPurpose ? formData?.renovationCosts : [],
			debounceTime: debounceTimes.s,
			required: false,
			addRowLabel: 'ç.feature.mortgageSimulator.addRenovation',
			label: 'ç.feature.mortgageSimulator.addRenovation',
			disabled: readonlyMode,
			order: 1,
			columns: [
				new InputSelect({
					key: 'type.id',
					required: true,
					clearable: true,
					disabled: readonlyMode,
					label: 'ç.question.renovationType.label',
					validators: [],
					options: resources?.[ResourceType.RENOVATION_TYPE] || [],
					alreadySorted: true,
					class: 'span8',
				}),
				new InputField({
					key: 'amount',
					type: 'number',
					disabled: readonlyMode,
					label: 'ç.question.renovationAmount.label',
					required: true,
					currency: true,
					class: 'span4',
				}),
			],
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(formGroup, formConfiguration, 'renovationCosts', 'table', hasRenovationPurpose, false),
		}),
		new InputField({
			key: 'venalValueAfter',
			label:
				hasRefinancePurpose && numberOfLoanPurposes === 1
					? 'ç.question.fairMarketValue.label'
					: 'ç.question.venalValueAfterRenovation.label',
			helpText:
				hasRefinancePurpose && numberOfLoanPurposes === 1
					? of('ç.feature.mortgageSimulator.fairMarketValue.helpText')
					: of('ç.feature.mortgageSimulator.venalValueAfterRenovation.helpText'),
			value: hasRenovationPurpose || (hasRefinancePurpose && numberOfLoanPurposes === 1) ? formData?.venalValueAfter : 0,
			type: 'number',
			currency: true,
			readonly: readonlyMode,
			required: false,
			class: 'span12',
			order: 1,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration) =>
				showOrHideField(
					formGroup,
					formConfiguration,
					'venalValueAfter',
					'number',
					hasRenovationPurpose || (hasRefinancePurpose && numberOfLoanPurposes === 1),
					false
				),
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.region.label',
			value: formData?.region?.id,
			helpText: of('ç.feature.mortgageSimulator.region.helpText'),
			required: false,
			disabled: readonlyMode,
			validators: [],
			options: resources?.[ResourceType.REGION] || [],
			alreadySorted: true,
			class: 'span12',
			order: 2,
		}),
		new InputField({
			key: 'isMainFirstResidence',
			label: 'ç.question.mainFirstResidence.label',
			value: formData?.isMainFirstResidence,
			type: 'checkbox',
			disabled: readonlyMode,
			class: 'span12',
			required: false,
			order: 2,
		}),
		new DynamicInputTable({
			key: 'additionalNeeds',
			value: formData?.additionalNeeds,
			debounceTime: debounceTimes.s,
			addRowLabel: 'ç.feature.mortgageSimulator.additionalNeeds.addRowLabel',
			required: false,
			disabled: readonlyMode,
			secondaryAction: true,
			secondaryActionLabel: 'ç.feature.mortgageSimulator.additionalNeeds.secondaryActionLabel',
			order: 3,
			columns: [
				new InputSelect({
					key: 'type.id',
					required: true,
					disabled: readonlyMode,
					clearable: true,
					label: 'ç.feature.additionalFinancingNeed.plural',
					validators: [],
					options: resources?.[ResourceType.ADDITIONAL_FINANCING_TYPE] || [],
					alreadySorted: true,
					class: 'span8',
				}),
				new InputField({
					key: 'amount',
					label: 'ç.question.amount.label',
					type: 'number',
					disabled: readonlyMode,
					required: true,
					currency: true,
					class: 'span4',
				}),
			],
		}),
	];

	return orderBy(questions, 'order');
}
