import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	input,
	Input,
	OnInit,
	Optional,
	Output,
	Self,
} from '@angular/core';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { BehaviorSubject, noop, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DestroyableComponent } from '@shared/util-component';
import { FormGroupWithWarning } from '../../models/form-warning.model';
import { UtilService } from '@oper-client/shared/util-formatting';
import { debounceTimes } from '@oper-client/shared/configuration';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { removeNullValues } from '@oper-client/shared/util-object';

@Component({
	selector: 'oper-client-dynamic-input-form-items',
	templateUrl: './dynamic-input-form-items.component.html',
	styleUrl: './dynamic-input-form-items.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicInputFormItemsComponent extends DestroyableComponent implements OnInit, AfterViewInit, ControlValueAccessor {
	readonly formReferences$ = new BehaviorSubject<FormGroupWithWarning[]>([]);
	readonly removeRow$ = new Subject<number>();
	readonly formChanged$ = new Subject<{ index: number; form: FormGroupWithWarning }>();

	readonly itemTitle = input<string>('');
	readonly cardTitle = input<string>('');
	readonly showAsCard = input<boolean>(false);
	readonly showDeleteButton = input<boolean>(false);
	@Input() rows: FormConfiguration[];
	@Input() debounceTime = debounceTimes.s;
	@Input() addItemLabel: string;
	@Input() disabled = false;

	@Output() valueChange = new EventEmitter<any>();
	@Output() removeRow = new EventEmitter<number>();
	@Output() addRow = new EventEmitter<void>();

	readonly hasInvalidForm$ = this.formReferences$.pipe(map((forms) => forms.some((form) => form.invalid)));

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;

	constructor(
		@Self() @Optional() public control: NgControl,
		readonly utilService: UtilService
	) {
		super();
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	ngAfterViewInit(): void {
		this.formReferences$
			.pipe(
				filter((forms) => forms.every((form) => form.valid)),
				debounceTime(this.debounceTime),
				map((forms) =>
					forms
						.map((form) => removeNullValues(this.utilService.erectObject(form.value)))
						.filter((form) => Object.keys(form).length > 0)
				),
				takeUntil(this.destroy$)
			)
			.subscribe((values) => {
				this.valueChange.emit(values);
			});
	}

	ngOnInit(): void {
		this.removeRow$.pipe(withLatestFrom(this.formReferences$), takeUntil(this.destroy$)).subscribe(([index, formReferences]) => {
			const forms = formReferences.filter((_, i) => i !== index);
			this.formReferences$.next(forms);
			if (forms.length === 0) {
				this.valueChange.emit([]);
			}
			this.removeRow.emit(index);
		});

		this.formChanged$
			.pipe(withLatestFrom(this.formReferences$), takeUntil(this.destroy$))
			.subscribe(([{ index, form }, formReferences]) => {
				if (formReferences[index]) {
					formReferences[index] = form;
				} else {
					formReferences.push(form);
				}
				this.formReferences$.next(formReferences);
			});
	}

	writeValue(obj: any[]): void {}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(disabled: boolean): void {}
}
