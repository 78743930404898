import {
	ActionState,
	initialActionState,
	Step,
	StepDataPoint,
	findStepByName,
	generateDataObjectFromStepDataPoints,
	Resource,
	ActionTypes,
	setActionState,
	Simulator,
	BorrowerSimulationDto,
	Offer,
	updateStepDataPoints,
	BaseProductDiscounts,
	Product,
} from '@oper-client/shared/data-model';
import { createRehydrateReducer } from '@oper-client/shared/util-client-storage';
import * as ApplicationFlowActions from './borrower-simulator-application-flow.actions';
import { on } from '@ngrx/store';
import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { updateListItem } from '@oper-client/shared/util-array';

export const BORROWER_SIMULATOR_APPLICATION_FLOW_KEY = 'borrowerSimulatorApplicationFlow';
export const BORROWER_SIMULATOR_PROJECT_PURPOSE_STEP = 'projectPurpose';
export const BORROWER_SIMULATOR_NUMBER_OF_BORROWERS_STEP = 'numberOfBorrowers';

export type BorrowerSimulatorApplicationFlowActionTypes =
	| 'calculateSimulation'
	| 'loadOffers'
	| 'updateOffer'
	| 'loadDiscounts'
	| 'loadDefaultProducts';
export type BorrowerSimulatorApplicationFlowActionsState = Record<BorrowerSimulatorApplicationFlowActionTypes, ActionState>;

export interface BorrowerSimulatorApplicationFlowState {
	products: Product[];
	discounts: Partial<BaseProductDiscounts>[];
	simulation: Partial<Simulator.Simulation> | null;
	offers: Partial<Offer>[];
	configuration: BorrowerSimulatorFeatureConfiguration | null;
	activeStep: Step | null;
	dataPoints: StepDataPoint | null;
	data: Partial<BorrowerSimulationDto> | null;
	selectedPurpose: Resource | null;
	numberOfBorrowers: Resource | null;
	actions: BorrowerSimulatorApplicationFlowActionsState;
}

export const initialState: BorrowerSimulatorApplicationFlowState = {
	simulation: null,
	products: [],
	offers: [],
	discounts: [],
	configuration: null,
	activeStep: null,
	dataPoints: null,
	data: null,
	selectedPurpose: null,
	numberOfBorrowers: null,
	actions: {
		calculateSimulation: initialActionState,
		loadOffers: initialActionState,
		loadDiscounts: initialActionState,
		loadDefaultProducts: initialActionState,
		updateOffer: initialActionState,
	},
};

function setActionStates(
	actionState: BorrowerSimulatorApplicationFlowActionsState,
	action: BorrowerSimulatorApplicationFlowActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): BorrowerSimulatorApplicationFlowActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createRehydrateReducer(
	BORROWER_SIMULATOR_APPLICATION_FLOW_KEY,
	initialState,
	on(ApplicationFlowActions.calculateSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.calculateSimulationSuccess, (state, { result, payload }) => ({
		...state,
		simulation: { ...state.simulation, ...payload, ...result },
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.success),
	})),
	on(ApplicationFlowActions.calculateSimulationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.loadOffers, (state, { payload }) => ({
		...state,
		simulation: { ...state.simulation, ...payload },
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.loadOffersSuccess, (state, { offers }) => ({
		...state,
		offers,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.success),
	})),
	on(ApplicationFlowActions.loadOffersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.loadDiscounts, (state) => ({
		...state,
		discounts: initialState.discounts,
		actions: setActionStates(state.actions, 'loadDiscounts', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.loadDiscountsSuccess, (state, { discounts }) => ({
		...state,
		discounts,
		actions: setActionStates(state.actions, 'loadDiscounts', ActionTypes.success),
	})),
	on(ApplicationFlowActions.loadDiscountsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDiscounts', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.loadDefaultProducts, (state) => ({
		...state,
		products: initialState.products,
		actions: setActionStates(state.actions, 'loadDefaultProducts', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.loadDefaultProductsSuccess, (state, { products }) => ({
		...state,
		products,
		actions: setActionStates(state.actions, 'loadDefaultProducts', ActionTypes.success),
	})),
	on(ApplicationFlowActions.loadDefaultProductsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadDefaultProducts', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.updateOffer, (state, { payload }) => ({
		...state,
		simulation: { ...state.simulation, selectedProducts: payload.selectedProducts as Product[] },
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.loading),
	})),
	on(ApplicationFlowActions.updateOfferSuccess, (state, { offer }) => ({
		...state,
		offers: updateListItem(
			state.offers,
			state.offers.findIndex((o) => o.id === offer.id),
			offer
		),
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.success),
	})),
	on(ApplicationFlowActions.updateOfferFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.failure, error),
	})),
	on(ApplicationFlowActions.setConfiguration, (state, { configuration }) => ({
		...state,
		configuration,
	})),
	on(ApplicationFlowActions.updateConfiguration, (state, { changes }) => ({
		...state,
		configuration: { ...state.configuration, ...changes },
	})),
	on(ApplicationFlowActions.setActiveStep, (state, { step }) => ({
		...state,
		activeStep: step,
	})),
	on(ApplicationFlowActions.updateActiveStep, (state, { changes }) => ({
		...state,
		activeStep: { ...state.activeStep, ...changes },
	})),
	on(ApplicationFlowActions.nextStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.next),
	})),
	on(ApplicationFlowActions.prevStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.back),
	})),
	on(ApplicationFlowActions.setData, (state, { data }) => ({
		...state,
		data,
	})),
	on(ApplicationFlowActions.updateData, (state, { data }) => ({
		...state,
		data: { ...state.data, ...data },
		dataPoints: updateStepDataPoints(state.dataPoints, { ...state.data, ...data }),
	})),
	on(ApplicationFlowActions.setDataForStep, (state, { step, data }) => {
		const dataPoints = { ...state.dataPoints, [step]: data };
		const updatedData = { ...initialState.data, ...generateDataObjectFromStepDataPoints<any>(dataPoints) };
		return {
			...state,
			dataPoints,
			data: updatedData,
		};
	}),
	on(ApplicationFlowActions.setStepFormConfiguration, (state, { configuration }) => ({
		...state,
		stepFormConfiguration: configuration,
	})),
	on(ApplicationFlowActions.setSelectedPurpose, (state, { purpose }) => ({
		...state,
		selectedPurpose: purpose,
	})),
	on(ApplicationFlowActions.setSimulation, (state, { simulation }) => ({
		...state,
		simulation,
	})),
	on(ApplicationFlowActions.setSelectedBorrowerMode, (state, { borrowerMode }) => ({
		...state,
		numberOfBorrowers: borrowerMode,
	})),
	on(ApplicationFlowActions.reset, () => ({
		...initialState,
	})),
	on(ApplicationFlowActions.clearData, (state) => ({
		...state,
		data: initialState.data,
		dataPoints: initialState.dataPoints,
		selectedPurpose: initialState.selectedPurpose,
		numberOfBorrowers: initialState.numberOfBorrowers,
		simulation: initialState.simulation,
		offers: initialState.offers,
		discounts: initialState.discounts,
		products: initialState.products,
		actions: initialState.actions,
	})),
	on(ApplicationFlowActions.resetSimulationReport, (state) => ({
		...state,
		simulation: initialState.simulation,
		offers: initialState.offers,
		discounts: initialState.discounts,
		products: initialState.products,
		actions: initialState.actions,
	}))
);
