export enum GtmEventNameEnum {
	APPOINTMENT_BOOKING_CTA_CLICK = 'appointment-booking/cta-click',
	AUTH_CODE_ENTER = 'auth/code-enter',
	AUTH_PRIVACY_POLICY_LINK = 'auth/privacy-policy-link',
	AUTH_RESEND_CODE = 'auth/resend-code',
	AUTH_RESET_PASSWORD = 'auth/reset-password',
	AUTH_RESET_PASSWORD_LINK = 'auth/reset-password-link',
	AUTH_SIGN_IN = 'auth/sign-in',
	AUTH_SIGN_IN_LINK = 'auth/sign-in-link',
	AUTH_SIGN_OUT = 'auth/sign-out',
	AUTH_SIGN_UP = 'auth/sign-up',
	AUTH_SIGN_UP_LINK = 'auth/sign-up-link',
	BORROWER_STATUS_CARD_GO_TO_DOCUMENT = 'borrower-status-card/go-to-document',
	BORROWER_STATUS_CARD_GO_TO_PREAPPROVAL = 'borrower-status-card/go-to-preapproval',
	CONTACT_FORM_SHOW = 'contact-form/show',
	CONTACT_FORM_SUBMIT = 'contact-form/submit',
	COOKIE_CONSENT_MODAL_ALLOW_ALL = 'cookie-consent-modal/allow-all',
	COOKIE_CONSENT_MODAL_ALLOW_ESSENTIAL = 'cookie-consent-modal/allow-essential',
	COOKIE_CONSENT_MODAL_ALLOW_FUNCTIONAL = 'cookie-consent-modal/allow-functional',
	DOCUMENT_FILE_BROWSE = 'document/file-browse',
	DOCUMENT_FILE_NEXT_BUTTON_CLICKED = 'document/next-button-clicked',
	DOCUMENT_FILE_REMOVE = 'document/file-remove',
	DOCUMENT_FILE_UPLOAD = 'document/file-upload',
	DOCUMENT_FILE_UPLOAD_SUCCESS = 'document/file-upload-success',
	DOCUMENT_FILE_VIEW = 'document/file-view',
	DOCUMENT_LIBRARY_DELETE_CTA = 'document-library/delete',
	DOCUMENT_LIBRARY_UPLOAD_FILE_CTA = 'document-library/upload',
	DOCUMENT_LIBRARY_UPLOAD_FILE_ERROR = 'document-library/upload-error',
	DOCUMENT_LIBRARY_UPLOAD_FILE_SUCCESS = 'document-library/upload-success',
	DOCUMENT_SCANBOT_ADD_ANOTHER_PAGE = 'document/scanbot-add-another-page',
	DOCUMENT_SCANBOT_INIT = 'document/scanbot-init',
	DOCUMENT_SCANBOT_UPLOAD = 'document/scanbot-upload',
	DOCUMENT_SCANBOT_UPLOAD_SUCCESS = 'document/scanbot-upload-success',
	DOCUMENT_UPLOAD = 'document/upload',
	DOCUMENT_UPLOAD_SUCCESS = 'document/upload-success',
	DOCUMENT_VIEW = 'document/view',
	LANDING_PAGE_CTA = 'landing-page/cta',
	LANGUAGE_SELECT = 'language/select',
	LOAN_REQUEST_ADD_ACQUISITION_SOURCE = 'loan-request/add-acquisition-source',
	LOAN_REQUEST_ADD_ALLOCATED_PRODUCTS_TO_OFFER = 'loan-request/add-allocated-products-to-offer',
	LOAN_REQUEST_ADD_LOAN_PURPOSE = 'loan-request/add-loan-purpose',
	LOAN_REQUEST_ADD_NEW_DOCUMENT_CONFIRMED = 'loan-request/add-new-document-confirmed',
	LOAN_REQUEST_ADD_NEW_SIGNING_REQUIREMENT_CONFIRMED = 'loan-request/add-new-signing-requirement-confirmed',
	LOAN_REQUEST_APPROVE_PROOF_DOCUMENT = 'loan-request/approve-proof-document',
	LOAN_REQUEST_ASSIGN_ANALYST_TO_LR = 'loan-request/assign-analyst-to-loan-request',
	LOAN_REQUEST_ASSIGN_BROKER_TO_LR = 'loan-request/assign-broker-to-loan-request',
	LOAN_REQUEST_BOARD_VIEW_CHANGE_STATUS = 'loan-request/board-view-change-status',
	LOAN_REQUEST_CHANGE_STATUS = 'loan-request/change-status',
	LOAN_REQUEST_CHANGE_STATUS_ERROR = 'loan-request/change-status-error',
	LOAN_REQUEST_CLEAR_FILTERS = 'loan-request/clear-filters',
	LOAN_REQUEST_CLICK_CREATE_NEW_LOAN_REQUEST = 'loan-request/click-create-new-loan-request',
	LOAN_REQUEST_CLOSE_ADD_NEW_DOCUMENT_MODAL = 'loan-request/close-add-new-document-modal',
	LOAN_REQUEST_CLOSE_ADD_NEW_SIGNING_DOCUMENT_MODAL = 'loan-request/close-add-new-signing-document-modal',
	LOAN_REQUEST_CLOSE_APPLY_DISCOUNTS_MODAL = 'loan-request/close-apply-discounts-modal',
	LOAN_REQUEST_CLOSE_ASSIGN_BROKER_MODAL = 'loan-request/close-assign-broker-modal',
	LOAN_REQUEST_CLOSE_DATA_PUSH_MODAL = 'loan-request/close-data-push-modal',
	LOAN_REQUEST_CLOSE_DELETE_DOCUMENT_MODAL = 'loan-request/close-delete-document-modal',
	LOAN_REQUEST_CLOSE_REJECT_PROOF_MODAL = 'loan-request/close-reject-proof-modal',
	LOAN_REQUEST_CLOSE_REMOVE_OFFER_MODAL = 'loan-request/close-remove-offer-modal',
	LOAN_REQUEST_CLOSE_REMOVE_PRODUCT_ITEM_MODAL = 'loan-request/close-remove-product-item-modal',
	LOAN_REQUEST_CREATE_NEW_LOAN_REQUEST_ERROR = 'loan-request/create-new-loan-request-error',
	LOAN_REQUEST_CREATE_NEW_LOAN_REQUEST_SUCCESS = 'loan-request/create-new-loan-request-success',
	LOAN_REQUEST_CREATE_OFFER = 'loan-request/create-offer',
	LOAN_REQUEST_CREATE_OFFER_ERROR = 'loan-request/create-offer-error',
	LOAN_REQUEST_CREATE_OFFER_SUCCESS = 'loan-request/create-offer-success',
	LOAN_REQUEST_CREATE_PROOF_DOCUMENT_ERROR = 'loan-request/create-proof-document-error',
	LOAN_REQUEST_CREATE_PROOF_DOCUMENT_SUCCESS = 'loan-request/create-proof-document-success',
	LOAN_REQUEST_DELETE_DOCUMENT_ICON_CLICK = 'loan-request/delete-document-icon-click',
	LOAN_REQUEST_DELETE_PROOF_DOCUMENT = 'loan-request/delete-proof-document',
	LOAN_REQUEST_DELETE_PROOF_DOCUMENT_ERROR = 'loan-request/delete-proof-document-error',
	LOAN_REQUEST_DELETE_PROOF_DOCUMENT_SUCCESS = 'loan-request/delete-proof-document-success',
	LOAN_REQUEST_DOWNLOAD_ALL_DOCUMENTS = 'loan-request/download-all-documents',
	LOAN_REQUEST_GENERATE_ALL_DOCUMENTS = 'loan-request/generate-all-documents',
	LOAN_REQUEST_GENERATE_BROKER_ADVISOR_SHEET = 'loan-request/generate-broker-sheet',
	LOAN_REQUEST_GENERATE_BROKER_PRESENTATION = 'loan-request/generate-broker-presentation',
	LOAN_REQUEST_GENERATE_GDPR_STATEMENT_DOCUMENT = 'loan-request/generate-gdpr-statement-document',
	LOAN_REQUEST_GENERATE_LOAN_REQUEST_PDF_FORM = 'loan-request/generate-loan-request-pdf-form',
	LOAN_REQUEST_GENERATE_SIMULATOR_DOCUMENT = 'loan-request/generate-simulation-document',
	LOAN_REQUEST_HIDE_OFFER_ON_SELF_SERVICE = 'loan-request/hide-offer-on-self-service',
	LOAN_REQUEST_HISTORY_DOCUMENT_ICON_CLICK = 'loan-request/history-document-icon-click',
	LOAN_REQUEST_LEARN_MORE_ABOUT_ACCEPTANCE_CRITERIA_CLICK = 'loan-request/learn-more-about-acceptance-criteria-click',
	LOAN_REQUEST_LIST_VIEW_ANALYST_FILTER_CHANGED = 'loan-request/list-view-analyst-filter-changed',
	LOAN_REQUEST_LIST_VIEW_BROKER_FILTER_CHANGED = 'loan-request/list-view-broker-filter-changed',
	LOAN_REQUEST_LIST_VIEW_CHANGE_STATUS = 'loan-request/list-view-change-status',
	LOAN_REQUEST_LIST_VIEW_DEED_FILTER_CHANGED = 'loan-request/list-view-deed-filter-changed',
	LOAN_REQUEST_LIST_VIEW_LOAD_MORE_LR = 'loan-request/list-view-load-more-lr',
	LOAN_REQUEST_LIST_VIEW_STATUS_FILTER_CHANGED = 'loan-request/list-view-status-filter-changed',
	LOAN_REQUEST_OFFER_ALLOCATION_CLOSE_ACCEPTANCE_CRITERIA_MODAL = 'loan-request/offer-allocation-close-acceptance-criteria-modal',
	LOAN_REQUEST_OFFER_ALLOCATION_OPEN_ACCEPTANCE_CRITERIA_MODAL = 'loan-request/offer-allocation-open-acceptance-criteria-modal',
	LOAN_REQUEST_OFFER_OVERVIEW_CLOSE_ACCEPTANCE_CRITERIA_MODAL = 'loan-request/offer-overview-close-acceptance-criteria-modal',
	LOAN_REQUEST_OFFER_OVERVIEW_OPEN_ACCEPTANCE_CRITERIA_MODAL = 'loan-request/offer-overview-open-acceptance-criteria-modal',
	LOAN_REQUEST_OPEN_ADD_NEW_DOCUMENT_MODAL = 'loan-request/open-add-new-document-modal',
	LOAN_REQUEST_OPEN_ADD_NEW_SIGNING_DOCUMENT_MODAL = 'loan-request/open-add-new-signing-document-modal',
	LOAN_REQUEST_OPEN_APPLY_DISCOUNTS_MODAL = 'loan-request/open-apply-discounts-modal',
	LOAN_REQUEST_OPEN_ASSIGN_BROKER_MODAL = 'loan-request/open-assign-broker-modal',
	LOAN_REQUEST_OPEN_BLOCKING_ERRORS_TOOLTIP = 'loan-request/open-blocking-errors-tooltip',
	LOAN_REQUEST_OPEN_DATA_PUSH_MODAL = 'loan-request/open-data-push-modal',
	LOAN_REQUEST_OPEN_DELETE_DOCUMENT_MODAL = 'loan-request/open-delete-document-modal',
	LOAN_REQUEST_OPEN_FINANCIAL_OFFER_DETAILS_MODAL = 'loan-request/open-financial-offer-details-modal',
	LOAN_REQUEST_OPEN_OFFER_ALLOCATION = 'loan-request/open-offer-allocation',
	LOAN_REQUEST_OPEN_PRODUCT_EXPLORER = 'loan-request/open-product-explorer',
	LOAN_REQUEST_OPEN_REJECT_PROOF_MODAL = 'loan-request/open-reject-proof-modal',
	LOAN_REQUEST_OPEN_REMOVE_OFFER_MODAL = 'loan-request/open-remove-offer-modal',
	LOAN_REQUEST_OPEN_REMOVE_PRODUCT_ITEM_MODAL = 'loan-request/open-remove-product-item-modal',
	LOAN_REQUEST_PRODUCT_EXPLORER_CLOSE_ACCEPTANCE_CRITERIA_MODAL = 'loan-request/product-explorer-close-acceptance-criteria-modal',
	LOAN_REQUEST_PRODUCT_EXPLORER_CLOSE_UNRESOLVED_CRITERIA_MODAL = 'loan-request/product-explorer-close-unresolved-criteria-modal',
	LOAN_REQUEST_PRODUCT_EXPLORER_OPEN_ACCEPTANCE_CRITERIA_MODAL = 'loan-request/product-explorer-open-acceptance-criteria-modal',
	LOAN_REQUEST_PRODUCT_EXPLORER_OPEN_UNRESOLVED_CRITERIA_MODAL = 'loan-request/product-explorer-open-unresolved-criteria-modal',
	LOAN_REQUEST_REJECT_PROOF_DOCUMENT = 'loan-request/reject-proof-document',
	LOAN_REQUEST_REMOVE_OFFER = 'loan-request/remove-offer',
	LOAN_REQUEST_REMOVE_OFFER_ERROR = 'loan-request/remove-offer-error',
	LOAN_REQUEST_REMOVE_OFFER_SUCCESS = 'loan-request/remove-offer-success',
	LOAN_REQUEST_REMOVE_PRODUCT_ITEM = 'loan-request/remove-product-item',
	LOAN_REQUEST_SCROLL_TO_DISCOUNTS = 'loan-request/scroll-to-discounts',
	LOAN_REQUEST_SEARCH_LOAN_REQUESTS = 'loan-request/search-loan-requests',
	LOAN_REQUEST_SEARCH_PRODUCTS = 'loan-request/search-products',
	LOAN_REQUEST_SEARCH_PRODUCTS_ERROR = 'loan-request/search-products-error',
	LOAN_REQUEST_SEE_SIMULATION = 'loan-request/see-simulation',
	LOAN_REQUEST_SELECT_ALL_DOCUMENTS = 'loan-request/select-all-documents',
	LOAN_REQUEST_SELECT_DOCUMENT = 'loan-request/select-document',
	LOAN_REQUEST_SELECT_OFFER = 'loan-request/select-offer',
	LOAN_REQUEST_SELECT_PRODUCT = 'loan-request/select-product',
	LOAN_REQUEST_SEND_DOCUMENTS_TO_LENDER = 'loan-request/send-documents-to-lender',
	LOAN_REQUEST_SEND_TO_LENDER = 'loan-request/send-to-lender',
	LOAN_REQUEST_SHARE_WITH_BORROWER_DOCUMENT_ICON_CLICK = 'loan-request/share-with-borrower-document-icon-click',
	LOAN_REQUEST_SHOW_ALL_LOAN_REQUESTS = 'loan-request/show-all-loan-requests',
	LOAN_REQUEST_SHOW_BOARD_VIEW = 'loan-request/show-board-view',
	LOAN_REQUEST_SHOW_FULL_TIMELINE = 'loan-request/show-full-timeline',
	LOAN_REQUEST_SHOW_LIST_VIEW = 'loan-request/show-list-view',
	LOAN_REQUEST_SHOW_MANUAL_ACCEPTANCE_CRITERIA_CHECKS_TAB = 'loan-request/show-manual-acceptance-criteria-checks-tab',
	LOAN_REQUEST_SHOW_MY_LOAN_REQUESTS = 'loan-request/show-my-loan-requests',
	LOAN_REQUEST_SHOW_OFFER_ON_SELF_SERVICE = 'loan-request/show-offer-on-self-service',
	LOAN_REQUEST_UNSELECT_DOCUMENT = 'loan-request/unselect-document',
	LOAN_REQUEST_UNSELECT_OFFER = 'loan-request/unselect-offer',
	LOAN_REQUEST_UNSELECT_PRODUCT = 'loan-request/unselect-product',
	LOAN_REQUEST_UPDATE_COST_AMOUNTS = 'loan-request/update-loan-request-cost-amounts',
	LOAN_REQUEST_UPDATE_DISCOUNTS = 'loan-request/update-discounts',
	LOAN_REQUEST_UPDATE_DOCUMENT_STATUS = 'loan-request/update-document-status',
	LOAN_REQUEST_UPDATE_OFFER = 'loan-request/update-offer',
	LOAN_REQUEST_UPDATE_OFFER_ERROR = 'loan-request/update-offer-error',
	LOAN_REQUEST_UPDATE_OFFER_SUCCESS = 'loan-request/update-offer-success',
	LOAN_REQUEST_UPLOAD_DOCUMENT_ICON_CLICK = 'loan-request/upload-document-icon-click',
	LOAN_REQUEST_UPLOAD_PROOF_DOCUMENT = 'loan-request/upload-proof-document',
	LOAN_REQUEST_UPLOAD_PROOF_DOCUMENT_ERROR = 'loan-request/upload-proof-document-error',
	LOAN_REQUEST_UPLOAD_PROOF_DOCUMENT_SUCCESS = 'loan-request/upload-proof-document-success',
	LOAN_REQUEST_VIEW_DOCUMENT_ICON_CLICK = 'loan-request/view-document-icon-click',
	MORTGAGE_SIMULATOR_END = 'mortgage-simulator/end',
	MORTGAGE_SIMULATOR_INCOME_SUBMIT = 'mortgage-simulator/income-submit',
	MORTGAGE_SIMULATOR_INTEREST_INPUT = 'mortgage-simulator/interest-input',
	MORTGAGE_SIMULATOR_LIABILITY_SUBMIT = 'mortgage-simulator/liability-submit',
	MORTGAGE_SIMULATOR_OWN_FUNDS_SUBMIT = 'mortgage-simulator/own-funds-submit',
	MORTGAGE_SIMULATOR_PATH = 'mortgage-simulator/path',
	MORTGAGE_SIMULATOR_RATE_INPUT = 'mortgage-simulator/rate-input',
	MORTGAGE_SIMULATOR_REALTY_SUBMIT = 'mortgage-simulator/realty-submit',
	MORTGAGE_SIMULATOR_RECALCULATE = 'mortgage-simulator/recalculate',
	MORTGAGE_SIMULATOR_SIGN_UP = 'mortgage-simulator/sign-up',
	MORTGAGE_SIMULATOR_START = 'mortgage-simulator/start',
	MORTGAGE_SIMULATOR_START_OVER = 'mortgage-simulator/start-over',
	NAVIGATION_ITEM_CLICKED = 'navigation/item-click',
	NAVIGATION_SHOW = 'navigation/show',
	PRE_APPROVAL_END = 'pre-approval/end',
	PRE_APPROVAL_START = 'pre-approval/start',
	PRE_APPROVAL_STEP_BORROWER_MODE_SUBMIT = 'pre-approval/step-borrower-mode-submit',
	PRE_APPROVAL_STEP_CLIENT_SUBMIT = 'pre-approval/step-client-submit',
	PRE_APPROVAL_STEP_CLOSURE_SUBMIT = 'pre-approval/step-closure-submit',
	PRE_APPROVAL_STEP_DIP_SUBMIT = 'pre-approval/step-dip-submit',
	PRE_APPROVAL_STEP_ERROR_SUBMIT = 'pre-approval/step-error-submit',
	PRE_APPROVAL_STEP_FINANCIAL_ASSET_SUBMIT = 'pre-approval/step-financial-asset-submit',
	PRE_APPROVAL_STEP_FINANCIAL_DATA_SUBMIT = 'pre-approval/step-financial-data-submit',
	PRE_APPROVAL_STEP_INCOME_SUBMIT = 'pre-approval/step-income-submit',
	PRE_APPROVAL_STEP_LIABILITY_SUBMIT = 'pre-approval/step-liability-submit',
	PRE_APPROVAL_STEP_OFFER_SUBMIT = 'pre-approval/step-offer-submit',
	PRE_APPROVAL_STEP_OFFICE_SELECTOR_SUBMIT = 'pre-approval/step-office-selector-submit',
	PRE_APPROVAL_STEP_OWN_FUNDS_SUBMIT = 'pre-approval/step-own-funds-submit',
	PRE_APPROVAL_STEP_PREAPPROVAL_SUBMIT = 'pre-approval/step-preapproval-submit',
	PRE_APPROVAL_STEP_REALTY_ADDRESS_TOGGLE = 'pre-approval/step-realty-address-toggle',
	PRE_APPROVAL_STEP_REALTY_SUBMIT = 'pre-approval/step-realty-submit',
	PRE_APPROVAL_STEP_TAX_SUBMIT = 'pre-approval/step-tax-submit',
	SIDEBAR_NAVIGATION_AVATAR_MENU_LOG_OUT = 'sidebar-navigation/avatar-menu-log-out',
	SIDEBAR_NAVIGATION_AVATAR_MENU_SELECT_LANGUAGE = 'sidebar-navigation/avatar-menu-select-language',
	SIDEBAR_NAVIGATION_SHOW_AVATAR_MENU = 'sidebar-navigation/show-avatar-menu',
	SIDEBAR_NAVIGATION_TOGGLE_SIDEBAR_COLLAPSE = 'sidebar-navigation/toggle-sidebar-collapse',
	SIDEBAR_NAVIGATION_TOGGLE_SIDEBAR_EXPAND = 'sidebar-navigation/toggle-sidebar-expand',
	SIMULATOR_ADD_BORROWER = 'simulator/add_borrower',
	SIMULATOR_ADD_PROJECT_CONTINUE = 'simulator/add_project_continue',
	SIMULATOR_APPLY_DISCOUNTS = 'simulator/apply_discounts',
	SIMULATOR_APPLY_PROCEED_FROM_SALE = 'simulator/cta-apply-proceeds-from-sale',
	SIMULATOR_ASSESS_FINANCES_CONTINUE = 'simulator/assess_finances_continue',
	SIMULATOR_CALCULATE_DTI = 'simulator/calculate_dti',
	SIMULATOR_CALCULATE_MONTHLY_PAYMENT_ESTIMATION_CTA = 'simulator/cta-calculate-monthly-payment-estimation',
	SIMULATOR_CONFIRM_CONVERSION = 'simulator/confirm_conversion',
	SIMULATOR_CONFIRM_PRINT = 'simulator/confirm_print',
	SIMULATOR_CREATE_SIMULATION = 'simulator/create_simulation',
	SIMULATOR_DELETE_CTA = 'simulator/cta-delete-simulation',
	SIMULATOR_EDIT = 'simulator/edit-simulation',
	SIMULATOR_EDIT_DETAILED_COST = 'simulator/cta-edit-detailed-costs',
	SIMULATOR_HEADER_ARCHIVE_SIMULATION = 'simulator/header_archive_simulation',
	SIMULATOR_INBOX_ARCHIVE_SIMULATION = 'simulator/inbox_archive_simulation',
	SIMULATOR_OPEN_LOAN_REQUEST_CTA = 'simulator/cta-open-loan-request',
	SIMULATOR_PREVIEW_CONVERSION = 'simulator/preview_conversion',
	SIMULATOR_PREVIEW_PRINT = 'simulator/preview_print',
	SIMULATOR_SAVE_CTA = 'simulator/cta-save-simulation',
	SIMULATOR_SELECT_PRODUCTS_CONTINUE = 'simulator/select_products_continue',
	SIMULATOR_SOLUTIONS_ADD = 'simulator/solutions_add',
	SIMULATOR_SOLUTIONS_EDIT_DISCOUNTS = 'simulator/solutions_edit_discounts',
	SIMULATOR_SOLUTIONS_EDIT_PRODUCTS = 'simulator/solutions_edit_products',
	SIMULATOR_VIEW_DETAILED_COST_CTA = 'simulator/cta-view-detailed-costs',
	SIMULATOR_VIEW_DISCOUNTS = 'simulator/view_discounts',
}
