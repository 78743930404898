import { DocumentTypes, Liability, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { InputBase } from '../../models/input-base.model';
import { DashedInputField, InputSelect } from '../../models/input-types.model';
import { Validators } from '@angular/forms';

export default function (
	formData?: Partial<Liability>,
	resources?: PartialNormalizedResource,
	allowedDocuments?: any,
	kind?: string,
): InputBase<any>[] {
	const options = allowedDocuments
		.map((current) => resources['document-type'].find((item) => item.definition === current.definition))
		.filter(Boolean);
	return [
		new InputSelect({
			key: 'documentType.id',
			label: 'ç.question.proofRequirement.label',
			required: true,
			options: options,
		}),
		...(kind === DocumentTypes.informative
			? [
					new DashedInputField({
						type: 'text',
						key: 'externalId',
						label: 'ç.question.info-document.label',
						required: true,
						placeholder: 'XXXX-XXXX-XXXX-XXXX',
						validators: [Validators.pattern('^[A-Za-z0-9]{4}(-[A-Za-z0-9]{4}){3}$')],
						errorLabel: 'ç.question.info-document.error',
						dashesConfig: [4, 8, 12, 16],
					}),
				]
			: []),
	];
}
