import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { generateGuid } from '@oper-client/shared/util-object';

import { FormConfiguration } from '../../models/dynamic-form.model';
import { FormGroupWithWarning } from '../../models/form-warning.model';
import { ControlType, InputBase } from '../../models/input-base.model';
import { InputField } from '../../models/input-types.model'; //TODO we should only deal with InputBase here
import { FormWarningService } from '../../services/form-warning.service';
import { ValidatorService } from '../../services/validator.service';
import { UnsavedDataService } from '@oper-client/shared/data-access';

@Component({
	selector: 'oper-client-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnDestroy {
	get formConfiguration(): FormConfiguration {
		return this._formConfiguration;
	}

	@Input()
	set formConfiguration(value: FormConfiguration) {
		this._formConfiguration = value;
		this.formName = value?.getName();
		this.initFormGroup(value);
		if (this._markAllAsTouched) {
			this.form.markAllAsTouched();
		}
		this.valueChange.emit(this.form);
		this.getErrors();
		this.getWarnings();
		this.formTrackingId.emit(this.formId);
		this.formInitialised.emit(this.form);
	}

	@Input()
	set markAllAsTouched(value: boolean) {
		this._markAllAsTouched = value;
		if (value) {
			this.form.markAllAsTouched();
		}
	}

	get markAllAsTouched(): boolean {
		return this._markAllAsTouched;
	}

	@Input() debounceTime: number;
	@Input() keydownDebounceExtension: boolean;
	@Output() errors = new EventEmitter();
	@Output() warnings = new EventEmitter();
	@Output() labelLinkClick = new EventEmitter();
	@Output() valueChange = new EventEmitter<FormGroupWithWarning>();
	@Output() formInitialised = new EventEmitter<FormGroupWithWarning>();
	@Output() enterKeyUp = new EventEmitter();
	@Output() externalAction = new EventEmitter<{ questionKey: string }>();
	@Output() formTrackingId = new EventEmitter<string>();

	public formId: string = generateGuid();
	public formName = '';
	public form: FormGroupWithWarning;
	public formConfigurationLoaded: boolean;
	private _formConfiguration: FormConfiguration = new FormConfiguration({ formConfiguration: { questions: [] } });
	private _markAllAsTouched: boolean;

	private readonly destroy$ = new Subject<void>();

	constructor(
		private validatorService: ValidatorService,
		private formWarningService: FormWarningService,
		private unsavedFormDataService: UnsavedDataService
	) {}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.unsavedFormDataService.untrackForm(this.formId);
	}

	getErrors() {
		if (this.form.errors) {
			const formattedErrors = this.validatorService.formatValidationErrors(this.form.errors);
			this.errors.emit(formattedErrors);
		} else {
			this.errors.emit([]);
		}
	}

	getWarnings() {
		if (this.form.warnings) {
			const formattedWarnings = this.formWarningService.formatWarnings(this.form.warnings);
			this.warnings.emit(formattedWarnings);
		} else {
			this.warnings.emit([]);
		}
	}

	initFormGroup(formConfiguration: FormConfiguration): void {
		const group: { [key: string]: FormControl } = {};
		if (formConfiguration?.formControl?.questions) {
			formConfiguration.formControl.questions.forEach((question) => {
				if (!this.isQuestion(question.controlType)) {
					return;
				}
				group[question.key] = new FormControl(
					{
						value: this.getInitValue(question),
						disabled: !!question.disabled,
					},
					{
						validators: question.validators,
						asyncValidators: question.asyncValidator,
						updateOn: question.updateOn,
					}
				);
			});
		}
		const formGroupValidators: Array<ValidatorFn> = formConfiguration?.formGroup?.validators || [];
		const formGroupAsyncValidators: Array<AsyncValidatorFn> = formConfiguration?.formGroup?.asyncValidators || [];
		const formGroup = new FormGroupWithWarning(group, {
			validators: formGroupValidators,
			asyncValidators: formGroupAsyncValidators,
		});

		this.form = formGroup;
		if (formConfiguration?.formControl?.questions) {
			formConfiguration.formControl.questions.forEach((question) => {
				if (question.hidden) {
					const type = question['type'];
					question
						.hidden(formGroup, this.destroy$)
						.pipe(
							takeWhile(() => this.form === formGroup),
							takeUntil(this.destroy$)
						)
						.subscribe((value) => {
							if (value) {
								question['type'] = 'hidden';
								if (group[question.key]) {
									group[question.key].disable();
								}
							} else {
								question['type'] = type;
								if (group[question.key]) {
									group[question.key].enable();
								}
							}
						});
				}

				if (question.transformField) {
					question
						.transformField(this.form, this.formConfiguration, question.key)
						.pipe(
							filter((transformedField) => !!transformedField),
							takeWhile(() => this.form === formGroup),
							takeUntil(this.destroy$)
						)
						.subscribe();
				}
			});
		}

		this.unsavedFormDataService.trackForm(this.formId, this.form);
	}

	getInitValue(question: InputBase<any>) {
		if (!question.value) {
			switch (question.controlType) {
				case 'field':
					if ((question as InputField).type === 'checkbox') {
						return false;
					}
					return question.value;
				case 'percentage':
					return question.value;
				case 'select':
				case 'radio':
					return typeof question.value === 'boolean' ? question.value : null;
				case 'switch':
					if (typeof question.value === 'undefined' || question.value === null) {
						return question['valueA'];
					}
					return question.value;
				case 'dashed':
					return question.value;
				default:
					return undefined;
			}
		}
		return question.value;
	}

	valueChanged() {
		this.valueChange.emit(this.form);
		this.getErrors();
		this.getWarnings();
	}

	isQuestion(controlType: ControlType): boolean {
		return !['space', 'section', 'information-box'].includes(controlType);
	}

	isSection(controlType: ControlType): boolean {
		return 'section' === controlType;
	}

	isInformationBox(controlType: ControlType): boolean {
		return 'information-box' === controlType;
	}
}
