import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { APP_INSIGHTS_CONFIG, ApplicationInsights } from '@oper-client/shared/configuration';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { FormConfiguration } from '../../lib/models/dynamic-form.model';
import { InputBase } from '../models/input-base.model';

import defaultBorrowerModeFormConfig from '../forms/default-borrower-mode';
import defaultClientFormConfig from '../forms/default-client';
import defaultIncomeFormConfig from '../forms/default-income';
import defaultLiabilityFormConfig from '../forms/default-liability';
import defaultFinancialAssetFormConfig from '../forms/default-financial-asset';
import defaultOwnFundsFormConfig from '../forms/default-own-funds';
import defaultRealtyAddressFormConfig from '../forms/default-realty-address';
import defaultRealtyBasicFormConfig from '../forms/default-realty-basic';
import defaultRealtyDetailedFormConfig from '../forms/default-realty-detailed';
import defaultRealtyRealEstateDataOptionalFormConfig from '../forms/default-realty-real-estate-data-optional';
import defaultMortgageSimulator from '../forms/default-mortgage-simulator';
import defaultMortgageSimulatorOwnFunds from '../forms/default-mortgage-simulator-own-funds';
import defaultMortgageSimulatorIncome from '../forms/default-mortgage-simulator-income';
import defaultMortgageSimulatorRealty from '../forms/default-mortgage-simulator-realty';
import defaultMortgageSimulatorProjectDetails from '../forms/default-mortgage-simulator-project-details';
import defaultMortgageSimulatorYourContributions from '../forms/default-mortgage-simulator-your-contributions';
import defaultMortgageSimulatorAdditionalFinance from '../forms/default-mortgage-simulator-additional-finance';
import defaultMortgageSimulatorPersonalDetails from '../forms/default-mortgage-simulator-personal-details';
import defaultContactForm from '../forms/default-contact-form';
import defaultMortgageSimulatorLiability from '../forms/default-mortgage-simulator-liability';
import defaultMortgageSimulatorCoLivingGroup from '../forms/default-mortgage-simulator-co-living-group';
import defaultMortgageSimulatorNewBuild from '../forms/default-mortgage-simulator-new-build';
import defaultMortgageSimulatorRegion from '../forms/default-mortgage-simulator-region';
import defaultMortgageExistingRealtyToRenovate from '../forms/default-mortgage-existing-realty-to-renovate';
import defaultMortgageRenovatedExistingRealty from '../forms/default-mortgage-renovated-existing-realty';
import defaultSignUp from '../forms/default-sign-up';
import defaultSignIn from '../forms/default-sign-in';
import defaultPasswordConfirm from '../forms/default-password-confirm';
import defaultPasswordReset from '../forms/default-password-reset';
import defaultRenovatedRealty from '../forms/default-renovated-realty';
import defaultRealtyToRenovate from '../forms/default-realty-to-renovate';
import defaultCoLivingRenovatedRealty from '../forms/default-co-living-renovated-realty';
import defaultCoLivingRealtyToRenovate from '../forms/default-co-living-realty-to-renovate';
import defaultRealtyBuildProject from '../forms/default-realty-build-project';
import defaultCoLivingRealtyBuildProject from '../forms/default-co-living-realty-build-project';
import defaultClientAddress from '../forms/default-client-address';
import defaultYourMortgage from '../forms/default-your-mortgage';

import hyppoBorrowerModeFormConfig from '../forms/hyppo-borrower-mode';
import hyppoClientFormConfig from '../forms/hyppo-client';
import hyppoIncomeFormConfig from '../forms/hyppo-income';
import hyppoLiabilityFormConfig from '../forms/hyppo-liability';
import hyppoFinancialAssetFormConfig from '../forms/hyppo-financial-asset';
import hyppoRealtyAddressFormConfig from '../forms/hyppo-realty-address';
import hyppoRealtyBasicFormConfig from '../forms/hyppo-realty-basic';
import hyppoRealtyDetailedFormConfig from '../forms/hyppo-realty-detailed';
import hyppoRealtyRealEstateDataOptionalFormConfig from '../forms/hyppo-realty-real-estate-data-optional';

import chBrokerageClientProfileFormConfig from '../forms/brokerage/ch-client-profile';
import chBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/ch-client-profile-detailed';
import chBrokerageFinancialAssetDetailFormConfig from '../forms/brokerage/ch-financial-asset-detail';
import chBrokerageRealtyBasicFormConfig from '../forms/brokerage/ch-realty-basic';
import chBrokerageRealtyDetailedFormConfig from '../forms/brokerage/ch-realty-detailed';
import chBrokerageRealtyPriceFormConfig from '../forms/brokerage/ch-realty-price';

import huBrokerageClientIdentityFormConfig from '../forms/brokerage/hu-client-identity';
import huBrokerageClientPersonalDetailsFormConfig from '../forms/brokerage/hu-client-personal-details';
import huBrokerageClientAdditionalDetailsFormConfig from '../forms/brokerage/hu-client-additional-details';
import huBrokerageClientComplianceFormConfig from '../forms/brokerage/hu-client-compliance';
import huBrokerageClientContactDetailsFormConfig from '../forms/brokerage/hu-client-contact-details';
import huBrokerageClientProfileFormConfig from '../forms/brokerage/hu-client-profile';
import huBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/hu-client-profile-detailed';

import defaultMortgageSimulatorHousingType from '../forms/default-mortgage-simulator-housing-type';
import defaultMortgageSimulatorRealtyBuild from '../forms/default-mortgage-simulator-realty-build';
import defaultBrokerageClientAdditionalDetailsFormConfig from '../forms/brokerage/default-client-additional-details';
import defaultBrokerageClientIdentityFormConfig from '../forms/brokerage/default-client-identity';
import defaultBrokerageClientPersonalDetailsFormConfig from '../forms/brokerage/default-client-personal-details';
import defaultBrokerageClientProfileFormConfig from '../forms/brokerage/default-client-profile';
import defaultBrokerageCreateNewClient from '../forms/brokerage/default-create-new-client';
import defaultBrokerageCreateNewProperty from '../forms/brokerage/default-create-new-property';
import huBrokerageCreateNewProperty from '../forms/brokerage/hu-create-new-property';
import defaultBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/default-client-profile-detailed';
import defaultBrokerageFinancialAssetDetailFormConfig from '../forms/brokerage/default-financial-asset-detail';
import defaultBrokerageAddressDetailsFormConfig from '../forms/brokerage/default-address-detailes';
import defaultBrokerageRealtyBasicFormConfig from '../forms/brokerage/default-realty-basic';
import defaultBrokerageRealtyBasicDetailsFormConfig from '../forms/brokerage/default-realty-basic-detailes';
import defaultBrokerageRealtyCollateralFormConfig from '../forms/brokerage/default-realty-collateral';
import defaultBrokerageRealtyDetailedFormConfig from '../forms/brokerage/default-realty-detailed';
import defaultBrokerageRealtyDetailsFormConfig from '../forms/brokerage/default-realty-details';
import defaultBrokerageRealtyEnergeticDetailsFormConfig from '../forms/brokerage/default-realty-energetic-details';
import defaultBrokerageRealtyOwnershipFormConfig from '../forms/brokerage/default-realty-ownership';
import defaultBrokerageRealtyFireInsuranceFormConfig from '../forms/brokerage/default-realty-fire-insurance';
import defaultBrokerageRealtyPriceFormConfig from '../forms/brokerage/default-realty-price';
import defaultBrokerageRealtyPriceAndValuationFormConfig from '../forms/brokerage/default-realty-price-and-valuation';
import defaultBrokerageRealtyRegistrationFormConfig from '../forms/brokerage/default-realty-registration';
import defaultBrokerageRealtyRenovationsFormConfig from '../forms/brokerage/default-realty-renovations';
import defaultBrokerageLiabilityType from '../forms/brokerage/default-liability-type';
import defaultBrokerageLiabilityTypeRent from '../forms/brokerage/default-liability-type-rent';
import defaultBrokerageLiabilityTypeAlimony from '../forms/brokerage/default-liability-type-alimony';
import defaultBrokerageLiabilityTypeAdditionalLiabilities from '../forms/brokerage/default-liability-type-additional-liabilities';
import defaultBrokerageLiabilityTypeConsumerLoanImmovable from '../forms/brokerage/default-liability-type-consumer-loan-immovable';
import defaultBrokerageLiabilityTypeCreditCard from '../forms/brokerage/default-liability-type-credit-card';
import defaultBrokerageLiabilityTypeCreditExpense from '../forms/brokerage/default-liability-type-credit-expense';
import defaultBrokerageLiabilityTypeCreditLine from '../forms/brokerage/default-liability-type-credit-line';
import defaultBrokerageLiabilityTypeLeasing from '../forms/brokerage/default-liability-type-leasing';
import defaultBrokerageLiabilityTypeMortgageImmovable from '../forms/brokerage/default-liability-type-mortgage-immovable';
import defaultBrokerageLiabilityTypeMortgageMovable from '../forms/brokerage/default-liability-type-mortgage-movable';
import defaultBrokerageLiabilityTypeOther from '../forms/brokerage/default-liability-type-other';
import defaultLoanRequestProofInformative from '../forms/brokerage/default-loan-request-proof-informative';
import defaultBrokerageDataConnectionStep1 from '../forms/brokerage/dataConnection/default-data-connection-step1';
import defaultBrokerageDocumentDataConnectionStep1 from '../forms/brokerage/dataConnection/default-document-data-connection-step1';
import finportaBrokerageDocumentDataConnectionStep1 from '../forms/brokerage/dataConnection/finporta-document-data-connection-step1';
import finportaBrokerageDocumentDataConnectionStep2 from '../forms/brokerage/dataConnection/finporta-document-data-connection-step2';
import defaultBrokerageUploadLibraryDocument from '../forms/brokerage/default-upload-library-document';
import defaultBrokerageUploadLibraryDocumentLanguagePredefined from '../forms/brokerage/default-upload-library-document-language-predefined';

import atBrokerageAddressDetailsFormConfig from '../forms/brokerage/at-address-detailes';
import atBrokerageRealtyBasicFormConfig from '../forms/brokerage/at-realty-basic';
import atBrokerageRealtyBasicDetailsFormConfig from '../forms/brokerage/at-realty-basic-detailes';
import atBrokerageRealtyDetailedFormConfig from '../forms/brokerage/at-realty-detailed';
import atBrokerageRealtyPriceFormConfig from '../forms/brokerage/at-realty-price';

import atIncomeFormConfig from '../forms/at-income';
import atLiabilityFormConfig from '../forms/at-liability';
import atRealtyAddressFormConfig from '../forms/at-realty-address';
import atRealtyBasicFormConfig from '../forms/at-realty-basic';
import atFinancialAssetFormConfig from '../forms/at-financial-asset';

import clerFinancialAssetFormConfig from '../forms/cler-financial-asset';
import clerRealtyBasicFormConfig from '../forms/cler-realty-basic';
import clerLiabilityConfig from '../forms/cler-liability';
import clerBrokerageRealtyPriceFormConfig from '../forms/brokerage/cler-realty-price';

import clerSignUp from '../forms/cler-sign-up';
import clerBrokerageRealtyBasicDetailsFormConfig from '../forms/brokerage/cler-realty-basic-detailes';
import clerBrokerageRealtyDetailed from '../forms/brokerage/cler-realty-detailed';
import clerBrokerageRealtyDetailsFormConfig from '../forms/brokerage/cler-realty-details';
import clerBrokerageRealtyPriceAndValuationFormConfig from '../forms/brokerage/cler-realty-price-and-valuation';
import clerBrokerageRealtyRegistrationFormConfig from '../forms/brokerage/cler-realty-registration';
import clerBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/cler-client-profile-detailed';
import clerBrokerageClientAdditionalDetailsFormConfig from '../forms/brokerage/cler-client-additional-details';
import clertBrokerageClientPersonalDetailsFormConfig from '../forms/brokerage/cler-client-personal-details';
import clerBrokerageClientContactDetailsFormConfig from '../forms/brokerage/cler-client-contact-details';
import clerBrokerageClientIdentityFormConfig from '../forms/brokerage/cler-client-identity';
import clerBrokerageClientProfile from '../forms/brokerage/cler-client-profile';
import clerBrokerageRealtyBasic from '../forms/brokerage/cler-realty-basic';
import clerBrokerageLiabilityTypeMortgageImmovable from '../forms/brokerage/cler-liability-type-mortgage-immovable';

import triodosClientFormConfig from '../forms/triodos-client';
import triodosRealtyBasicConfig from '../forms/triodos-realty-basic';
import triodosCoLivingRenovatedRealty from '../forms/triodos-co-living-renovated-realty';
import triodosBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/triodos-client-profile-detailed';
import triodosRealtyBuildProject from '../forms/triodos-realty-build-project';
import triodosMortgageSimulatorYourProject from '../forms/triodos-mortgage-simulator-your-project';

import credishopBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/credishop-client-profile-detailed';

import wirRealtyBasic from '../forms/wir-realty-basic';
import wirIncome from '../forms/wir-income';
import wirClientFormConfig from '../forms/wir-client';
import wirBrokerageClientProfile from '../forms/brokerage/wir-client-profile';
import wirBrokerageRealtyDetailed from '../forms/brokerage/wir-realty-detailed';
import wirBrokerageRealtyBasic from '../forms/brokerage/wir-realty-basic';
import wirYourMortgage from '../forms/wir-your-mortgage';
import wirBrokerageLiabilityTypeMortgageImmovable from '../forms/brokerage/wir-liability-type-mortgage-immovable';
import wirFinancialAssetFormConfig from '../forms/wir-financial-asset';
import wirLiability from '../forms/wir-liability';
import wirBrokerageRealtyPriceFormConfig from '../forms/brokerage/wir-realty-price';
import wirBrokerageClientProfileDetailedFormConfig from '../forms/brokerage/wir-client-profile-detailed';

import huBrokerageAddressDetailsFormConfig from '../forms/brokerage/hu-address-detailes';
import huBrokerageRealtyBasicDetailsFormConfig from '../forms/brokerage/hu-realty-basic-detailes';
import huBrokerageRealtyDetailsFormConfig from '../forms/brokerage/hu-realty-details';
import huBrokerageRealtyDetailed from '../forms/brokerage/hu-realty-detailed';
import huBrokerageRealtyPriceFormConfig from '../forms/brokerage/hu-realty-price';
import huBrokerageRealtyPriceAndValuationFormConfig from '../forms/brokerage/hu-realty-price-and-valuation';
import huBrokerageRealtyRegistrationFormConfig from '../forms/brokerage/hu-realty-registration';

import defaultProductSearchFilters from '../forms/brokerage/default-product-search-filters';
import defaultBrokerageIncomeSalaryEmployed from '../forms/brokerage/default-income-salary-employed';
import defaultBrokerageIncomeSelfEmployed from '../forms/brokerage/default-income-self-employed';
import defaultBrokerageIncomeHousehold from '../forms/brokerage/default-income-household';
import defaultBrokerageIncomeRental from '../forms/brokerage/default-income-rental';
import defaultBrokerageIncomeAlimony from '../forms/brokerage/default-income-alimony';
import defaultBrokerageIncomeChildSupport from '../forms/brokerage/default-income-child-support';
import defaultBrokerageIncomeRetirement from '../forms/brokerage/default-income-retirement';
import defaultBrokerageIncomeUnemployment from '../forms/brokerage/default-income-unemployment';
import defaultBrokerageIncomeMemberSalary from '../forms/brokerage/default-income-unemployment';
import defaultBrokerageIncomeOther from '../forms/brokerage/default-income-other';
import huBrokerageIncomeSalaryEmployed from '../forms/brokerage/hu-income-salary-employed';
import huBrokerageIncomeSelfEmployed from '../forms/brokerage/hu-income-self-employed';
import huBrokerageIncomeMemberSalary from '../forms/brokerage/hu-income-member-salary';
import huBrokerageIncomeRetirement from '../forms/brokerage/hu-income-retirement';
import chBrokerageIncomeSalaryEmployed from '../forms/brokerage/ch-income-salary-employed';
import chBrokerageLiabilityTypeAlimony from '../forms/brokerage/ch-liability-type-alimony';
import chBrokerageCreateNewProperty from '../forms/brokerage/ch-create-new-property';
import wirBrokerageCreateNewProperty from '../forms/brokerage/wir-create-new-property';
import defaultBrokerageClientRelationshipFormConfig from '../forms/brokerage/default-client-relationship';
import defaultBrokerageClientContactFormConfig from '../forms/brokerage/default-client-contact';
import defaultBrokerageClientContactDetailsFormConfig from '../forms/brokerage/default-client-contact-details';
import defaultBrokerageClientComplianceFormConfig from '../forms/brokerage/default-client-compliance';
import clerBrokerageClientComplianceFormConfig from '../forms/brokerage/cler-client-compliance';
import clerBrokerageClientContactFormConfig from '../forms/brokerage/cler-realty-address-detailes';
import chBrokerageClientContactFormConfig from '../forms/brokerage/ch-client-contact';
import huBrokerageCreateNewClient from '../forms/brokerage/hu-create-new-client';
import huBrokerageRealtyBasic from '../forms/brokerage/hu-realty-basic';
import clerClient from '../forms/cler-client';
import defaultBrokerageClientAddress from '../forms/brokerage/default-client-address';

import huBrokerageClientAddress from '../forms/brokerage/hu-client-address';
import csokCashCalculatorInput from '../forms/brokerage/calculator/csok-cash-calculator-input';
import csokCashCalculatorOutput from '../forms/brokerage/calculator/csok-cash-calculator-output';
import csokMortgageCalculatorInput from '../forms/brokerage/calculator/csok-mortgage-calculator-input';
import csokMortgageCalculatorOutput from '../forms/brokerage/calculator/csok-mortgage-calculator-output';
import villagecsokCashCalculatorInput from '../forms/brokerage/calculator/village-csok-cash-calculator-input';
import villagecsokCashCalculatorOutput from '../forms/brokerage/calculator/village-csok-cash-calculator-output';
import villagecsokMortgageCalculatorInput from '../forms/brokerage/calculator/village-csok-mortgage-calculator-input';
import villagecsokMortgageCalculatorOutput from '../forms/brokerage/calculator/village-csok-mortgage-calculator-output';
import babyLoanCalculatorInput from '../forms/brokerage/calculator/baby-loan-calculator-input';
import babyLoanCalculatorOutput from '../forms/brokerage/calculator/baby-loan-calculator-output';
import csokCashCalculatorValidator from '../validators/csok-cash.validator';
import csokMortgageCalculatorValidator from '../validators/csok-mortgage.validator';
import villageCsokCashCalculatorValidator from '../validators/village-csok-cash-validator';
import villageCsokMortgageCalculatorValidator from '../validators/village-csok-mortgage-validator';

import defaultPropertyRenovationType from '../forms/brokerage/default-property-renovation-type';
import defaultBrokerageCreateNewPropertyCollateral from '../forms/brokerage/default-create-new-property-collateral';

import finportaSignIn from '../forms/finporta-sign-in';
import finportaPasswordConfirm from '../forms/finporta-password-confirm';
import finportaPasswordReset from '../forms/finporta-password-reset';

import defaultJoinCredentials from '../forms/default-join-credentials';
import defaultJoinDetails from '../forms/default-join-details';
import chJoinCredentials from '../forms/ch-join-credentials';

import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { BrokerageFormEnum } from '../constants/brokerage-form.enum';
import defaultSimulatorAddProjectCosts from '../forms/brokerage/default-simulator-add-project-costs';
import defaultSimulatorAddProjectFinancing from '../forms/brokerage/default-simulator-add-project-financing';
import defaultSimulatorAssesFinancesBorrower from '../forms/brokerage/default-simulator-asses-finances-borrower';
import defaultSimulatorCreateRequestAssign from '../forms/brokerage/default-simulator-create-request-assign';
import defaultSimulatorCreateRequestBorrowerDetails from '../forms/brokerage/default-simulator-create-request-borrower-details';
import defaultSimulatorCreateRequestExtraCollateral from '../forms/brokerage/default-simulator-create-request-extra-collateral';
import defaultSimulatorCreateRequestPropertyDetails from '../forms/brokerage/default-simulator-create-request-property-details';
import defaultSimulatorAddProjectRealty from '../forms/brokerage/default-simulator-add-project-realty';
import defaultSearchSimulationFilters from '../forms/brokerage/default-search-simulation-filters';
import huSimulatorAddProjectRealty from '../forms/brokerage/hu-simulator-add-project-realty';
import huSimulatorAddProjectCosts from '../forms/brokerage/hu-simulator-add-project-costs';
import proceedsFromPropertySaleCalculatorInput from '../forms/brokerage/calculator/proceeds-from-property-sale-calculator-input';
import proceedsFromPropertySaleCalculatorOutput from '../forms/brokerage/calculator/proceeds-from-property-sale-calculator-output';
import huSimulatorCreateRequestBorrowerDetails from '../forms/brokerage/hu-simulator-create-request-borrower-details';
import huBrokerageClientContact from '../forms/brokerage/hu-client-contact';
import defaultPricingSheetForm from '../forms/configuration-portal/default-pricing-sheet-form';
import defaultProductForm from '../forms/configuration-portal/default-base-product-form';
import defaultProductAmortizationForm from '../forms/configuration-portal/default-base-product-amortization-form';
import defaultProductVersionForm from '../forms/configuration-portal/default-product-version-form';
import defaultProductAcceptanceRuleForm from '../forms/configuration-portal/default-product-acceptance-rule-form';
import defaultLibraryDocumentForm from '../forms/configuration-portal/default-document-library-form';
import defaultGenericAcceptanceRuleForm from '../forms/configuration-portal/default-generic-acceptance-rule-form';
import huSimulatorCreateRequestExtraCollateral from '../forms/brokerage/hu-simulator-create-request-extra-collateral';
import huJoinDetails from '../forms/hu-join-details';
import { nationalNumberFormValidators } from '../validators/national-number-validator';
import huJoinCredentials from '../forms/hu-join-credentials';
import atSimulatorAddProjectCosts from '../forms/brokerage/at-simulator-add-project-costs';

import huBrokerageLiabilityTypeConsumerLoanImmovable from '../forms/brokerage/hu-liability-type-consumer-loan-immovable';
import huBrokerageLiabilityTypeOther from '../forms/brokerage/hu-liability-type-other';
import huBrokerageLiabilityTypeMortgageMovable from '../forms/brokerage/hu-liability-type-mortgage-movable';
import huBrokerageLiabilityTypeMortgageImmovable from '../forms/brokerage/hu-liability-type-mortgage-immovable';
import huBrokerageLiabilityTypeCreditCard from '../forms/brokerage/hu-liability-type-credit-card';
import huBrokerageLiabilityTypeCreditLine from '../forms/brokerage/hu-liability-type-credit-line';
import huBrokerageLiabilityTypeLeasing from '../forms/brokerage/hu-liability-type-leasing';
import huBrokerageLiabilityTypeCreditExpense from '../forms/brokerage/hu-liability-type-credit-expense';
import huSimulatorAddProjectFinancing from '../forms/brokerage/hu-simulator-add-project-financing';
import huLoanRequestProofInformative from '../forms/brokerage/hu-loan-request-proof-informative';

import medirectMortgageSimulatorIncome from '../forms/medirect-mortgage-simulator-income';
import medirectMortgageSimulatorLiability from '../forms/medirect-mortgage-simulator-liability';
import defaultSimulatorAddProjectPurpose from '../forms/brokerage/default-simulator-add-project-purpose';

import defaultLoanApplicationProjectDetails from '../forms/default-loan-application-project-details';
import defaultLoanApplicationYourContribution from '../forms/default-loan-application-your-contribution';
import defaultLoanApplicationIncome from '../forms/default-loan-application-income';
import defaultLoanApplicationLiability from '../forms/default-loan-application-liability';
import defaultLoanApplicationPersonalDetails from '../forms/default-loan-application-personal-details';
import defaultMortgageSimulatorReportFinancialPlanOverview from '../forms/default-mortgage-simulator-report-financial-plan-overview';
import defaultLoanApplicationAdditionalRefinance from '../forms/default-loan-application-additional-refinance';
import defaultLoanApplicationSubmitApplication from '../forms/default-loan-application-submit-application';
import huSimulatorAssesFinancesBorrower from '../forms/brokerage/hu-simulator-asses-finances-borrower';
import wirSimulatorAssesFinancesBorrower from '../forms/brokerage/wir-simulator-asses-finances-borrower';
import chSimulatorAssesFinancesBorrower from '../forms/brokerage/ch-simulator-asses-finances-borrower';
import clerSimulatorAssesFinancesBorrower from '../forms/brokerage/cler-simulator-asses-finances-borrower';
import hyppoSimulatorAssesFinancesBorrower from '../forms/brokerage/hyppo-simulator-asses-finances-borrower';
import atSimulatorAssesFinancesBorrower from '../forms/brokerage/at-simulator-asses-finances-borrower';

interface FormQuestionMap {
	[formName: string]: (formData: any, resources: any, ...args) => InputBase<any>[];
}

interface FormValidatorMap {
	[formName: string]: (
		resources: any,
		...args
	) => {
		validators: ValidatorFn[];
		asyncValidators?: AsyncValidatorFn[];
	};
}

interface CustomerFormQuestionMap {
	[client: string]: FormQuestionMap;
}

@Injectable({
	providedIn: 'root',
})
export class DynamicFormConfigurationService {
	private readonly customer: string;
	private readonly formQuestionMap: FormQuestionMap;
	private readonly formValidatorMap: FormValidatorMap;
	private readonly customerSeparator: string = '-';
	private readonly customerFormQuestionMap: CustomerFormQuestionMap = {
		default: {
			borrowerMode: defaultBorrowerModeFormConfig,
			client: defaultClientFormConfig,
			income: defaultIncomeFormConfig,
			liability: defaultLiabilityFormConfig,
			financialAsset: defaultFinancialAssetFormConfig,
			ownFunds: defaultOwnFundsFormConfig,
			realtyAddress: defaultRealtyAddressFormConfig,
			realtyBasic: defaultRealtyBasicFormConfig,
			realtyDetailed: defaultRealtyDetailedFormConfig,
			realtyRealEstateDataOptional: defaultRealtyRealEstateDataOptionalFormConfig,
			brokerageProductSearchFilters: defaultProductSearchFilters,
			brokerageSearchSimulationFilters: defaultSearchSimulationFilters,
			brokerageCreateNewClient: defaultBrokerageCreateNewClient,
			brokerageCreateNewProperty: defaultBrokerageCreateNewProperty,
			brokerageCreateNewClientRenovationType: defaultPropertyRenovationType,
			brokerageCreateNewPropertyCollateral: defaultBrokerageCreateNewPropertyCollateral,
			brokerageClientAdditionalDetails: defaultBrokerageClientAdditionalDetailsFormConfig,
			brokerageClientIdentity: defaultBrokerageClientIdentityFormConfig,
			brokerageClientPersonalDetails: defaultBrokerageClientPersonalDetailsFormConfig,
			brokerageClientProfile: defaultBrokerageClientProfileFormConfig,
			brokerageClientProfileDetailed: defaultBrokerageClientProfileDetailedFormConfig,
			brokerageClientRelationship: defaultBrokerageClientRelationshipFormConfig,
			brokerageClientContact: defaultBrokerageClientContactFormConfig,
			brokerageClientContactDetails: defaultBrokerageClientContactDetailsFormConfig,
			brokerageClientCompliance: defaultBrokerageClientComplianceFormConfig,
			brokerageFinancialAssetDetail: defaultBrokerageFinancialAssetDetailFormConfig,
			brokerageAddressDetails: defaultBrokerageAddressDetailsFormConfig,
			brokerageRealtyBasic: defaultBrokerageRealtyBasicFormConfig,
			brokerageRealtyBasicDetails: defaultBrokerageRealtyBasicDetailsFormConfig,
			brokerageRealtyCollateral: defaultBrokerageRealtyCollateralFormConfig,
			brokerageRealtyDetailed: defaultBrokerageRealtyDetailedFormConfig,
			brokerageRealtyDetails: defaultBrokerageRealtyDetailsFormConfig,
			brokerageRealtyEnergeticDetails: defaultBrokerageRealtyEnergeticDetailsFormConfig,
			brokerageRealtyFireInsurance: defaultBrokerageRealtyFireInsuranceFormConfig,
			brokerageRealtyOwnership: defaultBrokerageRealtyOwnershipFormConfig,
			brokerageRealtyPrice: defaultBrokerageRealtyPriceFormConfig,
			brokerageRealtyPriceAndValuation: defaultBrokerageRealtyPriceAndValuationFormConfig,
			brokerageRealtyRegistration: defaultBrokerageRealtyRegistrationFormConfig,
			brokerageRealtyRenovations: defaultBrokerageRealtyRenovationsFormConfig,
			brokerageLiabilityType: defaultBrokerageLiabilityType,
			brokerageLiabilityTypeRent: defaultBrokerageLiabilityTypeRent,
			brokerageLiabilityTypeAlimony: defaultBrokerageLiabilityTypeAlimony,
			brokerageLiabilityTypeAdditionalLiabilities: defaultBrokerageLiabilityTypeAdditionalLiabilities,
			brokerageLiabilityTypeConsumerLoanImmovable: defaultBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeCreditCard: defaultBrokerageLiabilityTypeCreditCard,
			brokerageLiabilityTypeCreditExpense: defaultBrokerageLiabilityTypeCreditExpense,
			brokerageLiabilityTypeGeneralCreditExpense: defaultBrokerageLiabilityTypeCreditExpense,
			brokerageLiabilityTypeLeasing: defaultBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeMortgageImmovable: defaultBrokerageLiabilityTypeMortgageImmovable,
			brokerageLiabilityTypeMortgageMovable: defaultBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeCreditLine: defaultBrokerageLiabilityTypeCreditLine,
			brokerageLiabilityTypeConsumerLoanMovable: defaultBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeInvestmentCredit: defaultBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypePurchaseCredit: defaultBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeOtherCredit: defaultBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeOther: defaultBrokerageLiabilityTypeOther,
			brokerageLoanRequestProof: defaultLoanRequestProofInformative,
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_PURPOSE]: defaultSimulatorAddProjectPurpose,
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_COSTS]: defaultSimulatorAddProjectCosts,
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_FINANCING]: defaultSimulatorAddProjectFinancing,
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_REALTY]: defaultSimulatorAddProjectRealty,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: defaultSimulatorAssesFinancesBorrower,
			[BrokerageFormEnum.SIMULATOR_CREATE_REQUEST_ASSIGN]: defaultSimulatorCreateRequestAssign,
			[BrokerageFormEnum.SIMULATOR_CREATE_BORROWER_DETAILS]: defaultSimulatorCreateRequestBorrowerDetails,
			[BrokerageFormEnum.SIMULATOR_CREATE_REQUEST_EXTRA_COLLATERAL]: defaultSimulatorCreateRequestExtraCollateral,
			[BrokerageFormEnum.SIMULATOR_CREATE_REQUEST_PROPERTY_DETAILS]: defaultSimulatorCreateRequestPropertyDetails,
			brokerageUploadLibraryDocument: defaultBrokerageUploadLibraryDocument,
			configurationPortalPricingSheetForm: defaultPricingSheetForm,
			configurationPortalProductForm: defaultProductForm,
			configurationPortalProductAmortizationForm: defaultProductAmortizationForm,
			configurationPortalProductVersionForm: defaultProductVersionForm,
			configurationPortalGenericAcceptanceRuleForm: defaultGenericAcceptanceRuleForm,
			configurationPortalProductAcceptanceRuleForm: defaultProductAcceptanceRuleForm,
			configurationPortaldefaultLibraryDocumentFormRuleForm: defaultLibraryDocumentForm,
			mortgageSimulator: defaultMortgageSimulator,
			mortgageSimulatorHousingType: defaultMortgageSimulatorHousingType,
			mortgageSimulatorRealtyBuild: defaultMortgageSimulatorRealtyBuild,
			mortgageSimulatorProjectDetails: defaultMortgageSimulatorProjectDetails,
			mortgageSimulatorYourContribution: defaultMortgageSimulatorYourContributions,
			mortgageSimulatorAdditionalFinance: defaultMortgageSimulatorAdditionalFinance,
			mortgageSimulatorPersonalDetails: defaultMortgageSimulatorPersonalDetails,
			mortgageSimulatorOwnFunds: defaultMortgageSimulatorOwnFunds,
			mortgageSimulatorIncome: defaultMortgageSimulatorIncome,
			mortgageSimulatorLiability: defaultMortgageSimulatorLiability,
			borrowerSimulatorIncome: medirectMortgageSimulatorIncome,
			borrowerSimulatorLiability: medirectMortgageSimulatorLiability,
			mortgageSimulatorRealty: defaultMortgageSimulatorRealty,
			mortgageSimulatorCoLivingGroup: defaultMortgageSimulatorCoLivingGroup,
			mortgageSimulatorNewBuild: defaultMortgageSimulatorNewBuild,
			mortgageSimulatorRegion: defaultMortgageSimulatorRegion,
			mortgageSimulatorExistingRealtyToRenovate: defaultMortgageExistingRealtyToRenovate,
			mortgageSimulatorRenovation: defaultMortgageExistingRealtyToRenovate,
			mortgageSimulatorRenovatedExistingRealty: defaultMortgageRenovatedExistingRealty,
			mortgageSimulatorPurchase: defaultMortgageRenovatedExistingRealty,
			mortgageSimulatorReportFinancialPlanOverview: defaultMortgageSimulatorReportFinancialPlanOverview,
			loanApplicationProjectDetails: defaultLoanApplicationProjectDetails,
			loanApplicationYourContribution: defaultLoanApplicationYourContribution,
			loanApplicationIncome: defaultLoanApplicationIncome,
			loanApplicationLiability: defaultLoanApplicationLiability,
			loanApplicationPersonalDetails: defaultLoanApplicationPersonalDetails,
			loanApplicationAdditionalRefinance: defaultLoanApplicationAdditionalRefinance,
			loanApplicationSubmitApplication: defaultLoanApplicationSubmitApplication,
			contactForm: defaultContactForm,
			signUp: defaultSignUp,
			signIn: defaultSignIn,
			passwordConfirm: defaultPasswordConfirm,
			passwordReset: defaultPasswordReset,
			renovatedRealty: defaultRenovatedRealty,
			realtyToRenovate: defaultRealtyToRenovate,
			realtyBuildProject: defaultRealtyBuildProject,
			coLivingRenovatedRealty: defaultCoLivingRenovatedRealty,
			coLivingRealtyToRenovate: defaultCoLivingRealtyToRenovate,
			coLivingRealtyBuildProject: defaultCoLivingRealtyBuildProject,
			yourMortgage: defaultYourMortgage,
			clientAddress: defaultClientAddress,
			brokerageIncomeSalaryEmployed: defaultBrokerageIncomeSalaryEmployed,
			brokerageIncomeSelfEmployed: defaultBrokerageIncomeSelfEmployed,
			brokerageIncomeHousehold: defaultBrokerageIncomeHousehold,
			brokerageIncomeRental: defaultBrokerageIncomeRental,
			brokerageIncomeAlimony: defaultBrokerageIncomeAlimony,
			brokerageIncomeChildSupport: defaultBrokerageIncomeChildSupport,
			brokerageIncomeRetirement: defaultBrokerageIncomeRetirement,
			brokerageIncomeMemberSalary: defaultBrokerageIncomeMemberSalary,
			brokerageIncomeUnemployment: defaultBrokerageIncomeUnemployment,
			brokerageIncomeOther: defaultBrokerageIncomeOther,
			brokerageClientAddress: defaultBrokerageClientAddress,
			brokerageCsokCashCalculatorInput: csokCashCalculatorInput,
			brokerageCsokCashCalculatorOutput: csokCashCalculatorOutput,
			brokerageCsokMortgageCalculatorInput: csokMortgageCalculatorInput,
			brokerageCsokMortgageCalculatorOutput: csokMortgageCalculatorOutput,
			brokerageVillageCsokCashCalculatorInput: villagecsokCashCalculatorInput,
			brokerageVillageCsokCashCalculatorOutput: villagecsokCashCalculatorOutput,
			brokerageVillageCsokMortgageCalculatorInput: villagecsokMortgageCalculatorInput,
			brokerageVillageCsokMortgageCalculatorOutput: villagecsokMortgageCalculatorOutput,
			brokerageProceedsFromPropertySaleCalculatorInput: proceedsFromPropertySaleCalculatorInput,
			brokerageProceedsFromPropertySaleCalculatorOutput: proceedsFromPropertySaleCalculatorOutput,
			brokerageBabyLoanCalculatorInput: babyLoanCalculatorInput,
			brokerageBabyLoanCalculatorOutput: babyLoanCalculatorOutput,
			brokerageDataConnectionStep1: defaultBrokerageDataConnectionStep1,
			brokerageDocumentDataConnectionStep1: defaultBrokerageDocumentDataConnectionStep1,
			joinCredentials: defaultJoinCredentials,
			joinDetails: defaultJoinDetails,
		},
		austria: {
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_COSTS]: atSimulatorAddProjectCosts,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: atSimulatorAssesFinancesBorrower,
			borrowerMode: defaultBorrowerModeFormConfig,
			client: defaultClientFormConfig,
			income: defaultIncomeFormConfig,
			liability: defaultLiabilityFormConfig,
			financialAsset: defaultFinancialAssetFormConfig,
			ownFunds: defaultOwnFundsFormConfig,
			realtyAddress: defaultRealtyAddressFormConfig,
			realtyBasic: defaultRealtyBasicFormConfig,
			realtyDetailed: defaultRealtyDetailedFormConfig,
			realtyRealEstateDataOptional: defaultRealtyRealEstateDataOptionalFormConfig,
			brokerageClientProfile: defaultBrokerageClientProfileFormConfig,
			brokerageClientProfileDetailed: defaultBrokerageClientProfileDetailedFormConfig,
			brokerageFinancialAssetDetail: defaultBrokerageFinancialAssetDetailFormConfig,
			brokerageRealtyBasic: atBrokerageRealtyBasicFormConfig,
			brokerageRealtyDetailed: atBrokerageRealtyDetailedFormConfig,
			brokerageRealtyPrice: atBrokerageRealtyPriceFormConfig,
		},
		german: {
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_COSTS]: atSimulatorAddProjectCosts,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: atSimulatorAssesFinancesBorrower,
			borrowerMode: defaultBorrowerModeFormConfig,
			client: defaultClientFormConfig,
			income: defaultIncomeFormConfig,
			liability: defaultLiabilityFormConfig,
			financialAsset: defaultFinancialAssetFormConfig,
			ownFunds: defaultOwnFundsFormConfig,
			realtyAddress: defaultRealtyAddressFormConfig,
			realtyBasic: defaultRealtyBasicFormConfig,
			realtyDetailed: defaultRealtyDetailedFormConfig,
			realtyRealEstateDataOptional: defaultRealtyRealEstateDataOptionalFormConfig,
			brokerageClientProfile: defaultBrokerageClientProfileFormConfig,
			brokerageClientProfileDetailed: defaultBrokerageClientProfileDetailedFormConfig,
			brokerageFinancialAssetDetail: defaultBrokerageFinancialAssetDetailFormConfig,
			brokerageRealtyBasic: atBrokerageRealtyBasicFormConfig,
			brokerageRealtyBasicDetails: atBrokerageRealtyBasicDetailsFormConfig,
			brokerageRealtyDetailed: atBrokerageRealtyDetailedFormConfig,
			brokerageRealtyPrice: atBrokerageRealtyPriceFormConfig,
		},
		hypoooe: {
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_COSTS]: atSimulatorAddProjectCosts,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: hyppoSimulatorAssesFinancesBorrower,
			income: atIncomeFormConfig,
			liability: atLiabilityFormConfig,
			realtyAddress: atRealtyAddressFormConfig,
			realtyBasic: atRealtyBasicFormConfig,
			financialAsset: atFinancialAssetFormConfig,
			brokerageAddressDetails: atBrokerageAddressDetailsFormConfig,
			brokerageRealtyBasic: atBrokerageRealtyBasicFormConfig,
			brokerageRealtyBasicDetails: atBrokerageRealtyBasicDetailsFormConfig,
			brokerageUploadLibraryDocument: defaultBrokerageUploadLibraryDocumentLanguagePredefined,
		},
		hyppo: {
			borrowerMode: hyppoBorrowerModeFormConfig,
			client: hyppoClientFormConfig,
			income: hyppoIncomeFormConfig,
			liability: hyppoLiabilityFormConfig,
			financialAsset: hyppoFinancialAssetFormConfig,
			realtyAddress: hyppoRealtyAddressFormConfig,
			realtyBasic: hyppoRealtyBasicFormConfig,
			realtyDetailed: hyppoRealtyDetailedFormConfig,
			realtyRealEstateDataOptional: hyppoRealtyRealEstateDataOptionalFormConfig,
			brokerageClientProfile: chBrokerageClientProfileFormConfig,
			brokerageClientProfileDetailed: chBrokerageClientProfileDetailedFormConfig,
			brokerageFinancialAssetDetail: chBrokerageFinancialAssetDetailFormConfig,
			brokerageRealtyBasic: chBrokerageRealtyBasicFormConfig,
			brokerageRealtyDetailed: chBrokerageRealtyDetailedFormConfig,
			brokerageRealtyPrice: chBrokerageRealtyPriceFormConfig,
		},
		preapproval: {
			borrowerMode: hyppoBorrowerModeFormConfig,
			client: hyppoClientFormConfig,
			income: hyppoIncomeFormConfig,
			liability: hyppoLiabilityFormConfig,
			financialAsset: hyppoFinancialAssetFormConfig,
			realtyAddress: hyppoRealtyAddressFormConfig,
			realtyBasic: hyppoRealtyBasicFormConfig,
			realtyDetailed: hyppoRealtyDetailedFormConfig,
			realtyRealEstateDataOptional: hyppoRealtyRealEstateDataOptionalFormConfig,
			brokerageRealtyPrice: chBrokerageRealtyPriceFormConfig,
		},
		credishop: {
			brokerageClientProfileDetailed: credishopBrokerageClientProfileDetailedFormConfig,
		},
		cler: {
			financialAsset: clerFinancialAssetFormConfig,
			realtyBasic: clerRealtyBasicFormConfig,
			liability: clerLiabilityConfig,
			brokerageRealtyPrice: clerBrokerageRealtyPriceFormConfig,
			signUp: clerSignUp,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: clerSimulatorAssesFinancesBorrower,
			brokerageClientAdditionalDetails: clerBrokerageClientAdditionalDetailsFormConfig,
			brokerageClientPersonalDetails: clertBrokerageClientPersonalDetailsFormConfig,
			brokerageClientProfile: clerBrokerageClientProfile,
			brokerageClientProfileDetailed: clerBrokerageClientProfileDetailedFormConfig,
			brokerageClientCompliance: clerBrokerageClientComplianceFormConfig,
			brokerageClientContact: chBrokerageClientContactFormConfig,
			brokerageClientContactDetails: clerBrokerageClientContactDetailsFormConfig,
			brokerageClientIdentity: clerBrokerageClientIdentityFormConfig,
			brokerageRealtyAddressDetails: clerBrokerageClientContactFormConfig,
			brokerageRealtyBasic: clerBrokerageRealtyBasic,
			brokerageRealtyBasicDetails: clerBrokerageRealtyBasicDetailsFormConfig,
			brokerageRealtyDetailed: clerBrokerageRealtyDetailed,
			brokerageRealtyDetails: clerBrokerageRealtyDetailsFormConfig,
			brokerageRealtyEnergeticDetails: () => [],
			brokerageRealtyPriceAndValuation: clerBrokerageRealtyPriceAndValuationFormConfig,
			brokerageRealtyRegistration: clerBrokerageRealtyRegistrationFormConfig,
			brokerageLiabilityTypeMortgageImmovable: clerBrokerageLiabilityTypeMortgageImmovable,
			brokerageIncomeSalaryEmployed: chBrokerageIncomeSalaryEmployed,
			client: clerClient,
			brokerageLiabilityTypeAlimony: chBrokerageLiabilityTypeAlimony,
			brokerageCreateNewProperty: chBrokerageCreateNewProperty,
			joinCredentials: chJoinCredentials,
		},
		triodos: {
			client: triodosClientFormConfig,
			realtyBasic: triodosRealtyBasicConfig,
			brokerageClientProfileDetailed: triodosBrokerageClientProfileDetailedFormConfig,
			coLivingRenovatedRealty: triodosCoLivingRenovatedRealty,
			realtyBuildProject: triodosRealtyBuildProject,
			mortgageSimulatorYourProject: triodosMortgageSimulatorYourProject,
		},
		swiss: {
			financialAsset: clerFinancialAssetFormConfig,
			realtyBasic: clerRealtyBasicFormConfig,
			liability: clerLiabilityConfig,
			brokerageRealtyPrice: clerBrokerageRealtyPriceFormConfig,
			signUp: clerSignUp,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: chSimulatorAssesFinancesBorrower,
			brokerageRealtyDetailed: clerBrokerageRealtyDetailed,
			brokerageClientProfileDetailed: clerBrokerageClientProfileDetailedFormConfig,
			brokerageCreateNewProperty: chBrokerageCreateNewProperty,
			joinCredentials: chJoinCredentials,
		},
		wir: {
			realtyBasic: wirRealtyBasic,
			income: wirIncome,
			client: wirClientFormConfig,
			yourMortgage: wirYourMortgage,
			signUp: clerSignUp,
			financialAsset: wirFinancialAssetFormConfig,
			liability: wirLiability,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: wirSimulatorAssesFinancesBorrower,
			brokerageRealtyPrice: wirBrokerageRealtyPriceFormConfig,
			brokerageClientProfile: wirBrokerageClientProfile,
			brokerageClientProfileDetailed: wirBrokerageClientProfileDetailedFormConfig,
			brokerageClientContact: chBrokerageClientContactFormConfig,
			brokerageRealtyDetailed: wirBrokerageRealtyDetailed,
			brokerageRealtyBasic: wirBrokerageRealtyBasic,
			brokerageLiabilityTypeMortgageImmovable: wirBrokerageLiabilityTypeMortgageImmovable,
			brokerageIncomeSalaryEmployed: chBrokerageIncomeSalaryEmployed,
			brokerageLiabilityTypeAlimony: chBrokerageLiabilityTypeAlimony,
			brokerageCreateNewProperty: wirBrokerageCreateNewProperty,
			joinCredentials: chJoinCredentials,
		},
		finporta: {
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_COSTS]: huSimulatorAddProjectCosts,
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_FINANCING]: huSimulatorAddProjectFinancing,
			[BrokerageFormEnum.SIMULATOR_ASSES_FINANCES_BORROWER]: huSimulatorAssesFinancesBorrower,
			[BrokerageFormEnum.SIMULATOR_ADD_PROJECT_REALTY]: huSimulatorAddProjectRealty,
			[BrokerageFormEnum.SIMULATOR_CREATE_BORROWER_DETAILS]: huSimulatorCreateRequestBorrowerDetails,
			[BrokerageFormEnum.SIMULATOR_CREATE_REQUEST_EXTRA_COLLATERAL]: huSimulatorCreateRequestExtraCollateral,
			brokerageClientAdditionalDetails: huBrokerageClientAdditionalDetailsFormConfig,
			brokerageClientCompliance: huBrokerageClientComplianceFormConfig,
			brokerageClientContactDetails: huBrokerageClientContactDetailsFormConfig,
			brokerageClientIdentity: huBrokerageClientIdentityFormConfig,
			brokerageClientPersonalDetails: huBrokerageClientPersonalDetailsFormConfig,
			brokerageClientProfile: huBrokerageClientProfileFormConfig,
			brokerageClientProfileDetailed: huBrokerageClientProfileDetailedFormConfig,
			brokerageAddressDetails: huBrokerageAddressDetailsFormConfig,
			brokerageRealtyBasic: huBrokerageRealtyBasic,
			brokerageRealtyBasicDetails: huBrokerageRealtyBasicDetailsFormConfig,
			brokerageRealtyDetails: huBrokerageRealtyDetailsFormConfig,
			brokerageRealtyDetailed: huBrokerageRealtyDetailed,
			brokerageRealtyEnergeticDetails: () => [],
			brokerageRealtyPrice: huBrokerageRealtyPriceFormConfig,
			brokerageRealtyPriceAndValuation: huBrokerageRealtyPriceAndValuationFormConfig,
			brokerageRealtyRegistration: huBrokerageRealtyRegistrationFormConfig,
			brokerageIncomeSalaryEmployed: huBrokerageIncomeSalaryEmployed,
			brokerageIncomeSelfEmployed: huBrokerageIncomeSelfEmployed,
			brokerageIncomeRetirement: huBrokerageIncomeRetirement,
			brokerageIncomeMemberSalary: huBrokerageIncomeMemberSalary,
			brokerageCreateNewClient: huBrokerageCreateNewClient,
			brokerageCreateNewProperty: huBrokerageCreateNewProperty,
			brokerageClientAddress: huBrokerageClientAddress,
			brokerageClientContact: huBrokerageClientContact,
			brokerageLiabilityTypeAdvanceLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeBabyExpectingLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeBudgetAidToBeReimbursed: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeBusinessLoan: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeCommodityLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeConsumerLoanMovable: huBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeCreditCard: huBrokerageLiabilityTypeCreditCard,
			brokerageLiabilityTypeCreditLine: huBrokerageLiabilityTypeCreditLine,
			brokerageLiabilityTypeCsokMortgage: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeEmployerLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeFarmersLoan: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeForeignLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeGeneralCreditExpense: huBrokerageLiabilityTypeCreditExpense,
			brokerageLiabilityTypeHomeSavingsBankLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeLeasing: huBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeLifeInsuranceLoan: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeMFB: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeMortgageImmovable: huBrokerageLiabilityTypeMortgageImmovable,
			brokerageLiabilityTypeMortgageMovable: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeMotorLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeMunicipalLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeOther: huBrokerageLiabilityTypeOther,
			brokerageLiabilityTypeOtherCredit: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLiabilityTypeOtherGuaranteeObligation: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypePrivateLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypePurchaseCredit: huBrokerageLiabilityTypeLeasing,
			brokerageLiabilityTypeStudentLoan: huBrokerageLiabilityTypeConsumerLoanImmovable,
			brokerageLiabilityTypeVCSOK: huBrokerageLiabilityTypeMortgageMovable,
			brokerageLoanRequestProof: huLoanRequestProofInformative,
			brokerageDocumentDataConnectionStep1: finportaBrokerageDocumentDataConnectionStep1,
			brokerageDocumentDataConnectionStep2: finportaBrokerageDocumentDataConnectionStep2,
			signIn: finportaSignIn,
			passwordConfirm: finportaPasswordConfirm,
			passwordReset: finportaPasswordReset,
			brokerageUploadLibraryDocument: defaultBrokerageUploadLibraryDocumentLanguagePredefined,
			joinCredentials: huJoinCredentials,
			joinDetails: huJoinDetails,
		},
		medirect: {
			mortgageSimulatorIncome: medirectMortgageSimulatorIncome,
			mortgageSimulatorLiability: medirectMortgageSimulatorLiability,
		},
	};

	private readonly customerFormValidatorMap = {
		default: {
			brokerageCsokCashCalculatorInput: csokCashCalculatorValidator,
			brokerageCsokMortgageCalculatorInput: csokMortgageCalculatorValidator,
			brokerageVillageCsokCashCalculatorInput: villageCsokCashCalculatorValidator,
			brokerageVillageCsokMortgageCalculatorInput: villageCsokMortgageCalculatorValidator,
		},
		finporta: {
			brokerageClientProfile: nationalNumberFormValidators,
			brokerageClientIdentity: nationalNumberFormValidators,
		},
	};

	constructor(@Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights) {
		this.customer = this.getCustomer();
		this.formQuestionMap = this.FormQuestionMap;
		this.formValidatorMap = this.FormValidatorMap;
	}

	public isFormConfigured(name: string) {
		return typeof this.formQuestionMap[name] !== 'undefined';
	}

	public getForm(
		name: string,
		formData: any,
		resources?: PartialNormalizedResource | null,
		configModifier?: (formConfig: InputBase<any>[]) => InputBase<any>[],
		...args
	): BehaviorSubject<FormConfiguration> {
		const formConfiguration: FormConfiguration = new FormConfiguration();
		formConfiguration.setName(name);

		if (typeof this.formQuestionMap[name] === 'undefined') {
			throw new Error('Undefined form configuration!');
		}

		formConfiguration.formControl.questions = this.enrichConfigurationMapWithModifier(
			this.formQuestionMap[name](formData, resources, ...args),
			configModifier
		);

		const validators = this.formValidatorMap[name]?.(resources);
		if (validators) {
			formConfiguration.setValidators(validators.validators, validators.asyncValidators);
		}
		return new BehaviorSubject<FormConfiguration>(formConfiguration);
	}

	public getMergedForms(names: string[], formData: any, resources?: PartialNormalizedResource): Observable<FormConfiguration> {
		const formConfiguration: FormConfiguration = new FormConfiguration();

		formConfiguration.formControl.questions = names.reduce(
			(prev, current) => [...prev, ...this.getFormConfigurationQuestionsFromMap(current, this.formQuestionMap, formData, resources)],
			[]
		);

		return new BehaviorSubject<FormConfiguration>(formConfiguration);
	}

	private get FormQuestionMap(): FormQuestionMap {
		return { ...this.customerFormQuestionMap['default'], ...this.customerFormQuestionMap[this.customer] };
	}

	private get FormValidatorMap(): FormValidatorMap {
		return { ...this.customerFormValidatorMap['default'], ...this.customerFormValidatorMap[this.customer] };
	}

	private enrichConfigurationMapWithModifier(
		formQuestionMap: InputBase<any>[],
		configModifier?: (formConfig: InputBase<any>[]) => InputBase<any>[]
	): InputBase<any>[] {
		return typeof configModifier === 'undefined' || configModifier === null ? formQuestionMap : configModifier(formQuestionMap);
	}

	private getFormConfigurationQuestionsFromMap(
		name: string,
		formQuestionMap: FormQuestionMap,
		formData: any,
		resources?: PartialNormalizedResource,
		...args
	): InputBase<any>[] {
		if (typeof formQuestionMap[name] === 'undefined') {
			throw new Error('Undefined form configuration!');
		}

		return formQuestionMap[name](formData, resources, ...args);
	}

	private getCustomer(): string | null {
		const name = this.applicationInsights?.name;
		return !name.includes(this.customerSeparator) || name.split(this.customerSeparator).length === 0
			? null
			: name.split(this.customerSeparator)[0];
	}
}
