import { HttpErrorResponse } from '@angular/common/http';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { Broker, CommissionConfig } from '@oper-client/shared/data-model';

export const loadBrokers = createAction('[Broker] Load Brokers');
export const loadBrokersSuccess = createAction('[Broker] Load Brokers Success', props<{ brokers: Broker[] }>());
export const loadBrokersFailure = createAction('[Broker] Load Brokers Failure', props<{ error: HttpErrorResponse }>());

export const loadBroker = createAction('[Broker] Load Broker', props<{ brokerId: number }>());
export const loadBrokerSuccess = createAction('[Broker] Load Broker Success', props<{ broker: Broker }>());
export const loadBrokerFailure = createAction('[Broker] Load Broker Failure', props<{ error: HttpErrorResponse }>());

export const loadDirectBroker = createAction('[Broker] Load Direct Broker');
export const loadDirectBrokerSuccess = createAction('[Broker] Load Direct Broker Success', props<{ broker: Broker }>());
export const loadDirectBrokerFailure = createAction('[Broker] Load Direct Broker Failure', props<{ error: HttpErrorResponse }>());

export const getCurrentBrokerId = createAction('[Broker] Get current Broker Id');
export const getCurrentBroker = createAction('[Broker] Get current Broker');
export const setCurrentBrokerId = createAction('[Broker] Set current Broker Id', props<{ brokerId: number }>());
export const resetCurrentBrokerId = createAction('[Broker] Reset current Broker Id');
export const clearBrokers = createAction('[Broker] Clear Brokers');

export const updateBroker = createAction('[Broker] Update Broker', props<{ broker: Update<Broker> }>());
export const updateBrokerSuccess = createAction('[Broker] Update Broker Success', props<{ broker: Update<Broker> }>());
export const updateBrokerFailure = createAction('[Broker] Update Broker Error', props<{ error: HttpErrorResponse }>());

export const updateCommissionConfig = createAction('[Broker] Update Commission Config', props<{ config: CommissionConfig }>());
export const updateCommissionConfigSuccess = createAction('[Broker] Update Commission Config Success', props<{ brokerId: number }>());
export const updateCommissionConfigFailure = createAction(
	'[Broker] Update Commission Config Failure',
	props<{ error: HttpErrorResponse }>()
);

export const transferPortfolio = createAction(
	'[Broker] Transfer Portfolio',
	props<{ brokerId: number; beneficiaryBroker: Partial<Broker> }>()
);
export const transferPortfolioSuccess = createAction('[Broker] Transfer Portfolio Success');
export const transferPortfolioFailure = createAction('[Broker] Transfer Portfolio Failure', props<{ error: HttpErrorResponse }>());
