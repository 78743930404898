import { LibraryDocument } from './library-document.model';
import { ConfigurationPortalFields } from './configuration-panel.model';
import { Funder } from '../offer.model';
import { Resource } from '../resource.model';
import { CustomTranslations } from '../base-product.model';

export interface ConfigurationPortalProductVersion extends ConfigurationPortalFields {
	maxDuration: number;
	minDuration: number;
	maxLtv: number;
	minLtv: number;
	maxDti: number;
	minDti: number;
	maxAmount: number;
	minAmount: number;
	maxLiquidities: number;
	maxRenovation: number;
	product: ConfigurationPortalBaseProduct;
	version: number;
	defaultVariability: ConfigurationPortalVariability;
}

export interface ConfigurationPortalBaseProduct extends ConfigurationPortalFields {
	active: boolean;
	name: string;
	creditProvider: ConfigurationPortalCreditProvider;
	funder: Funder;
	loanType: ConfigurationPortalLoanType;
	allowedPurposes: ConfigurationPortalFields[];
	whitelistedBrokers: Resource;
	allowedVariabilities: ConfigurationPortalAllowedProductVariability[];
	productType: ConfigurationPortalFields;
	logoUrl: string;
	isSimulatorReferenceProduct: boolean;
	isSalesAccessRestricted: boolean;
	hasFunderSpecificPricer: boolean;
	customTranslations?: CustomTranslations;
	loanRequestFormDocuments?: LibraryDocument[];
	daysInYear: number;
	firstPaymentDay: number;
	gracePeriod: number;
	rateCalculationMethod: RateCalculationMethod;
	interestCalculationMethod: InterestCalculationMethod;
}
export interface ConfigurationPortalCreditProvider extends ConfigurationPortalFields {
	name: string;
	logoUrl?: string;
	fsmaReference?: string;
	active?: boolean;
	fullName?: string;
	order?: number;
	isLiabilityProvider?: boolean;
}

export interface ConfigurationPortalProductType extends ConfigurationPortalFields {
	order: number;
}

export interface ConfigurationPortalLoanType extends ConfigurationPortalFields {
	order: number;
}

export interface ConfigurationPortalVariability extends ConfigurationPortalFields {
	order: number;
	isSelected?: boolean;
}

export interface ConfigurationPortalLoanPurposeType extends ConfigurationPortalFields {
	order: number;
	isSelected?: boolean;
}

export interface ConfigurationPortalLoanPurposeType extends ConfigurationPortalFields {
	order: number;
	isSelected?: boolean;
}

export enum InterestCalculationMethod {
	MONTHLY = 'monthly',
	DAILY_FIXED_MONTH_LENGTH = 'dailyFixed',
	DAILY_REAL_MONTH_LENGTH = 'dailyReal',
}

export enum RateCalculationMethod {
	COMPOUND = 'compound',
	DIVIDE = 'divide',
}

export interface ConfigurationPortalAllowedProductVariability extends ConfigurationPortalFields {
	isSimulatorReferenceVariability: boolean;
	variability: ConfigurationPortalVariability;
	minDuration: number;
	maxDuration: number;
	defaultDuration: number;
	isDefault?: boolean;
}

export enum ConfigurationPortalProductAmountTypes {
	FIXED_AMOUNT = 'fixedAmount',
	FIXED_AMOUNT_PER_REALTY = 'fixedAmountPerRealty',
	PERCENTAGE_PRODUCT_AMOUNT = 'percentageProductAmount',
	PERCENTAGE_OUTSTANDING_PRODUCT_AMOUNT = 'percentageOutstandingProductAmount',
}

// Maps for converting between camelCase and snake_case
export const interestCalculationMethodMap = new Map<InterestCalculationMethod, string>([
	[InterestCalculationMethod.MONTHLY, 'monthly'],
	[InterestCalculationMethod.DAILY_FIXED_MONTH_LENGTH, 'daily_fixed'],
	[InterestCalculationMethod.DAILY_REAL_MONTH_LENGTH, 'daily_real'],
]);

export const rateCalculationMethodMap = new Map<RateCalculationMethod, string>([
	[RateCalculationMethod.COMPOUND, 'compound'],
	[RateCalculationMethod.DIVIDE, 'divide'],
]);
