import { DocumentTypes, Liability, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { InputBase } from '../../models/input-base.model';
import { InputField, InputSelect } from '../../models/input-types.model';
import { FormGroup } from '@angular/forms';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export default function (
	formData?: Partial<Liability>,
	resources?: PartialNormalizedResource,
	allowedDocuments?: any,
	kind?: string,
	MFODocId?: number
): InputBase<any>[] {
	const options = allowedDocuments
		.map((current) => resources['document-type'].find((item) => item.definition === current.definition))
		.filter(Boolean);
	return [
		new InputSelect({
			key: 'documentType.id',
			label: 'ç.question.proofRequirement.label',
			required: true,
			options: options,
		}),
		...(kind === DocumentTypes.informative
			? [
					new InputField({
						type: 'text',
						key: 'externalId',
						label: 'ç.question.comment.label',
						required: false,
						transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
							const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'externalId');
							return formGroup.controls['documentType.id'].valueChanges.pipe(
								distinctUntilChanged(),
								map((id: number) => {
									inputField.required = id === MFODocId;
									return inputField;
								})
							);
						},
					}),
				]
			: []),
	];
}
