import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DiscountConfiguration } from '@oper-client/shared/data-model';
import { AcceptanceRuleParameterValuePipe } from '../pipes/acceptance-rule-parameter-value.pipe';
import { LanguageService } from '@oper-client/shared/util-language';

@Pipe({
	name: 'discountDescription',
	pure: false, // otherwise translation doesn't happen when switching language
})
export class DiscountDescriptionPipe implements PipeTransform {
	constructor(
		private readonly parameterValueTypePipe: AcceptanceRuleParameterValuePipe,
		private readonly translateService: TranslateService,
		private readonly languageService: LanguageService
	) {}

	transform(discount: DiscountConfiguration): unknown {
		const translationParams = {};
		discount.parameters?.forEach((param) => {
			translationParams[param.name] = this.parameterValueTypePipe.transform(param.value, param.type);
		});
		translationParams['interestRate'] = this.parameterValueTypePipe.transform(discount.interestRate, { definition: 'percentage' });

		const currentLanguage = this.languageService.getCurrentLanguage();

		if (discount.explanations && discount.explanations.length > 0) {
			const explanation = discount.explanations.find((exp) => exp.language.definition === currentLanguage);
			if (explanation && explanation.explanation) {
				const translatedExplanation = this.translateService.instant(explanation.explanation, translationParams);
				return this.interpolate(translatedExplanation, translationParams);
			}
		}

		const translationKey = `ç.feature.offer.product.discounts.${discount.name}`;
		const translatedText = this.translateService.instant(translationKey, translationParams);
		return translatedText === translationKey
			? this.translateService.instant('ç.configurationPortal.product.productDiscounts.noTranslation')
			: translatedText;
	}

	private interpolate(str: string, params: { [key: string]: any }): string {
		return str.replace(/{{\s*([^{}\s]*)\s*}}/g, (match, key) => params[key] || match);
	}
}
