import { DynamicInputTable, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { orderBy } from '@oper-client/shared/util-array';
import { LoanApplicationDto, LoanPurposeEnums, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../services/validator-constants';
import { debounceTimes } from '@oper-client/shared/configuration';

export default function (formData?: LoanApplicationDto, resources?: PartialNormalizedResource): InputBase<any>[] {
	const loanPurpose = formData?.loanPurpose?.definition ?? 'default';

	switch (loanPurpose) {
		case LoanPurposeEnums.PURCHASE:
			return getFormConfigurationForProjectPurposePurchase(formData, resources);
		case LoanPurposeEnums.NEW_BUILD:
			return getFormConfigurationForProjectPurposeNewBuild(formData, resources);
		case LoanPurposeEnums.RENOVATION:
			return getFormConfigurationForProjectPurposeRenovation(formData, resources);
		case LoanPurposeEnums.REFINANCE:
			return getFormConfigurationForProjectPurposeRefinance(formData, resources);
		case LoanPurposeEnums.BUY_OUT:
			return getFormConfigurationForProjectPurposeBuyOut(formData, resources);
		default:
			return [];
	}
}

function getFormConfigurationForProjectPurposePurchase(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const questions: InputBase<any>[] = [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			required: true,
			hideRequiredAsterisk: true,
			transform: (value) => +value,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	if (formData?.renovations?.length > 0) {
		questions.push(
			new DynamicInputTable({
				key: 'renovations',
				value: formData?.renovations,
				debounceTime: debounceTimes.xxs,
				showAsCard: true,
				cardTitle: 'ç.feature.realty.renovation.singular',
				addRowLabel: 'ç.feature.realty.renovations.addRenovation',
				label: 'ç.feature.mortgageSimulator.addIncome',
				required: true,
				columns: [
					new InputSelect({
						key: 'renovationType.id',
						required: true,
						clearable: true,
						label: 'ç.question.renovationType.label',
						validators: [],
						options: orderBy(resources?.[ResourceType.RENOVATION_TYPE] || [], 'order'),
						alreadySorted: true,
						class: 'span6',
					}),
					new InputField({
						key: 'amountContractor',
						label: 'ç.question.amount.label',
						type: 'number',
						required: true,
						currency: true,
						class: 'span6',
					}),
				],
			})
		);
	}

	return questions;
}

function getFormConfigurationForProjectPurposeNewBuild(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			hideRequiredAsterisk: true,
		}),
	];
}

function getFormConfigurationForProjectPurposeRenovation(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			required: true,
			hideRequiredAsterisk: true,
			transform: (value) => +value,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
	];
}

function getFormConfigurationForProjectPurposeRefinance(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			required: true,
			hideRequiredAsterisk: true,
			transform: (value) => +value,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
	];
}

function getFormConfigurationForProjectPurposeBuyOut(
	formData?: LoanApplicationDto,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.epcContractNumber,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			required: true,
			hideRequiredAsterisk: true,
			transform: (value) => +value,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
	];
}
