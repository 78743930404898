/**
 * Returns the current timezone as a UTC offset, in TZD (time zone designator, `+hh:mm or -hh:mm`) format.
 * For example, if `new Date().getTimezoneOffset()` = `-120`, the result will be `+02:00`.
 */
export function getCurrentUTCOffset(): string {
	const tzOffset = new Date().getTimezoneOffset(),
		sign = tzOffset <= 0 ? '+' : '-',
		hours = Math.floor(Math.abs(tzOffset) / 60),
		minutes = Math.abs(tzOffset) % 60;
	return `${sign}${pad(hours)}:${pad(minutes)}`;
}

/**
 * Concatenates `date` and `time` string inputs and appends the current timezone,
 * formatting the result as a ISO 8601 datetime (in `YYYY-MM-DDThh:mm:ssTZD`).
 * For example, if `date = "2020-06-30` and `time = "19:51:44"`, and the current timezone is `UTC+02:00`,
 * the result will be `"2020-06-30T19:51:44+02:00"`.
 * See https://www.w3.org/TR/NOTE-datetime.
 */
export function convertToISO8601(date: string, time: string): string {
	return `${date}` + (typeof time !== 'undefined' ? `T${time}${getCurrentUTCOffset()}` : '');
}

/**
 * Extracts local date and time from Date (in `YYYY-MM-DD` and `hh:mm` format).
 */
export function extractLocalDateAndTime(date: Date): { date: string; time: string } {
	// shift the datetime to the current tz
	const tzOffsetServerClient = date.getTimezoneOffset() - new Date().getTimezoneOffset();
	date.setMinutes(date.getMinutes() + tzOffsetServerClient);

	return {
		date: `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`,
		time: `${pad(date.getHours())}:${pad(date.getMinutes())}`,
	};
}

/**
 * Pads a number with leading zeros to a length of 2.
 */
function pad(value: number): string {
	return value.toString().padStart(2, '0');
}

const months_in_year = 12;

/**
 * Converts a number of months to a number of full years.
 * E.g., 36 months = 3 years, 50 months = 4 years.
 */
export function convertMonthsToYears(months: number): number {
	return Math.ceil(months / months_in_year);
}

/**
 * Converts a number of years to a number of months.
 * E.g., 3 years = 36 months.
 */
export function convertYearsToMonths(months: number): number {
	return Math.floor(months * months_in_year);
}

/**
 * Generates an array of objects representing allowed durations between minDuration and maxDuration.
 * @param minDuration minimal duration (in months)
 * @param maxDuration maximal duration (in months)
 * @param creator allows generation of an array of a custom type based on allowed durations
 */
export function generateAllowedDurations<T>(minDuration: number, maxDuration: number, creator?: (duration: number) => T): T[] {
	const allowedDurations = [];
	for (let duration = minDuration; duration <= maxDuration; duration += months_in_year) {
		allowedDurations.push(creator ? creator(duration) : duration);
	}
	return allowedDurations;
}

export const yearOptions1500 = getYearOptions(1500);
export const yearOptions1700 = getYearOptions(1700);

function getYearOptions(year: number): { id: number; key: string }[] {
	return Array.from({ length: new Date().getFullYear() - year + 1 }, (_, i) => i + year)
		.reverse()
		.map((year) => ({
			id: year,
			key: year.toString(),
		}));
}

/**
 * Returns the current date in ISO 8601 format (in `YYYY-MM-DD`).
 */
export function getCurrentDate(): string {
	return new Date().toISOString().split('T')[0];
}
