import { PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData: Partial<Simulator.Simulation> | null = null, resources?: PartialNormalizedResource): InputBase<any>[] {
	const questions = [
		new InputField({
			key: 'collaterals[0].collateralAmount',
			type: 'hidden',
			value: formData?.collaterals?.[0]?.collateralAmount ?? 0,
		}),
		new InputSelect({
			key: 'collaterals[0].realtyType.id',
			label: 'ç.question.propertyType.label',
			value: formData?.collaterals?.[0]?.realtyType?.id,
			required: true,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
	];

	return questions;
}
