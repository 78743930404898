import { debounceTimes } from '@oper-client/shared/configuration';
import { PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { of } from 'rxjs';
import { DynamicInputTable, InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';

export default function (
	formData: Partial<Simulator.Borrower> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	return [
		new DynamicInputTable({
			key: 'incomes',
			value: formData?.incomes,
			debounceTime: debounceTimes.xxs,
			addRowLabel: 'ç.feature.mortgageSimulator.addIncome',
			label: 'ç.feature.mortgageSimulator.addIncome',
			required: true,
			disabled: readonlyMode,
			columns: [
				new InputField({ key: 'id', type: 'hidden', required: false }),
				new InputSelect({
					key: 'incomeType.id',
					required: true,
					clearable: true,
					disabled: readonlyMode,
					label: 'ç.feature.mortgageSimulator.incomeType.label',
					helpText: of('ç.feature.mortgageSimulator.incomeType.tooltip'),
					validators: [],
					options: orderBy(resources?.[ResourceType.INCOME_TYPE] || [], 'order'),
					alreadySorted: true,
					class: 'span6',
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: true,
					currency: true,
					class: 'span6',
					disabled: readonlyMode,
				}),
			],
		}),

		new DynamicInputTable({
			key: 'liabilities',
			value: formData?.liabilities,
			debounceTime: debounceTimes.xxs,
			addRowLabel: 'ç.feature.mortgageSimulator.addLiability',
			label: 'ç.feature.mortgageSimulator.addLiability',
			required: true,
			disabled: readonlyMode,
			columns: [
				new InputField({ key: 'id', type: 'hidden', required: false }),
				new InputSelect({
					key: 'liabilityType.id',
					required: true,
					clearable: true,
					disabled: readonlyMode,
					label: 'ç.feature.mortgageSimulator.liabilityType.label',
					helpText: of('ç.feature.mortgageSimulator.liabilityType.tooltip'),
					validators: [],
					options: orderBy(resources?.[ResourceType.LIABILITY_TYPE] || [], 'order'),
					alreadySorted: true,
					class: 'span6',
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: true,
					disabled: readonlyMode,
					currency: true,
					class: 'span6',
				}),
			],
		}),
		new InputField({
			key: 'numberOfDependents',
			label: 'ç.question.numberOfDependents.label',
			value: formData?.numberOfDependents,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span12',
			disabled: readonlyMode,
			placeholder: 'ç.question.numberOfDependents.placeholder',
		}),
	];
}
