import { AnyObject } from '../models/any-object.model';
import { CostFieldConfig, FinancialPlanConfig, toCostFieldKey } from '../models/financial-plan-config.model';
import {
	FinancialPlan,
	FinancialPlanCostItem,
	FinancialPlanCostItemGroup,
	FinancialPlanFieldUpdate,
	FinancialPlanLoanAmountItem,
	FinancialPlanOwnFundItem,
	MonthlyPaymentEstimation,
} from '../models/financial-plan.model';
import { LoanRequest } from '../models/loan-request.model';

export function getFinancialPlanLoanAmountFields(financialPlanConfig: FinancialPlanConfig | null): FinancialPlanLoanAmountItem[] {
	return (
		financialPlanConfig?.financialPlanLoanAmountFields
			.filter((field) => field.isOverridable)
			.map((field) => ({ financialPlanLoanAmountField: field, amount: 0, isOverridden: false })) ?? []
	);
}

export function getFinancialPlanOwnFundFields(financialPlanConfig: FinancialPlanConfig | null): FinancialPlanOwnFundItem[] {
	return (
		financialPlanConfig?.financialPlanOwnFundFields
			.filter((field) => field.isOverridable)
			.map((field) => ({ financialPlanOwnFundField: field, amount: 0, isOverridden: false })) ?? []
	);
}

export function getMonthlyPaymentEstimations(
	loanAmountItems: FinancialPlanLoanAmountItem[],
	monthlyPaymentEstimations: MonthlyPaymentEstimation[]
): MonthlyPaymentEstimation[] {
	const monthlyPaymentEstimationsLookup: AnyObject<MonthlyPaymentEstimation> = monthlyPaymentEstimations.reduce(
		(acc, estimation) => ({
			...acc,
			[estimation.item.financialPlanLoanAmountField.loanAmountType.definition]: estimation,
		}),
		{}
	);

	return loanAmountItems
		.filter((item) => item.financialPlanLoanAmountField.isOverridable)
		.map((item) => {
			const key = item.financialPlanLoanAmountField.loanAmountType.definition;
			const monthlyPaymentEstimation = monthlyPaymentEstimationsLookup[key];

			return {
				...(monthlyPaymentEstimation ?? {
					monthlyInstallment: 0,
					rate: item.financialPlanLoanAmountField.defaultRate,
					duration: item.financialPlanLoanAmountField.defaultDuration,
				}),
				item,
			};
		})
		.sort(
			(a, b) => a.item.financialPlanLoanAmountField.loanAmountType.order - b.item.financialPlanLoanAmountField.loanAmountType.order
		);
}

export function calculateTotalProjectCosts(loanRequest: LoanRequest, financialPlanConfig: FinancialPlanConfig) {
	return (
		Math.round(
			financialPlanConfig?.financialPlanCostFields
				.map((costField) => toCostFieldKey(costField.costType.definition))
				.reduce((usage, key) => usage + (loanRequest?.[key] || 0), 0) * 100
		) / 100
	);
}

export class FinancialPlanCalculator {
	private readonly loanAmountItems: AnyObject<FinancialPlanLoanAmountItem>;
	private readonly ownFundItems: AnyObject<FinancialPlanOwnFundItem>;

	constructor(financialPlanLoanAmountItems: FinancialPlanLoanAmountItem[], financialPlanOwnFundItems: FinancialPlanOwnFundItem[]) {
		this.loanAmountItems = this.getLoanAmountItems(financialPlanLoanAmountItems);
		this.ownFundItems = this.getOwnFundItems(financialPlanOwnFundItems);
	}

	get mainLoanAmountItem() {
		return Object.values(this.loanAmountItems).find(
			({ financialPlanLoanAmountField }) => financialPlanLoanAmountField.isMain && financialPlanLoanAmountField.isOverridable
		);
	}

	get mainOwnFundItem() {
		return Object.values(this.ownFundItems).find(
			({ financialPlanOwnFundField }) => financialPlanOwnFundField.isMain && financialPlanOwnFundField.isOverridable
		);
	}

	get totalLoanAmountValue() {
		return Object.values(this.loanAmountItems).reduce((acc, { amount }) => acc + amount, 0);
	}

	get totalOwnFundsValue() {
		return Object.values(this.ownFundItems).reduce((acc, { amount }) => acc + amount, 0);
	}

	updateFinancialPlan(fieldUpdate: FinancialPlanFieldUpdate): FinancialPlan {
		if (this.isLoanAmountFieldItem(fieldUpdate.key)) {
			return this.updateLoanAmountItems(fieldUpdate.key, fieldUpdate.amount, fieldUpdate.isOverridden ?? true);
		} else if (this.isOwnFundFieldItem(fieldUpdate.key)) {
			return this.updateOwnFundItems(fieldUpdate.key, fieldUpdate.amount, fieldUpdate.isOverridden ?? true);
		}

		return {
			financialPlanLoanAmountItems: Object.values(this.loanAmountItems),
			financialPlanOwnFundItems: Object.values(this.ownFundItems),
		};
	}

	updateMainOwnFundItem(amount: number): FinancialPlan {
		const key = this.mainOwnFundItem.financialPlanOwnFundField.ownFundType.definition;
		return this.updateOwnFundItems(key, amount, true);
	}

	updateMainLoanAmountItem(amount: number): FinancialPlan {
		const key = this.mainLoanAmountItem.financialPlanLoanAmountField.loanAmountType.definition;
		return this.updateLoanAmountItems(key, amount, true);
	}

	resetFinancialPlan(): FinancialPlan {
		return {
			financialPlanLoanAmountItems: Object.values(this.loanAmountItems).map((item) => ({ ...item, isOverridden: false, amount: 0 })),
			financialPlanOwnFundItems: Object.values(this.ownFundItems).map((item) => ({ ...item, isOverridden: false, amount: 0 })),
		};
	}

	private getOwnFundItems(financialPlanOwnFundItems: FinancialPlanOwnFundItem[]): AnyObject<FinancialPlanOwnFundItem> {
		return financialPlanOwnFundItems.reduce(
			(acc, { financialPlanOwnFundField, amount, isOverridden }) => ({
				...acc,
				[financialPlanOwnFundField.ownFundType.definition]: { financialPlanOwnFundField, amount, isOverridden },
			}),
			{}
		);
	}

	private getLoanAmountItems(financialPlanLoanAmountItems: FinancialPlanLoanAmountItem[]): AnyObject<FinancialPlanLoanAmountItem> {
		return financialPlanLoanAmountItems.reduce(
			(acc, { financialPlanLoanAmountField, amount, isOverridden }) => ({
				...acc,
				[financialPlanLoanAmountField.loanAmountType.definition]: { financialPlanLoanAmountField, amount, isOverridden },
			}),
			{}
		);
	}

	private isLoanAmountFieldItem(key: string): boolean {
		return !!this.loanAmountItems[key];
	}

	private isOwnFundFieldItem(key: string): boolean {
		return !!this.ownFundItems[key];
	}

	private resetOverriddenFlag(items: FinancialPlanLoanAmountItem[] | FinancialPlanOwnFundItem[]) {
		return items.map((item) => ({ ...item, isOverridden: false }));
	}

	private updateLoanAmountItems(key: string, amount: number, isOverridden: boolean): FinancialPlan {
		const updatedLoanAmountItems = this.recalculateMainLoanAmountItem(key, amount, this.loanAmountItems[key].amount);

		const financialPlanLoanAmountItems = Object.values({
			...updatedLoanAmountItems,
			[key]: { ...updatedLoanAmountItems[key], amount, isOverridden },
		});

		return { financialPlanLoanAmountItems, financialPlanOwnFundItems: this.resetOverriddenFlag(Object.values(this.ownFundItems)) };
	}

	private updateOwnFundItems(key: string, amount: number, isOverridden: boolean): FinancialPlan {
		return {
			financialPlanLoanAmountItems: this.resetOverriddenFlag(Object.values(this.loanAmountItems)),
			financialPlanOwnFundItems: Object.values({
				...this.ownFundItems,
				[key]: { ...this.ownFundItems[key], amount, isOverridden },
			}),
		};
	}

	/**
	 * This function is used to increase or decrease the main loan amount item when another financial plan field item is updated
	 * @param key - key of the item
	 * @param amount - new amount
	 * @param previousAmount - previous amount
	 * @returns updated loan amount items
	 */
	private recalculateMainLoanAmountItem(key: string, amount: number, previousAmount: number): AnyObject<FinancialPlanLoanAmountItem> {
		const mainLoanAmountItem = this.mainLoanAmountItem;
		const mainLoanAmountItemKey = mainLoanAmountItem.financialPlanLoanAmountField.loanAmountType.definition;
		if (mainLoanAmountItemKey !== key) {
			const delta = previousAmount - amount;
			const updatedAmount = mainLoanAmountItem.amount + delta;

			return {
				...this.loanAmountItems,
				[mainLoanAmountItemKey]: {
					...mainLoanAmountItem,
					// Main loan amount can't be negative
					// If the main loan amount is negative, set it to 0 because it will trigger recalculate feature
					amount: updatedAmount < 0 ? 0 : updatedAmount,
				},
			};
		}

		return this.loanAmountItems;
	}
}

export function getFinancialPlanCostBreakdown(
	financialPlan: AnyObject<number>,
	costFields: Partial<CostFieldConfig>[],
	defaultCostGroupType = 'default'
): FinancialPlanCostItemGroup[] {
	if (!costFields || Object.keys(financialPlan ?? {}).length === 0) {
		return [];
	}

	const costGroups: FinancialPlanCostItemGroup[] = [];
	Object.entries(financialPlan)
		.filter(([key]) => financialPlan[key] > 0)
		.forEach(([key, value]) => {
			const costType = removePrefixAndLowercaseFirst('cost', key);
			const label = `ç.question.${key}.label`;
			const helpText = `ç.question.${key}.helpText`;
			const costField = costFields.find((field) => field?.costType?.definition === costType);
			if (!costField) {
				return;
			}

			const costItem = {
				key,
				label,
				helpText,
				value,
				costType,
				costGroupType: costField?.costGroupType?.definition,
			} as FinancialPlanCostItem;

			const costGroup = costItem.costGroupType ?? defaultCostGroupType;
			const existingGroup = costGroups.find((group) => group.costGroupType === costGroup);
			if (existingGroup) {
				existingGroup.costs.push(costItem);
				existingGroup.totalCosts += costItem.value;
				existingGroup.costs.sort((a, b) => b.value - a.value);
			} else {
				costGroups.push({
					costGroupType: costGroup,
					costs: [costItem],
					totalCosts: costItem.value,
					label: `ç.feature.financials.${costGroup}`,
				});
			}
		});

	return costGroups.sort((a, b) => b.totalCosts - a.totalCosts);
}

function removePrefixAndLowercaseFirst(prefix: string, input: string): string {
	if (!input.startsWith(prefix)) {
		return input;
	}

	const withoutPrefix = input.slice(prefix.length);
	return withoutPrefix.charAt(0).toLowerCase() + withoutPrefix.slice(1);
}
