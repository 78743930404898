import { PartialNormalizedResource, Simulator } from '@oper-client/shared/data-model';
import { of } from 'rxjs';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const questions = [
		new InputField({
			key: 'collaterals[0].collateralAmount',
			label: 'ç.feature.mortgageSimulator.collateralAmount',
			helpText: of('ç.feature.mortgageSimulator.collateralAmountTooltip'),
			value: formData?.collaterals?.[0]?.collateralAmount ?? 0,
			type: 'number',
			disabled: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
		}),
	];

	return questions;
}
