import { createAction, props } from '@ngrx/store';
import { LoadValidationData, ValidationResult } from '@oper-client/shared/data-model';
import { HttpErrorResponse } from '@angular/common/http';
import { AiValidationConfig } from '@oper-client/shared/configuration';

export const loadFieldMapping = createAction('[AI Validation] Get Field Mapping');
export const loadFieldMappingSuccess = createAction(
	'[AI Validation] Get Field Mapping Success',
	props<{
		config: AiValidationConfig;
	}>()
);
export const loadFieldMappingFailure = createAction(
	'[AI Validation] Get Field Mapping Failure',
	props<{
		error: HttpErrorResponse;
	}>()
);
export const triggerValidation = createAction(
	'[AI Validation] Trigger Validation',
	props<{
		loanRequestId: number;
		proofId: number;
		docId: number;
		docName: string;
		category: string;
		categoryEntityId: number;
	}>()
);
export const triggerValidationSuccess = createAction(
	'[AI Validation] Trigger Validation Success',
	props<{
		loanRequestId: number;
		proofId: number;
		docId: number;
		docName: string;
		category: string;
		categoryEntityId: number;
		id: string;
		retry?: boolean
	}>()
);

export const triggerValidationFailure = createAction(
	'[AI Validation] Trigger Validation Failure',
	props<{
		error: HttpErrorResponse;
	}>()
);

export const loadValidationResults = createAction(
	'[AI Validation] Load Validation Results',
	props<{
		validations: LoadValidationData[]
	}>()
);

export const loadValidationResultsSuccess = createAction(
	'[AI Validation] Load Validation Results Success',
	props<{
		validationResults: ValidationResult[];
	}>()
);
export const clearValidationResults = createAction('[AI Validation] Clear Validation Results');

export const loadValidationResultsFailure = createAction(
	'[AI Validation] Load Validation Results Failure'
);

export const enableValidationTrigger = createAction('[AI Validation] Enable Validation Trigger');

