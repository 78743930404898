import { AnyObject } from '../models/any-object.model';
import { Step } from '../models/step.model';

export function generateDataObjectFromStepDataPoints<T>(dataPoints: AnyObject<any>): T {
	return Object.keys(dataPoints ?? {}).reduce((dataObject, stepName) => {
		const stepData: Partial<T> | undefined = dataPoints[stepName];
		if (stepData) {
			dataObject = { ...dataObject, ...stepData };
		}
		return dataObject;
	}, {} as T);
}

export function updateStepDataPoints<T>(dataPoints: AnyObject<any>, dataObject: Partial<T>): AnyObject<any> {
	let result = { ...dataPoints };
	Object.entries(dataObject).forEach(([key, value]) => {
		const stepName = Object.keys(result).find((step) => result[step][key] !== undefined);

		if (stepName) {
			result = {
				...result,
				[stepName]: { ...result[stepName], [key]: value },
			};
		}
	});

	return result;
}

export function getStepsWhichHaveSavedDataPoints(dataPoints: AnyObject<any>): string[] {
	return Object.entries(dataPoints || {})
		.filter(([, value]) => Object.keys(value).some((key) => value[key] !== null))
		.map(([key]) => key);
}

export function findStepByName(steps: Step[], name: string): Step | null {
	if (!name) {
		return null;
	}

	const stack = [...steps];

	while (stack.length > 0) {
		const step = stack.pop();
		if (step.name === name) {
			return step;
		}

		if (step.steps) {
			stack.push(...step.steps);
		}
	}

	return null;
}

export function getActiveStep(steps: Step[]): Step | null {
	if (steps.length === 0) {
		return null;
	}

	const activeStep = steps.find((step) => step.active);
	if (activeStep) {
		return activeStep;
	}

	const firstStep = steps[0];
	if (firstStep.steps && firstStep.steps.length > 0) {
		return firstStep.steps[0];
	}

	return firstStep;
}

export function getUpdatedSteps(steps: Step[], activeStep: Step, completedSteps: string[]): Step[] {
	const completed = new Set(completedSteps);
	if (activeStep?.completed && !completed.has(activeStep?.name)) {
		completed.add(activeStep.name);
	}

	return steps.map((step) => {
		const hasActiveChild = step.steps?.some((subStep) => subStep.name === activeStep?.name) ?? false;
		const hasAllChildrenCompleted = step.steps?.every((subStep) => completed.has(subStep.name)) ?? false;

		return {
			...step,
			active: step.name === activeStep?.name || hasActiveChild,
			completed: completed.has(step.name) || hasAllChildrenCompleted,
			steps: hasActiveChild ? getUpdatedSteps(step.steps, activeStep, completedSteps) : step.steps,
		};
	});
}
