import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ConfigurationPortalFields } from '@oper-client/shared/data-model';
import { DocumentLibrary, PartialNormalizedResource } from '@oper-client/shared/data-model';

export default function (
	formData?: DocumentLibrary,
	resources?: PartialNormalizedResource,
	languages?: ConfigurationPortalFields[]
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'language.definition',
			label: 'ç.misc.language.singular',
			required: true,
			clearable: true,
			validators: [],
			options: languages?.map((language) => ({
				id: language.definition,
				key: `ç.resource.language.${language.definition}`,
			})),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputField({
			key: 'fileName',
			label: 'ç.question.fileName.label',
			value: formData?.fileName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
	];
}
