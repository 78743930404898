import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AiValidationPartialState } from './ai-validation.reducer';
import * as AIValidationSelectors from './ai-validation.selectors';
import * as AIValidationActions from './ai-validation.actions';
import { LoadValidationData } from '@oper-client/shared/data-model';

@Injectable()
export class AiValidationFacade {
	fieldMapping$ = this.store.pipe(select(AIValidationSelectors.getFieldMapping));

	validationResults$ = this.store.pipe(select(AIValidationSelectors.getValidationResults));

	constructor(
		private store: Store<AiValidationPartialState>,
	) {}

	public loadFieldMapping(): void {
		this.store.dispatch(AIValidationActions.loadFieldMapping());
	}

	public loadValidationResults(validations: LoadValidationData[]): void {
		this.store.dispatch(AIValidationActions.loadValidationResults({ validations }));
	}

	public clearValidationResults(): void {
		this.store.dispatch(AIValidationActions.clearValidationResults());
	}
	
	public enableValidationTrigger(): void {
		this.store.dispatch(AIValidationActions.enableValidationTrigger());
	}
}
