import { DynamicFormCard, InputField, InputPhone, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { Client, LoanApplicationDto, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { FormConfiguration } from '../models/dynamic-form.model';
import { ValidatorService } from '../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS, DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../services/validator-constants';
import { Validators } from '@angular/forms';

export default function (formData?: LoanApplicationDto, resources?: PartialNormalizedResource): InputBase<any>[] {
	const additionalFormFields: InputBase<any>[] = [];

	if (formData?.coBorrowerIncomes?.length > 0) {
		const secondaryBorrowerFormConfiguration = new FormConfiguration();
		secondaryBorrowerFormConfiguration.setName('coBorrowerPersonalDetailsForm');
		secondaryBorrowerFormConfiguration.formControl.questions = getClientDetailsFormConfiguration(
			formData?.coBorrowerPersonalDetails,
			resources
		);

		additionalFormFields.push(
			new DynamicFormCard({
				title: 'ç.misc.coBorrower',
				key: 'coBorrowerPersonalDetails',
				formConfiguration: secondaryBorrowerFormConfiguration,
				showDeleteButton: false,
			})
		);
	}

	const mainBorrowerFormConfiguration = new FormConfiguration();
	mainBorrowerFormConfiguration.setName('mainBorrowerPersonalDetailsForm');
	mainBorrowerFormConfiguration.formControl.questions = getClientDetailsFormConfiguration(
		formData?.mainBorrowerPersonalDetails,
		resources
	);

	return [
		new DynamicFormCard({
			title: 'ç.misc.mainBorrower',
			key: 'mainBorrowerPersonalDetails',
			formConfiguration: mainBorrowerFormConfiguration,
			showDeleteButton: false,
		}),

		...additionalFormFields,
	];
}

function getClientDetailsFormConfiguration(formData: Partial<Client>, resources: PartialNormalizedResource): InputBase<any>[] {
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
	const languages = resources?.[ResourceType.LANGUAGE]?.map((language) => ({
		...language,
		key: `ç.resource.language-name.${language.definition}`,
	}));

	return [
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName,
			required: true,
			hideRequiredAsterisk: true,
			type: 'text',
			class: 'span6',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName,
			required: true,
			hideRequiredAsterisk: true,
			type: 'text',
			class: 'span6',
		}),
		new InputSelect({
			key: 'sex.id',
			label: 'ç.question.gender.label',
			value: formData?.sex?.id,
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			options: resources?.[ResourceType.SEX] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'birthCity',
			label: 'ç.question.birthCity.label',
			value: formData?.birthCity,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'birthCountry.id',
			label: 'ç.question.birthCountry.label',
			value: formData?.birthCountry?.id,
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			options: resources?.[ResourceType.COUNTRY] || [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'nationality.id',
			label: 'ç.question.nationality.label',
			value: formData?.nationality?.id,
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			options: resources?.[ResourceType.NATIONALITY] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'idCardNumber',
			label: 'ç.question.idCardNumber.label',
			value: formData?.idCardNumber,
			type: 'text',
			required: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS_LETTERS_DASHES, 'onlyNumbersAndLetters'),
			],
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
		new InputField({
			key: 'idExpirationDate',
			label: 'ç.question.idCardExpirationDate.label',
			value: formData?.idExpirationDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
			min: currentDate,
			validators: [ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST)],
			class: 'span12',
		}),
		new InputField({
			key: 'nationalNumber',
			label: 'ç.question.nationalNumber.label',
			value: formData?.nationalNumber,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'civilStatus.id',
			label: 'ç.question.civilStatus.label',
			value: formData?.civilStatus?.id,
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			options: resources?.[ResourceType.CIVIL_STATUS] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'addresses[0].street',
			label: 'ç.question.street.label',
			value: formData?.addresses?.[0]?.street || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputField({
			key: 'addresses[0].houseNumber',
			label: 'ç.question.number.label',
			value: formData?.addresses?.[0]?.houseNumber || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'addresses[0].zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.addresses?.[0]?.zipCode || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(
					COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS_LETTERS_WHITESPACE,
					'onlyNumbersAndLetters'
				),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'addresses[0].city',
			label: 'ç.question.city.label',
			value: formData?.addresses?.[0]?.city || null,
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'addresses[0].country.id',
			label: 'ç.question.country.label',
			value: formData?.addresses?.[0]?.country?.id,
			required: true,
			hideRequiredAsterisk: true,
			options: resources?.[ResourceType.COUNTRY],
		}),
		new InputPhone({
			key: 'phoneNumbers[0]',
			label: 'ç.question.mobileNumber.label',
			value: formData.phoneNumbers?.[0] || {},
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
			allowedTypes: ['MOBILE'],
		}),
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			value: formData?.employmentStatus?.id,
			required: true,
			hideRequiredAsterisk: true,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.languageForDocuments.label',
			value: formData?.language?.id,
			options: languages,
			required: true,
			hideRequiredAsterisk: true,
			class: 'span12',
		}),
	];
}
