import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ConfigurationPortalAcceptanceRule } from '@oper-client/shared/data-model';
import { CreditProvider, PartialNormalizedResource } from '@oper-client/shared/data-model';

export default function (
	formData?: ConfigurationPortalAcceptanceRule,
	resources?: PartialNormalizedResource,
	acceptanceRuleNames?: string[],
	creditProviders?: CreditProvider[]
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'function',
			label: 'ç.configurationPortal.acceptanceRule.function',
			value: formData?.function,
			options: acceptanceRuleNames?.map((creditProvider) => ({
				id: creditProvider,
				key: creditProvider,
			})),
			searchable: true,
			class: 'span12',
		}),
		new InputField({
			key: 'blocking',
			label: 'ç.configurationPortal.acceptanceRule.blocking',
			value: formData?.blocking,
			type: 'checkbox',
			class: 'span12',
			labelPlacement: 'slim',
		}),
		new InputField({
			key: 'postCalculation',
			label: 'ç.configurationPortal.acceptanceRule.postCalculation',
			value: formData?.postCalculation,
			type: 'checkbox',
			class: 'span12',
			labelPlacement: 'slim',
		}),
		new InputSelect({
			key: 'creditProvider.configurationId',
			label: 'ç.feature.offer.product.creditProvider',
			value: formData?.creditProvider?.configurationId,
			options: creditProviders?.map((creditProvider) => ({
				id: creditProvider.configurationId,
				key: creditProvider.name,
			})),
			searchable: true,
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'isForSimulator',
			label: 'ç.configurationPortal.acceptanceRule.isForSimulator',
			value: formData?.isForSimulator,
			type: 'checkbox',
			class: 'span12',
			labelPlacement: 'slim',
		}),
		new InputField({
			key: 'isForPreapproval',
			label: 'ç.configurationPortal.acceptanceRule.isForPreapproval',
			value: formData?.isForPreapproval,
			type: 'checkbox',
			class: 'span12',
			labelPlacement: 'slim',
		}),
	];
}
