<div class="rows">
	@for (row of rows; track $index) {
		<div [class.card]="showAsCard()">
			@if (cardTitle()) {
				<div class="header__title" [class.main]="showAsCard()">
					{{ cardTitle() | translate }}

					@if (showDeleteButton()) {
						<div class="header__remove-icon">
							<oper-client-fontawesome-icon
								[title]="'ç.misc.action.Remove' | translate"
								[icon]="'faTimes'"
								[size]="'lg'"
								(click)="removeRow$.next($index)"
							/>
						</div>
					}
				</div>
			}
			<div class="row">
				@if (itemTitle()) {
					<div class="header__title">
						{{ itemTitle() | translate }}
						@if (rows.length > 1) {
							#{{ $index + 1 }}
						}

						@if ($index > 0) {
							<div class="header__remove-icon">
								<a [ngClass]="{ disabled }" (click)="!disabled && removeRow$.next($index)">{{
									'ç.misc.action.Remove' | translate
								}}</a>
							</div>
						}
					</div>
				}
				<oper-client-dynamic-form
					class="row__form"
					[formConfiguration]="row"
					(valueChange)="formChanged$.next({ index: $index, form: $event })"
				/>
			</div>
		</div>
	}
</div>

@if (!disabled) {
	<button class="add-row-button" [disabled]="hasInvalidForm$ | async" (click)="addRow.emit()">
		<oper-client-fontawesome-icon [icon]="'faPlus'" />
		{{ addItemLabel | translate }}
	</button>
}
