import { DynamicFormCard, DynamicInputFormItems, InputField, InputSelect, PercentageInputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { orderBy } from '@oper-client/shared/util-array';
import { BorrowerSimulationDto, LoanPurposeEnums, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { distinctUntilChanged, map, Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { hideWhenFieldIsFalse } from '../utils/dynamic-form.utils';
import { FormConfiguration } from '../models/dynamic-form.model';
import { debounceTimes } from '@oper-client/shared/configuration';

export default function (formData?: Partial<BorrowerSimulationDto>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const loanPurpose = (formData?.loanPurpose?.definition as string) ?? 'default';

	switch (loanPurpose) {
		case LoanPurposeEnums.PURCHASE:
			return getFormConfigurationForProjectPurposePurchase(formData, resources);
		case LoanPurposeEnums.NEW_BUILD:
			return getFormConfigurationForProjectPurposeNewBuild(formData, resources);
		case LoanPurposeEnums.RENOVATION:
			return getFormConfigurationForProjectPurposeRenovation(formData, resources);
		case LoanPurposeEnums.REFINANCE:
			return getFormConfigurationForProjectPurposeRefinance(formData, resources);
		case LoanPurposeEnums.BUY_OUT:
			return getFormConfigurationForProjectPurposeBuyOut(formData, resources);
		default:
			return [];
	}
}

function getFormConfigurationForProjectPurposePurchase(
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const renovationCostForm = new FormConfiguration();
	renovationCostForm.setName('renovationCostsForm');

	renovationCostForm.formControl.questions = [
		new InputField({
			key: 'type.id',
			type: 'hidden',
			value: resources?.[ResourceType.RENOVATION_TYPE]?.find((item) => item.definition === 'other')?.id ?? 1,
		}),
		new InputField({
			key: 'amount',
			label: 'ç.feature.realty.renovation.singular',
			value: formData?.renovationCost?.amount ?? 0,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.propertyPrice.label',
			value: formData?.realtyPrice || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputSelect({
			key: 'realtyUsageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.realtyUsageType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputSelect({
			key: 'purchaseSaleType.id',
			label: 'ç.question.typeOfSale.label',
			value: formData?.purchaseSaleType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.PURCHASE_SALE_TYPE],
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScore.label',
			value: formData?.epcBeforeRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			hideRequiredAsterisk: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new DynamicFormCard({
			title: 'ç.question.isMarkedForRenovation.label',
			key: 'renovationCost',
			value: formData?.renovationCost,
			formConfiguration: renovationCostForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.isMarkedForRenovation.label',
			secondaryAction: true,
			forceExpand: formData?.renovationCost?.amount > 0,
			required: false,
		}),
		new InputField({
			key: 'renovationPurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.RENOVATION).id ?? null,
		}),
		new InputField({
			key: 'refinancePurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.REFINANCE).id ?? null,
		}),
	];
}

function getFormConfigurationForProjectPurposeNewBuild(
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'region.id',
			label: 'ç.question.projectLocation.label',
			value: formData?.region?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REGION] || [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'realtyUsageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.realtyUsageType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'priceBuilding',
			label: 'ç.question.constructionCostsExcludingVAT.label',
			value: formData?.priceBuilding || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'architectFees',
			label: 'ç.question.architectFees.label',
			value: formData?.architectFees || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.newBuildEpcScore.label',
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			hideRequiredAsterisk: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'isBuyingLand',
			label: 'ç.question.isBuyingLand.label',
			value: formData?.isBuyingLand || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const key = 'isBuyingLand';
				const conditionFieldKey = 'hasExistingLoanOnLand';
				const isConditionFieldChecked = !!formGroup.value[conditionFieldKey];
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === key);
				const formControl = <FormControl>formGroup.controls[key];
				!isConditionFieldChecked ? formControl.enable() : formControl.disable();

				return formGroup.controls[conditionFieldKey].valueChanges.pipe(
					distinctUntilChanged(),
					map((isChecked) => {
						!isChecked ? formControl.enable() : formControl.disable();
						return inputField;
					})
				);
			},
		}),
		new InputSelect({
			key: 'landPurchaseType.id',
			label: 'ç.question.typeOfPurchase.label',
			value: formData?.landPurchaseType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.LAND_PURCHASE_TYPE],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isBuyingLand'),
		}),
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceOfTheLand.label',
			value: formData?.priceOfLand || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isBuyingLand'),
		}),
		new InputField({
			key: 'hasExistingLoanOnLand',
			label: 'ç.question.hasExistingLoanOnLand.label',
			value: formData?.hasExistingLoanOnLand || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string): Observable<InputField> => {
				const conditionFieldKey = 'isBuyingLand';
				const isConditionFieldChecked = !!formGroup.value[conditionFieldKey];
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === key);
				const formControl = <FormControl>formGroup.controls[key];
				!isConditionFieldChecked ? formControl.enable() : formControl.disable();

				return formGroup.controls[conditionFieldKey].valueChanges.pipe(
					distinctUntilChanged(),
					map((isChecked) => {
						!isChecked ? formControl.enable() : formControl.disable();
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'outstandingLoanAmount',
			label: 'ç.question.outstandingLoanAmount.label',
			value: formData?.outstandingLoanAmount || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoanOnLand'),
		}),
		new InputField({
			key: 'priceOfLandUnderLoan',
			label: 'ç.question.priceOfLandUnderLoan.label',
			value: formData?.priceOfLandUnderLoan || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
			disclaimer: 'ç.question.priceOfLandUnderLoan.disclaimer',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasExistingLoanOnLand'),
		}),
		new InputField({
			key: 'refinancePurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.REFINANCE).id ?? null,
		}),
	];
}

function getFormConfigurationForProjectPurposeRenovation(
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'realtyUsageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.realtyUsageType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.venalValueBeforeRenovation.label',
			value: formData?.venalValueBefore,
			type: 'number',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'renovationPurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.RENOVATION).id ?? null,
		}),
		new InputField({
			key: 'refinancePurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.REFINANCE).id ?? null,
		}),
		new InputField({
			key: 'renovationCost.type.id',
			type: 'hidden',
			value: resources?.[ResourceType.RENOVATION_TYPE]?.find((item) => item.definition === 'other')?.id ?? 1,
		}),
		new InputField({
			key: 'renovationCost.amount',
			label: 'ç.question.renovationCosts.label',
			value: formData?.renovationCost?.amount,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScoreBeforeRenovation.label',
			value: formData?.epcBeforeRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			hideRequiredAsterisk: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
	];
}

function getFormConfigurationForProjectPurposeRefinance(
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputField({
			value: 0,
			type: 'hidden',
			key: 'realtyPrice',
		}),
		new InputSelect({
			key: 'realtyUsageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.realtyUsageType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'venalValueAfter',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.venalValueAfter || null,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new DynamicInputFormItems({
			key: 'tranches',
			value: formData.tranches,
			debounceTime: debounceTimes.xxs,
			itemTitle: 'ç.misc.tranche',
			addItemLabel: 'ç.misc.action.addTranche',
			required: true,
			items: [
				new InputField({
					key: 'originalLoanAmount',
					label: 'ç.feature.mortgageSimulator.originalLoanAmount',
					type: 'number',
					required: true,
					currency: true,
					class: 'span12',
					hideRequiredAsterisk: true,
				}),
				new InputField({
					key: 'outstandingBalance',
					label: 'ç.question.refinancingOutstandingBalance.label',
					type: 'number',
					required: true,
					currency: true,
					class: 'span12',
					hideRequiredAsterisk: true,
				}),
				new PercentageInputField({
					key: 'interestRate',
					label: 'ç.feature.mortgageSimulator.interestRateOnThisLoan',
					required: true,
					type: 'number',
					class: 'span12',
					hideRequiredAsterisk: true,
					validators: [Validators.min(0), Validators.max(100)],
				}),
			],
		}),
	];
}

function getFormConfigurationForProjectPurposeBuyOut(
	formData?: Partial<BorrowerSimulationDto>,
	resources?: PartialNormalizedResource
): InputBase<any>[] {
	const renovationCostForm = new FormConfiguration();
	renovationCostForm.setName('renovationCostsForm');

	renovationCostForm.formControl.questions = [
		new InputField({
			key: 'type.id',
			type: 'hidden',
			value: resources?.[ResourceType.RENOVATION_TYPE]?.find((item) => item.definition === 'other')?.id ?? 1,
		}),
		new InputField({
			key: 'amount',
			label: 'ç.feature.realty.renovation.singular',
			value: formData?.renovationCost?.amount ?? 0,
			type: 'text',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	return [
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'realtyUsageType.id',
			label: 'ç.question.propertyWillBeUsedFor.label',
			value: formData?.realtyUsageType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputField({
			key: 'buyOutAmount',
			label: 'ç.question.amountIntendedToBuyOut.label',
			value: formData?.buyOutAmount || null,
			type: 'number',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.originalPropertyPrice.label',
			value: formData?.venalValueBefore,
			type: 'number',
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcScore.label',
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			hideRequiredAsterisk: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new DynamicFormCard({
			title: 'ç.question.isMarkedForRenovation.label',
			key: 'renovationCost',
			value: formData?.renovationCost,
			formConfiguration: renovationCostForm,
			showDeleteButton: true,
			secondaryActionLabel: 'ç.question.isMarkedForRenovation.label',
			secondaryAction: true,
			forceExpand: formData?.renovationCost?.amount > 0,
			required: false,
		}),
		new InputField({
			key: 'renovationPurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.RENOVATION).id ?? null,
		}),
		new InputField({
			key: 'refinancePurpose.id',
			type: 'hidden',
			value:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((item) => item.definition === LoanPurposeEnums.REFINANCE).id ?? null,
		}),
	];
}
