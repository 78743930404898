import { DataConnection, Integrator, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { InputBase } from '../../../models/input-base.model';
import { InputSelect } from '../../../models/input-types.model';

export default function (
	formData: Partial<DataConnection>,
	resources: PartialNormalizedResource,
	integrators: Array<Integrator>
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'integrator.id',
			label: 'ç.question.searchForLender.title',
			options: integrators.map((_) => ({ ..._, key: `ç.resource.integrator.${_['name']}` })),
			value: integrators?.[0]?.id,
			class: 'span12',
			searchable: true,
			required: true,
			order: 1,
		}),
	];
}
