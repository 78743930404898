import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	creditLiabilityDefinitions,
	Liability,
	nonCreditLiabilityDefinitions,
	parseActionState,
	ParsedActionState,
} from '@oper-client/shared/data-model';

import { ClientPartialFeatureState } from '../client.reducer';
import { ClientFacade } from '../client/client.facade';
import * as LiabilitySelectors from './liability.selectors';
import * as LiabilityActions from './liability.actions';
import { LiabilityActionTypes } from './liability.reducer';

@Injectable()
export class LiabilityFacade {
	constructor(
		private store: Store<ClientPartialFeatureState>,
		private clientFacade: ClientFacade
	) {}

	public createLiabilityActionState$ = this.selectActionState('createLiability');
	public updateLiabilityActionState$ = this.selectActionState('updateLiability');
	public removeLiabilityActionState$ = this.selectActionState('removeLiability');
	public loadLiabilitiesActionState$ = this.selectActionState('loadLiabilities');
	public calculateAmortizationActionState$ = this.selectActionState('calculateAmortization');

	// Liabilities
	public liabilities$ = this.store.pipe(select(LiabilitySelectors.getLiabilities));
	public triggerCalculationActionState$ = this.selectActionState('triggerConsultations');
	public consultations$ = this.store.pipe(select(LiabilitySelectors.selectConsultations));
	public nonCreditLiabilities$ = this.store.pipe(
		select(LiabilitySelectors.getLiabilities),
		map((liabilities) => liabilities.filter((item) => nonCreditLiabilityDefinitions.includes(item.liabilityType.definition)))
	);
	public creditLiabilities$ = this.store.pipe(
		select(LiabilitySelectors.getLiabilities),
		map((liabilities) => liabilities.filter((item) => creditLiabilityDefinitions.includes(item.liabilityType.definition)))
	);
	public currentClientNonCreditLiabilities$ = combineLatest([this.clientFacade.currentClientId$, this.nonCreditLiabilities$]).pipe(
		map(([clientId, liabilities]) => liabilities.filter((liability) => liability.client.id === clientId))
	);
	public currentClientCreditLiabilities$ = combineLatest([this.clientFacade.currentClientId$, this.creditLiabilities$]).pipe(
		map(([clientId, liabilities]) => liabilities.filter((liability) => liability.client.id === clientId))
	);
	public creditsFromBecris$ = combineLatest([this.clientFacade.currentClientId$, this.consultations$]).pipe(
		map(([clientId, credits]) => {
			if (Array.isArray(credits)) {
				return credits
					.filter((credit) => credit.client.id === clientId)
					.map((credit) => ({
						...credit,
						instruments: [...credit.instruments].sort((a, b) => +new Date(a.firstTermDate) - +new Date(b.firstTermDate)),
					}));
			}

			return null;
		})
	);
	public readonly calculatedAmortization$ = this.store.pipe(select(LiabilitySelectors.getCalculatedAmortization));

	public mainClientLiabilities$ = combineLatest([this.clientFacade.mainClientId$, this.liabilities$]).pipe(
		map(([mainClientId, incomes]) => incomes.filter((income) => income.client.id === mainClientId))
	);
	public secondClientLiabilities$ = combineLatest([this.clientFacade.secondClientId$, this.liabilities$]).pipe(
		map(([secondClientId, incomes]) => incomes.filter((income) => income.client.id === secondClientId))
	);

	// Liabilities
	// Possible extensions
	// loadLiabilitiesForLoanRequest
	// getLiabilitiesForLoanRequest - credit/noncredit
	public loadLiability(loanRequestId: number, clientId: number, liabilityId: number) {
		this.store.dispatch(LiabilityActions.loadLiability({ loanRequestId, clientId, liabilityId }));
	}

	public loadLiabilities(loanRequestId: number, clientId: number) {
		this.store.dispatch(LiabilityActions.loadLiabilities({ loanRequestId, clientId }));
	}

	public createLiability(loanRequestId: number, clientId: number, liability: Partial<Liability>) {
		this.store.dispatch(LiabilityActions.addLiability({ loanRequestId, clientId, liability }));
	}

	public updateLiability(loanRequestId: number, clientId: number, liabilityId: number, liability: Partial<Liability>) {
		this.store.dispatch(
			LiabilityActions.updateLiability({ loanRequestId, clientId, liability: { id: liabilityId, changes: liability } })
		);
	}

	public deleteLiability(loanRequestId: number, clientId: number, liabilityId: number) {
		this.store.dispatch(LiabilityActions.deleteLiability({ loanRequestId, clientId, id: liabilityId }));
	}

	public getLiabilitiesForLoanRequest(loanRequestId: number) {
		return combineLatest([this.clientFacade.getClientsOfLoanRequest(loanRequestId), this.liabilities$]).pipe(
			map(([clients, liabilities]) =>
				liabilities.filter((liability) => clients.map((client) => client.id).includes(liability.client.id))
			)
		);
	}

	public getCreditLiabilitiesForLoanRequest(loanRequestId: number) {
		return this.getLiabilitiesForLoanRequest(loanRequestId).pipe(
			map((liabilities) => liabilities?.filter((item) => creditLiabilityDefinitions.includes(item.liabilityType.definition)))
		);
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: LiabilityActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(LiabilitySelectors.getActionState(actionType)), map(parseActionState));
	}
}
