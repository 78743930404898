import { FormGroup } from '@angular/forms';
import { LiabilityType, LoanPurposeEnums, PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { DynamicInputTable, InputField, InputSelect, showOrHideField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import { of } from 'rxjs';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const loanPurposesSet = new Set(formData?.loanPurposes?.map((purpose) => purpose.definition));
	const hasRenovationTypePurpose =
		loanPurposesSet.has(LoanPurposeEnums.RENOVATION) ||
		loanPurposesSet.has(LoanPurposeEnums.MODERNIZATION) ||
		loanPurposesSet.has(LoanPurposeEnums.EXTENSION);

	const isFairMarketValueShown =
		loanPurposesSet.has(LoanPurposeEnums.REFINANCE) || loanPurposesSet.has(LoanPurposeEnums.FREE_USE) || hasRenovationTypePurpose;

	const hasPurchaseAndRenovationType = loanPurposesSet.has(LoanPurposeEnums.PURCHASE) && hasRenovationTypePurpose;

	const isRealtyPriceShown = loanPurposesSet.has(LoanPurposeEnums.PURCHASE) && !isFairMarketValueShown;

	const fairMarketValue = Math.max(formData?.fairMarketValue ?? 0, formData?.realtyPrice ?? 0);

	const mortgageImmovable: LiabilityType = 'mortgageImmovable';
	const liabilityType = resources?.[ResourceType.LIABILITY_TYPE]?.find((item) => item.definition === mortgageImmovable);

	const questions: InputBase<any>[] = [
		new InputField({
			key: 'defaultRefinanceLiabilityType',
			type: 'hidden',
			value: liabilityType,
			required: false,
		}),
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.fairMarketValue.label',
			value: isRealtyPriceShown || hasPurchaseAndRenovationType ? fairMarketValue : 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
				showOrHideField(formGroup, formConfiguration, key, 'number', isRealtyPriceShown, false),
		}),
		new InputField({
			key: 'fairMarketValue',
			label: 'ç.question.fairMarketValue.label',
			value: isFairMarketValueShown ? fairMarketValue : 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
				showOrHideField(formGroup, formConfiguration, key, 'number', isFairMarketValueShown, false),
		}),
		new InputField({
			key: 'freeUseMortgage',
			label: 'ç.resource.additional-financing-need-type.freePurposeMortgage',
			value: loanPurposesSet.has(LoanPurposeEnums.FREE_USE) ? formData?.freeUseMortgage : 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
				showOrHideField(formGroup, formConfiguration, key, 'number', loanPurposesSet.has(LoanPurposeEnums.FREE_USE), false),
		}),
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceLand.label',
			value: loanPurposesSet.has(LoanPurposeEnums.NEW_BUILD) ? formData?.priceOfLand : 0,
			type: 'number',
			readonly: readonlyMode,
			required: false,
			currency: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
				showOrHideField(formGroup, formConfiguration, key, 'number', loanPurposesSet.has(LoanPurposeEnums.NEW_BUILD), false),
		}),
		new InputField({
			key: 'buildingCosts',
			label: 'ç.question.priceBuilding.label',
			value: loanPurposesSet.has(LoanPurposeEnums.NEW_BUILD) ? formData?.buildingCosts : 0,
			type: 'number',
			readonly: readonlyMode,
			required: false,
			currency: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
				showOrHideField(formGroup, formConfiguration, key, 'number', loanPurposesSet.has(LoanPurposeEnums.NEW_BUILD), false),
		}),
		new InputField({
			key: 'refinancingAmount',
			label: `ç.question.${loanPurposesSet.size === 1 ? 'refinancingOutstandingBalance' : 'refinancingAmount'}.label`,
			helpText: of(
				`ç.feature.mortgageSimulator.${loanPurposesSet.size === 1 ? 'refinancingOutstandingBalance' : 'refinancingAmount'}.helpText`
			),
			value: loanPurposesSet.has(LoanPurposeEnums.REFINANCE) ? formData?.refinancingAmount : 0,
			type: 'number',
			currency: true,
			readonly: readonlyMode,
			required: false,
			class: 'span12',
			order: loanPurposesSet.size === 1 ? 2 : 3,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
				showOrHideField(formGroup, formConfiguration, key, 'number', loanPurposesSet.has(LoanPurposeEnums.REFINANCE), false),
		}),
	];

	if (resources?.[ResourceType.RENOVATION_TYPE]?.length === 1) {
		const renovationTypeId = resources?.[ResourceType.RENOVATION_TYPE]?.find((item) => item.definition === 'generic')?.id;

		questions.push(
			new InputField({
				key: 'renovationCosts[0].type.id',
				type: 'hidden',
				value: renovationTypeId,
			}),

			new InputField({
				key: 'renovationCosts[0].amount',
				label: 'ç.feature.realty.renovation.singular',
				value: hasRenovationTypePurpose ? formData?.renovationCosts[0]?.amount ?? 0 : 0,
				type: 'number',
				readonly: readonlyMode,
				required: false,
				currency: true,
				order: 1,
				class: 'span12',
				transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
					showOrHideField(formGroup, formConfiguration, key, 'number', hasRenovationTypePurpose, false),
			})
		);
	} else {
		questions.push(
			new DynamicInputTable({
				key: 'renovationCosts',
				value: hasRenovationTypePurpose ? formData?.renovationCosts : [],
				debounceTime: debounceTimes.s,
				required: false,
				addRowLabel: 'ç.feature.mortgageSimulator.addRenovation',
				label: 'ç.feature.mortgageSimulator.addRenovation',
				disabled: readonlyMode,
				order: 1,
				columns: [
					new InputSelect({
						key: 'type.id',
						required: true,
						clearable: true,
						disabled: readonlyMode,
						label: 'ç.question.renovationType.label',
						validators: [],
						options: resources?.[ResourceType.RENOVATION_TYPE] || [],
						alreadySorted: true,
						class: 'span8',
					}),
					new InputField({
						key: 'amount',
						type: 'number',
						disabled: readonlyMode,
						label: 'ç.question.renovationAmount.label',
						required: true,
						currency: true,
						class: 'span4',
					}),
				],
				transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration, key: string) =>
					showOrHideField(formGroup, formConfiguration, key, 'table', hasRenovationTypePurpose, false),
			})
		);
	}

	return questions;
}
