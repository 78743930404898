import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ConfigurationPortalBaseProduct, ConfigurationPortalProductType } from '@oper-client/shared/data-model';
import { CreditProvider, Funder, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';

export default function (
	formData?: ConfigurationPortalBaseProduct,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[],
	funders?: Funder[],
	productTypes?: ConfigurationPortalProductType[]
): InputBase<any>[] {
	return [
		new InputField({
			key: 'name',
			label: 'ç.feature.offer.product.productCode',
			value: formData?.name || '',
			type: 'text',
			class: 'span12',
			validators: [Validators.maxLength(64)],
		}),
		new InputField({
			key: 'active',
			label: 'ç.feature.pricing.active',
			value: formData?.active,
			type: 'checkbox',
			class: 'span12',
		}),
		new InputSelect({
			key: 'creditProvider.configurationId',
			label: 'ç.question.creditProvider.label',
			value: formData?.creditProvider?.configurationId,
			options: creditProviders?.map((creditProvider) => ({
				id: creditProvider.configurationId,
				key: creditProvider.name,
			})),
			class: 'span12',
		}),
		new InputSelect({
			key: 'funder.configurationId',
			label: 'ç.question.funder.label',
			value: formData?.funder?.configurationId,
			options: funders?.map((funder) => ({
				id: funder.configurationId,
				key: funder.name,
			})),
			class: 'span12',
		}),
		new InputSelect({
			key: 'productType.configurationId',
			label: 'ç.feature.offer.product.productType',
			value: formData?.productType.configurationId,
			options: orderBy(
				productTypes?.map((productType) => ({
					id: productType.configurationId,
					key: `ç.resource.product-type.${productType.summary}`,
					order: productType.order,
				})),
				'order'
			),
			class: 'span12',
		}),
		new InputField({
			key: 'logoUrl',
			label: 'ç.feature.offer.product.logoUrl',
			value: formData?.logoUrl || '',
			type: 'text',
			class: 'span12',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator('^(https?:\\/\\/)?([\\w\\d\\-\\.]+)\\.([a-z]{2,})(\\/\\S*)?$', 'logoUrl'),
			],
		}),
		new InputField({
			key: 'isSalesAccessRestricted',
			label: 'ç.feature.product.isSalesAccessRestricted',
			value: formData?.isSalesAccessRestricted,
			type: 'checkbox',
			class: 'span12',
		}),
		new InputField({
			key: 'isSimulatorReferenceProduct',
			label: 'ç.configurationPortal.product.isSimulatorReferenceVariability',
			value: formData?.isSimulatorReferenceProduct,
			type: 'checkbox',
			class: 'span12',
		}),
	];
}
