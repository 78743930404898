import { PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';
import { of } from 'rxjs';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const questions = [
		new InputSelect({
			key: 'realtyUsageType.id',
			label: 'ç.feature.mortgageSimulator.realtyUsageType.label',
			value: formData?.realtyUsageType?.id,
			helpText: of('ç.feature.mortgageSimulator.realtyUsageType.helpText'),
			required: false,
			clearable: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE] || [],
			disabled: readonlyMode,
			class: 'span12',
		}),
		new InputField({
			key: Simulator.RealtyFormKeysEnum.REALTY_EPC_BEFORE,
			label: 'ç.feature.mortgageSimulator.epcBeforeRenovations.label',
			value: formData?.epcBeforeRenovations,
			type: 'number',
			required: false,
			disabled: readonlyMode,
			class: 'span6',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
		}),
		new InputField({
			key: Simulator.RealtyFormKeysEnum.REALTY_EPC_AFTER,
			label: 'ç.feature.mortgageSimulator.epcAfterRenovations.label',
			value: formData?.epcAfterRenovations,
			type: 'number',
			required: false,
			disabled: readonlyMode,
			class: 'span6',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
		}),
		new InputField({
			key: 'collaterals[0].collateralAmount',
			label: 'ç.feature.mortgageSimulator.collateralAmount',
			helpText: of('ç.feature.mortgageSimulator.collateralAmountTooltip'),
			value: formData?.collaterals?.[0]?.collateralAmount ?? 0,
			type: 'number',
			disabled: readonlyMode,
			currency: true,
			required: false,
			class: 'span12',
			secondaryAction: true,
			secondaryActionLabel: 'ç.feature.mortgageSimulator.collateralAmount.secondaryActionLabel',
		}),
	];

	return questions;
}
