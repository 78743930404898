@if (isQuestionExpanded) {
	<div #questionContainer *ngIf="form.controls[question.key] && question['type'] !== 'hidden'" [formGroup]="form">
		<div
			class="form-item"
			[class.form-item--inlined]="question.labelPlacement === 'inline' || question.type === 'checkbox' || question.type === 'toggle'"
			[class.form-item--slim]="question.labelPlacement === 'slim'"
			[class.form-item--checkbox]="question.type === 'checkbox'"
			[class]="question.questionClass ?? ''"
		>
			<ng-container [ngSwitch]="question.controlType">
				<!-- input -->
				<ng-container *ngIf="question.type !== 'checkbox'; else checkboxInputTpl">
					<div *ngIf="!!question.label" class="label">
						@if (question.type !== 'table' && question.type !== 'formItems') {
							<label class="form-item-label" [attr.for]="formControlId">
								{{ question.label | translate }} <sup *ngIf="shouldShowAsterisk()">*</sup>
							</label>
						}
						<ng-container *ngIf="question.helpText | async as helpText">
							<oper-client-tooltip-indicator
								*ngIf="overlayService.isModalClosed$ | async"
								[text]="helpText | translate"
								[parentContainer]="questionContainer"
							></oper-client-tooltip-indicator>
							<fa-icon
								*ngIf="overlayService.isModalOpen$ | async"
								class="help-text-icon"
								[tooltip]="helpText | translate"
								[tooltip-class]="'oper-tooltip'"
								[placement]="'right'"
								[fixedWidth]="true"
								[icon]="questionIcon"
							></fa-icon>
						</ng-container>
					</div>
					<ng-container *ngSwitchCase="'field'">
						<ng-container *ngIf="!question.multiline">
							<div
								*ngIf="question.type === 'date'"
								class="input-group"
								[ngClass]="{ 'input-group--disabled': question.disabled }"
							>
								<ng-container *ngIf="isMobile; else desktop">
									<input
										class="input-group__form-item-input"
										#dateInput
										type="date"
										[id]="formControlId"
										[name]="question.label"
										[required]="question.required"
										[attr.disabled]="question.disabled ? '' : null"
										[formControlName]="question.key"
										[min]="minDate"
										[max]="maxDate"
										(change)="onTextChange($event?.target?.value)"
										(blur)="onBlur()"
										(focus)="onFocus()"
										(click)="dateInputClick()"
									/>
									<div class="input-group-addition input-group-addition--append" (click)="dateInputClick()">
										<fa-icon [icon]="faCalendar"></fa-icon>
									</div>
								</ng-container>

								<ng-template #desktop>
									<mat-datepicker #picker></mat-datepicker>
									<input
										class="input-group__form-item-input"
										matInput
										[id]="formControlId"
										[name]="question.label"
										[placeholder]="mask"
										[matDatepicker]="picker"
										[min]="minDate"
										[max]="maxDate"
										[value]="question.value"
										[required]="question.required"
										[attr.disabled]="question.disabled ? '' : null"
										(dateChange)="datePickerChanged($event)"
									/>

									<div
										*ngIf="!!form?.controls[question?.key]?.value"
										class="input-group-addition input-group-addition--append cursor-pointer clear-icon"
										(click)="datePickerChanged(null)"
									>
										<span class="clear-icon-wrapper" [title]="'ç.misc.action.clear' | translate">
											<span aria-hidden="true" class="clear-icon">&#215;</span>
										</span>
									</div>
									<div class="input-group-addition input-group-addition--append cursor-pointer" (click)="picker.open()">
										<fa-icon [icon]="faCalendar"></fa-icon>
									</div>
								</ng-template>
							</div>
							<oper-client-input-password
								*ngIf="question.type === 'password'"
								[formControlName]="question.key"
								[name]="question.label"
								[key]="question.key"
								[required]="question.required"
								[disableInput]="question.disabled ? '' : null"
								[value]="question.value"
								(focusEmit)="onFocus()"
								(blurEmit)="onBlur()"
								(valueChange)="onTextChange($event)"
								[revealPassword]="question.revealPassword"
								[showPasswordStrength]="question.showPasswordStrength"
								[immediatePasswordCheck]="question.immediatePasswordCheck"
							></oper-client-input-password>
							<div
								class="input-group-container"
								[class.input-group-container--additional-disclaimer]="question.additionalDisclaimer"
							>
								<div
									*ngIf="question.type !== 'date' && !question.currency && question.type !== 'password'"
									class="input-group"
									[ngClass]="{ 'input-group--disabled': question.disabled }"
								>
									<div
										class="input-group-addition input-group-addition--prepend input-group-addition--prefix"
										*ngIf="question.prefix"
									>
										{{ question.prefix }}
									</div>
									<input
										*ngIf="question.type === 'number'; else defaultInput"
										class="input-group__form-item-input"
										inputmode="decimal"
										operClientNumberToDecimalDirective
										[options]="defaultDecimalInputOptions$ | async"
										[ngClass]="{ 'input-group__form-item-input--disabled': question.disabled }"
										[type]="'text'"
										[id]="formControlId"
										[name]="question.label"
										[placeholder]="question.placeholder ? (question.placeholder | translate) : ''"
										[required]="question.required"
										[attr.disabled]="question.disabled ? '' : null"
										[formControlName]="question.key"
										[maxlength]="question.maxlength"
										(ngModelChange)="onTextChange($event)"
										(blur)="onBlur()"
										(focus)="onFocus()"
									/>
									<ng-template #defaultInput>
										<input
											*ngIf="!question.onlyLetters; else onlyLetter"
											class="input-group__form-item-input"
											[ngClass]="{ 'input-group__form-item-input--disabled': question.disabled }"
											[type]="question.type"
											[id]="formControlId"
											[name]="question.label"
											[placeholder]="question.placeholder ? (question.placeholder | translate) : ''"
											[attr.inputmode]="question.type"
											[required]="question.required"
											[attr.disabled]="question.disabled ? '' : null"
											[formControlName]="question.key"
											[min]="question.min"
											[max]="question.max"
											[maxlength]="question.maxlength"
											(ngModelChange)="onTextChange($event)"
											(blur)="onBlur()"
											(focus)="onFocus()"
											operClientOnlyNumbers
											[apply]="question.onlyNumbers"
										/>
										<ng-template #onlyLetter>
											<input
												class="input-group__form-item-input"
												[ngClass]="{ 'input-group__form-item-input--disabled': question.disabled }"
												[type]="question.type"
												[id]="formControlId"
												[name]="question.label"
												[placeholder]="question.placeholder ? (question.placeholder | translate) : ''"
												[attr.inputmode]="question.type"
												[required]="question.required"
												[attr.disabled]="question.disabled ? '' : null"
												[formControlName]="question.key"
												[min]="question.min"
												[max]="question.max"
												[maxlength]="question.maxlength"
												operClientOnlyLettersDirective
												(ngModelChange)="onTextChange($event)"
												(blur)="onBlur()"
												(focus)="onFocus()"
											/>
										</ng-template>
									</ng-template>
									<div
										class="input-group-addition input-group-addition--prepend input-group-addition--suffix"
										*ngIf="question.suffix"
									>
										{{ question.suffix | translate }}
									</div>
								</div>
								<div
									class="input-group"
									[ngClass]="{ 'input-group--disabled': question.disabled || question.readonly }"
									*ngIf="question.currency"
								>
									<div class="input-group-addition input-group-addition--prepend input-group-addition--prefix">
										<div class="currency-symbol">
											{{ environmentCurrencySymbol }}
										</div>
									</div>
									<input
										class="input-group__form-item-input"
										inputmode="decimal"
										operClientNumberToDecimalDirective
										[options]="defaultCurrencyInputOptions$ | async"
										[type]="'text'"
										[id]="formControlId"
										[name]="question.label"
										[placeholder]="question.placeholder ? (question.placeholder | translate) : ''"
										[required]="question.required"
										[attr.disabled]="question.disabled ? '' : null"
										[formControlName]="question.key"
										[attr.readonly]="question.readonly ? '' : null"
										[autocomplete]="'off'"
										(ngModelChange)="onTextChange($event)"
										(blur)="onBlur()"
										(focus)="onFocus()"
										(keydown)="onKeydown()"
									/>

									<div
										class="input-group-addition input-group-addition--prepend input-group-addition--suffix"
										*ngIf="question.suffix"
									>
										{{ question.suffix | translate }}
									</div>

									<div
										*ngIf="
											question.additionalFlagIcon &&
											((question.additionalFlagValue | async) === true ||
												(question.additionalFlagValue | async) === false)
										"
										class="input-group-addition input-group-addition--append"
										[ngClass]="{
											'input-group-addition--active': question.additionalFlagValue | async,
											'input-group-addition--inactive': (question.additionalFlagValue | async) === false,
											'--clickable': question.additionalFlagClickable | async
										}"
										(click)="question.onAdditionalFlagClicked()"
									>
										<fa-icon class="icon" [icon]="question.additionalFlagIcon"></fa-icon>
									</div>
								</div>
								<span class="additional-disclaimer" *ngIf="question.additionalDisclaimer">
									{{ question.additionalDisclaimer | translate }}
								</span>
								<button
									class="external-action-button"
									*ngIf="question.externalActionButtonIcon"
									[attr.disabled]="question.disabled || (question.externalActionButtonDisabled | async) ? '' : null"
									(click)="externalActionClick.emit({ questionKey: question.key })"
								>
									<fa-icon [icon]="question.externalActionButtonIcon" [fixedWidth]="true"></fa-icon>
								</button>
							</div>
						</ng-container>
						<span class="additional-label" *ngIf="question.additionalLabel">
							{{ question.additionalLabel | translate }}
						</span>
						<ng-container *ngIf="question.multiline">
							<textarea
								class="form-item-textarea"
								(blur)="onBlur()"
								(focus)="onFocus()"
								[id]="formControlId"
								[formControlName]="question.key"
								[required]="question.required"
								[attr.disabled]="question.disabled ? '' : null"
								min="0"
								rows="4"
								(ngModelChange)="onTextChange($event)"
							>
							</textarea>
						</ng-container>
					</ng-container>
				</ng-container>

				<!-- Needs to be placed exactly here. See https://github.com/angular/angular/issues/13761 -->
				<!-- checkbox -->
				<ng-template #checkboxInputTpl>
					<div class="checkbox-container">
						<label
							for="{{ formControlId }}"
							class="form-item-label checkbox-container--shrink"
							[ngClass]="{ 'cursor-pointer': !question?.disabled }"
							[innerHTML]="question.label | translate"
							(click)="onLabelLinkClick($event)"
							(click)="onLabelClick($event)"
						></label>
						<ng-container *ngIf="question.helpText | async as helpText">
							<oper-client-tooltip-indicator
								*ngIf="overlayService.isModalClosed$ | async"
								[text]="helpText | translate"
								[parentContainer]="questionContainer"
								class="help-text-icon"
							></oper-client-tooltip-indicator>
							<fa-icon
								*ngIf="overlayService.isModalOpen$ | async"
								class="help-text-icon"
								[tooltip]="helpText | translate"
								[tooltip-class]="'oper-tooltip'"
								[placement]="'right'"
								[fixedWidth]="true"
								[icon]="questionIcon"
							></fa-icon>
						</ng-container>
						<oper-client-checkbox
							class="checkbox cursor-pointer"
							[id]="formControlId"
							[disabled]="question?.disabled"
							[size]="'sm'"
							[required]="question.required"
							[attr.disabled]="question.disabled ? '' : null"
							[value]="question.value"
							[formControlName]="question.key"
							(clicked)="onTextChange($event)"
						/>
					</div>
				</ng-template>

				<!-- select -->
				<ng-container *ngSwitchCase="'select'">
					@if (question.beta) {
						<oper-client-dropdown
							[formName]="formName"
							[formControlName]="question.key"
							[label]="question.defaultLabel | translate"
							[disabled]="question.disabled || question?.locked"
							[clearable]="question.clearable"
							[options]="question.options"
							[bindValue]="question.bindValue"
							[multiselect]="question.multiple"
							[value]="question.value"
							(valueChange)="onSelectionChange()"
						/>
					} @else {
						<oper-client-select
							data-test="select--modalInformations"
							[disabled]="question.disabled || question?.locked"
							[options]="question.options"
							[bindValue]="question.bindValue"
							[placeholder]="question.defaultLabel | translate"
							[formControlName]="question.key"
							[alreadySorted]="question.alreadySorted"
							[clearable]="question.clearable"
							[multiple]="question.multiple"
							[appendTo]="question.appendTo || null"
							[searchable]="question.searchable"
							[searchFn]="question.searchFn"
							[prefillDefaultValue]="question.prefillDefaultValue"
							(selectedValueChange)="onSelectionChange()"
							[formName]="formName"
							[bindLabel]="question.bindLabel ?? 'translatedKey'"
						/>
					}
				</ng-container>

				<ng-container *ngSwitchCase="'radio'">
					<oper-client-radio-group
						[formControlName]="question.key"
						[options]="question.options"
						[disableInput]="question.disabled"
						[withItemBorder]="question.withItemBorder"
						[value]="question.value"
						(valueChange)="onRadioSelectChange($event)"
					>
					</oper-client-radio-group>
				</ng-container>

				<!-- switch -->
				<ng-container *ngSwitchCase="'switch'">
					<oper-client-button-switch
						*ngIf="question.type === 'button'"
						[formId]="formId"
						[type]="question.type"
						[formControlName]="question.key"
						[labelA]="question.labelA"
						[labelB]="question.labelB"
						[valueA]="question.valueA"
						[valueB]="question.valueB"
						[value]="question.value"
						(valueChanged)="onSwitchChange()"
					>
					</oper-client-button-switch>

					<div class="toggle-container">
						<oper-client-toggle-switch
							*ngIf="question.type === 'toggle'"
							[formId]="formId"
							[type]="question.type"
							[formControlName]="question.key"
							[labelA]="question.labelA"
							[labelB]="question.labelB"
							[valueA]="question.valueA"
							[valueB]="question.valueB"
							[value]="question.value"
							(valueChanged)="onSwitchChange()"
						></oper-client-toggle-switch>
						<ng-container *ngIf="!question.label && (question.helpText | async)">
							<oper-client-tooltip-indicator
								*ngIf="overlayService.isModalClosed$ | async"
								[text]="question.helpText | async | translate"
								[parentContainer]="questionContainer"
							></oper-client-tooltip-indicator>
							<fa-icon
								*ngIf="overlayService.isModalOpen$ | async"
								class="help-text-icon"
								[tooltip]="question.helpText | async | translate"
								[tooltip-class]="'oper-tooltip'"
								[placement]="'right'"
								[fixedWidth]="true"
								[icon]="questionIcon"
							></fa-icon>
						</ng-container>
					</div>
				</ng-container>

				<!-- tel -->
				<ng-container *ngSwitchCase="'phone'">
					<oper-client-phone-input
						[formControlName]="question.key"
						[format]="'noCountry'"
						[allowedCountries]="question.allowedCountries"
						(valueChanged)="onPhoneNumberChange($event)"
						[required]="question.required"
						[allowedTypes]="question.allowedTypes"
						[validatePhoneType]="question.validatePhoneType"
						[enablePlaceholder]="question.enablePlaceholder"
					>
					</oper-client-phone-input>
				</ng-container>

				<ng-container *ngSwitchCase="'percentage'">
					<div class="input-group-container" [class.input-group-container--additional-disclaimer]="question.additionalDisclaimer">
						<div class="input-group" [ngClass]="{ 'input-group--disabled': question.disabled }">
							<div
								class="input-group-addition input-group-addition--prepend input-group-addition--prefix"
								*ngIf="question.prefix"
							>
								{{ question.prefix | translate }}
							</div>

							<input
								class="input-group__form-item-input"
								inputmode="decimal"
								operClientNumberToDecimalDirective
								[options]="defaultPercentageInputOptions$ | async"
								[ngClass]="{ 'input-group__form-item-input--disabled': question.disabled }"
								[type]="'text'"
								[id]="formControlId"
								[name]="question.label"
								[required]="question.required"
								[attr.disabled]="question.disabled ? '' : null"
								[formControlName]="question.key"
								[autocomplete]="'off'"
								(ngModelChange)="onTextChange($event)"
								(blur)="onBlur()"
								(focus)="onFocus()"
								(keydown)="onKeydown()"
							/>

							<div
								class="input-group-addition input-group-addition--prepend input-group-addition--suffix"
								*ngIf="question.suffix"
							>
								{{ question.suffix | translate }}
							</div>
						</div>
						<span class="additional-disclaimer" *ngIf="question.additionalDisclaimer">
							{{ question.additionalDisclaimer | translate }}
						</span>
						<button
							class="external-action-button"
							*ngIf="question.externalActionButtonIcon"
							[attr.disabled]="question.disabled || (question.externalActionButtonDisabled | async) ? '' : null"
							(click)="externalActionClick.emit({ questionKey: question.key })"
						>
							<fa-icon [icon]="question.externalActionButtonIcon" [fixedWidth]="true"></fa-icon>
						</button>
					</div>
				</ng-container>

				<oper-client-dynamic-input-table
					*ngSwitchCase="'table'"
					[formControlName]="question.key"
					[debounceTime]="question.debounceTime"
					[rows]="question.rows"
					[disabled]="question.disabled"
					[headerDefinitions]="question.headerDefinitions"
					[addRowLabel]="question.addRowLabel"
					[showAsCard]="question.showAsCard"
					[cardTitle]="question.cardTitle"
					[cardSubtitle]="question.cardSubtitle"
					[showDeleteButton]="question.showDeleteButton"
					[isVerticalLayout]="question.isVerticalLayout"
					[deleteLabel]="question.deleteLabel"
					(valueChange)="onDynamicTableValueChange($event)"
					(addRow)="onDynamicTableAddRow()"
					(removeRow)="onDynamicTableRemoveRow($event)"
					(removeCard)="onDynamicTableRemoveCard()"
				/>

				<oper-client-dynamic-input-form-items
					*ngSwitchCase="'formItems'"
					[formControlName]="question.key"
					[debounceTime]="question.debounceTime"
					[rows]="question.rows"
					[cardTitle]="question.cardTitle"
					[disabled]="question.disabled"
					[addItemLabel]="question.addItemLabel"
					[showAsCard]="question.showAsCard"
					[itemTitle]="question.itemTitle"
					[showDeleteButton]="question.showDeleteButton"
					(valueChange)="onDynamicTableValueChange($event)"
					(addRow)="onDynamicFormItemsAddRow()"
					(removeRow)="onDynamicFormItemsRemoveRow($event)"
				/>

				<oper-client-dynamic-form-card
					*ngSwitchCase="'card'"
					[formControlName]="question.key"
					[key]="question.key"
					[title]="question.title"
					[formConfiguration]="question.formConfiguration"
					[showDeleteButton]="question.showDeleteButton"
					[subtitle]="question.subtitle"
					[debounceTime]="question.debounceTime"
					(valueChange)="onDynamicFormCardValueChange($event)"
					(removeCard)="removeDynamicFormCard($event)"
				/>

				<!-- dashed -->
				<ng-container *ngSwitchCase="'dashed'">
					<div class="input-group-container" [class.input-group-container--additional-disclaimer]="question.additionalDisclaimer">
						<div class="input-group" [ngClass]="{ 'input-group--disabled': question.disabled }">
							<div
								class="input-group-addition input-group-addition--prepend input-group-addition--prefix"
								*ngIf="question.prefix"
							>
								{{ question.prefix }}
							</div>

							<input
								class="input-group__form-item-input"
								[ngClass]="{ 'input-group__form-item-input--disabled': question.disabled }"
								[type]="question.type"
								[id]="formControlId"
								[name]="question.label"
								[placeholder]="question.placeholder ? (question.placeholder | translate) : ''"
								[attr.inputmode]="question.type"
								[required]="question.required"
								[attr.disabled]="question.disabled ? '' : null"
								[formControlName]="question.key"
								[min]="question.min"
								[max]="question.max"
								[maxlength]="question.maxlength"
								(ngModelChange)="onTextChange($event)"
								(blur)="onBlur()"
								(focus)="onFocus()"
								[operClientFormatWithDashes]="question.dashesConfig"
							/>

							<div
								class="input-group-addition input-group-addition--prepend input-group-addition--suffix"
								*ngIf="question.suffix"
							>
								{{ question.suffix | translate }}
							</div>
						</div>
						<span class="additional-disclaimer" *ngIf="question.additionalDisclaimer">
							{{ question.additionalDisclaimer | translate }}
						</span>
						<button
							class="external-action-button"
							*ngIf="question.externalActionButtonIcon"
							[attr.disabled]="question.disabled || (question.externalActionButtonDisabled | async) ? '' : null"
							(click)="externalActionClick.emit({ questionKey: question.key })"
						>
							<fa-icon [icon]="question.externalActionButtonIcon" [fixedWidth]="true"></fa-icon>
						</button>
					</div>
				</ng-container>
			</ng-container>

			<oper-client-badge
				[label]="question.badgeLabel"
				[color]="question.badgeColor"
				[tooltip]="'ç.question.hasPoliticallyExposedPersonStatus.label' | translate"
				class="politically-exposed-person-badge"
				*ngIf="question.badgeLabel"
			></oper-client-badge>

			@if (question.disclaimer) {
				<oper-client-information-box
					class="disclaimer"
					[content]="question.disclaimer | translate: { tenant: customerName }"
					[color]="'default'"
					[disableClose]="true"
				/>
			}
		</div>

		@if (
			(form?.controls[question.key]?.invalid && form?.controls[question.key]?.touched && !isFocused && (errorMessage | translate)) ||
				(question.warningText | async | translate);
			as message
		) {
			<div
				[ngClass]="{
					'form-item-error': true,
					'form-item-error--checkbox': question.labelPlacement === 'inline' || question.type === 'checkbox'
				}"
			>
				<label #error class="form-item-error__label">{{ message }}</label>
				@if (error.offsetWidth < error.scrollWidth) {
					<fa-icon
						class="form-item-error__icon"
						[tooltip]="message"
						[tooltip-class]="'oper-tooltip'"
						[trigger]="'click'"
						[placement]="'bottom'"
						[fixedWidth]="true"
						[icon]="errorIcon"
					/>
				}
			</div>
		} @else if (!question.disclaimer) {
			<label class="form-item-error__label">{{ '\u00A0' }}</label>
		}
	</div>
} @else {
	<button class="secondary-action-button" (click)="expandQuestion()">
		<oper-client-fontawesome-icon class="secondary-action-button__icon" [icon]="'faPlus'" />
		<span class="secondary-action-button__label">{{ question.secondaryActionLabel | translate }}</span>
	</button>
}
